export function toISODateString(date: Date): string {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return newDate.toISOString().split('T')[0];
}

export function toISOTimeString(date: Date): string {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return newDate.toISOString().split('T')[1].split('.')[0];
}

export function toISOTimeStringWithoutSeconds(date: Date): string {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return newDate.toISOString().split('T')[1].split('.')[0].slice(0, 5);
}