import { CommonModule } from '@angular/common';
import {
    Component,
    Injector,
    Input,
    OnInit,
    Signal,
    WritableSignal,
    computed,
    inject,
    signal
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    AbstractControl,
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { addMinutes } from 'date-fns';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { CreateTaetigkeitCommand, CreateZeitbuchungCommand, TaetigkeitDto, UpdateZeitbuchungCommand, ZeitbuchungDto } from 'src/app/global/apis/backend/models';
import { KostentraegerService, TaetigkeitService, ZeitbuchungService } from 'src/app/global/apis/backend/services';
import { toISODateString, toISOTimeStringWithoutSeconds } from 'src/app/global/helper/isoDateFormater';
import { convertToDecimal, convertToTimespan } from 'src/app/global/helper/numToTimespan';
import { roundDateToNearestQuarterHour } from 'src/app/global/helper/roundTime';
import { FormDatePickerComponent } from 'src/app/shared/ui/form-date-picker/form-date-picker.component';

import { FormDropdownComponent } from 'src/app/shared/ui/form-dropdown/form-dropdown.component';
import { FormInputComponent } from 'src/app/shared/ui/form-input/form-input.component';
import { FormTimePickerComponent } from 'src/app/shared/ui/form-time-picker/form-time-picker.component';

@Component({
    selector: 'app-zeitbuchung.detail',
    templateUrl: './zeitbuchung.detail.component.html',
    styleUrls: [],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        FloatLabelModule,
        InputTextModule,
        DropdownModule,
        InputNumberModule,
        MultiSelectModule,
        InputSwitchModule,
        FormInputComponent,
        FormDropdownComponent,
        FormTimePickerComponent,
        FormDatePickerComponent,
        TableModule
    ],
})
export default class ZeitbuchungDetailComponent implements OnInit {
    @Input({ required: true }) readonly id?: string; // -> from route param

    public _isEdit = false;
    private readonly _zeitbuchungService = inject(ZeitbuchungService);
    private readonly _taetigkeitService = inject(TaetigkeitService);
    private readonly _fb = inject(FormBuilder);
    private readonly _componentInjector = inject(Injector);
    private readonly _messageService = inject(MessageService);
    private readonly _router = inject(Router);
    private _entry: ZeitbuchungDto | null = null;
    private readonly _alleKostentraegerSignal = toSignal(inject(KostentraegerService).kostentraegerGetAllKostentraeger());

    zeitbuchungNummerSignal = computed(() => {
        if (this._isEdit) {
            return this._entry?.id;
        }
        return -1;
    });

    private redirectPath = ['zeitbuchung'];
    private readonly initialDateTime = roundDateToNearestQuarterHour(new Date());
    private route: ActivatedRoute = inject(ActivatedRoute);

    public isLoading = true;
    public formZeitbuchung = this._fb.group({
        id: [''],
        datum: [this.initialDateTime, [Validators.required]],
        startzeit: [toISOTimeStringWithoutSeconds(this.initialDateTime), [this.zeitValidator.bind(this)]],
        pause: [0, [Validators.required, Validators.min(0)]],
        endzeit: [toISOTimeStringWithoutSeconds(addMinutes(this.initialDateTime, 15)),
        [this.zeitValidator.bind(this)]],
        homeOffice: [false, [Validators.required]],
    });

    public formTaetigkeit = this._fb.group({
        id: [''],
        kostentraeger: [null, [Validators.required]],
        zusammenfassung: ['', [Validators.required]],
        stunden: [0, [Validators.required, Validators.min(0)]],
        notiz: ['']
    });

    public kostentraegerAuswahlSignal = computed(() => this._alleKostentraegerSignal() ?? []);
    public taetigkeitenSignal: WritableSignal<TaetigkeitDto[]> = signal([]);
    public gesamtStundenSignal: Signal<number> = signal(0);
    public startEndePauseSignal: WritableSignal<number> = signal(0);

    constructor() {
        const state = this._router.getCurrentNavigation()?.extras.state;

        if (state) {
            this.redirectPath = state['redirectPath'] ?? ['zeitbuchung'];
        }
    }

    ngOnInit(): void {
        if (this.id) {
            this._isEdit = true;
            this._zeitbuchungService.zeitbuchungGetZeitbuchungById({ id: this.id || '' })
                .subscribe((response) => {
                    this._entry = response;

                    if (this._entry) {
                        this.taetigkeitenSignal = signal(this._entry?.taetigkeiten ?? []);
                        this.gesamtStundenSignal = computed(() => {
                            return this.taetigkeitenSignal().reduce((acc, x) => acc + convertToDecimal(x.stunden), 0);
                        });
                        this.setFormFromEntry(this._entry);
                        this.recalculateAlgStunden();
                        this.formZeitbuchung.markAsPristine();
                    }
                });
        }

        this.isLoading = false;
    }

    onSubmit() {
        if (this._isEdit) this.update();
        else this.create();
    }

    onSubmitTaetigkeit() {
        const neueTaetigkeit: CreateTaetigkeitCommand = {
            kostentraegerId: (this.formTaetigkeit.get('kostentraeger')?.value as any).id,
            stunden: convertToTimespan(this.formTaetigkeit.get('stunden')?.value ?? 0),
            zusammenfassung: this.formTaetigkeit.get('zusammenfassung')?.value ?? '',
            notiz: this.formTaetigkeit.get('notiz')?.value ?? '',
            zeitbuchungId: this._entry?.id ?? ''
        };

        this._taetigkeitService.taetigkeitCreateTaetigkeit({ body: neueTaetigkeit })
            .subscribe((response) => {
                this._messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail:
                        'Taetigkeit erfolgreich angelegt!',
                });
                const taetigkeitDto: TaetigkeitDto = {
                    id: response,
                    kostentraegerId: neueTaetigkeit.kostentraegerId ?? '',
                    kostentraegerName: (this.formTaetigkeit.get('kostentraeger')?.value as any).name,
                    stunden: neueTaetigkeit.stunden ?? '00:00:00',
                    zusammenfassung: neueTaetigkeit.zusammenfassung ?? '',
                    notiz: neueTaetigkeit.notiz ?? '',
                    zeitbuchungId: neueTaetigkeit.zeitbuchungId ?? '',
                };

                this.taetigkeitenSignal.set([...(this.taetigkeitenSignal() ?? []), taetigkeitDto]);
            });
    }

    deleteTaetigkeit(id: string) {
        this._taetigkeitService.taetigkeitDeleteTaetigkeit({ body: { ids: [id] } })
            .subscribe(() => {
                this._messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail:
                        'Taetigkeit ' +
                        id +
                        ' erfolgreich gelöscht!',
                });
                this.taetigkeitenSignal.set((this.taetigkeitenSignal() ?? []).filter((x) => x.id !== id));
            });
    }

    update() {
        const neueZeitbuchung: UpdateZeitbuchungCommand = {
            id: this.formZeitbuchung.get('id')?.value ?? '',
            datum: toISODateString(this.formZeitbuchung.get('datum')?.value ?? new Date()),
            // add :00 to string
            startzeit: `${this.formZeitbuchung.get('startzeit')?.value ?? toISOTimeStringWithoutSeconds(new Date())}:00`,
            pause: convertToTimespan(this.formZeitbuchung.get('pause')?.value ?? 0),
            endzeit: `${this.formZeitbuchung.get('endzeit')?.value ?? toISOTimeStringWithoutSeconds(new Date())}:00`,
            mitarbeiterId: this._entry?.mitarbeiterId ?? '',
            homeOffice: this.formZeitbuchung.get('homeOffice')?.value ?? false,
        };
        this._zeitbuchungService.zeitbuchungUpdateZeitbuchung({ body: neueZeitbuchung })
            .subscribe((x) => {
                this._messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail:
                        'Zeitbuchung ' +
                        neueZeitbuchung.id +
                        ' erfolgreich bearbeitet!',
                });
                this.recalculateAlgStunden();
                this.formZeitbuchung.markAsPristine();
            });
    }

    create() {
        const neueZeitbuchung: CreateZeitbuchungCommand = {
            datum: toISODateString(this.formZeitbuchung.get('datum')?.value ?? new Date()),
            startzeit: `${this.formZeitbuchung.get('startzeit')?.value ?? toISOTimeStringWithoutSeconds(new Date())}:00`,
            pause: convertToTimespan(this.formZeitbuchung.get('pause')?.value ?? 0),
            endzeit: `${this.formZeitbuchung.get('endzeit')?.value ?? toISOTimeStringWithoutSeconds(new Date())}:00`,
            homeOffice: this.formZeitbuchung.get('homeOffice')?.value ?? false,
        };
        this._zeitbuchungService.zeitbuchungCreateZeitbuchung({ body: neueZeitbuchung })
            .subscribe((response) => {
                this._messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail:
                        'Zeitbuchung erfolgreich angelegt!',
                });
                this._router.navigate([response], { relativeTo: this.route });
            });
    }

    private setFormFromEntry(dto: ZeitbuchungDto) {
        try {
            this.formZeitbuchung.setValue(
                {
                    id: dto.id,
                    datum: new Date(dto.datum),
                    startzeit: dto.startzeit.slice(0, 5),
                    pause: convertToDecimal(dto.pause || '00:00:00'),
                    endzeit: dto.endzeit.slice(0, 5),
                    homeOffice: dto.homeOffice
                },
                { emitEvent: false }
            );
        } catch (e) {
            console.error('Error setting form values: ', e);
        }
    }

    public resetForm() {
        if (this._isEdit) {

            this.setFormFromEntry(this._entry!);
            return;
        }
        this.formZeitbuchung.setValue({
            id: '',
            datum: this.initialDateTime,
            startzeit: toISOTimeStringWithoutSeconds(this.initialDateTime),
            pause: 0,
            endzeit: toISOTimeStringWithoutSeconds(addMinutes(this.initialDateTime, 15)),
            homeOffice: false
        });
        this.formZeitbuchung.markAsPristine();
    }

    public back() {
        structuredClone(this.redirectPath) !==
            structuredClone(['zeitbuchung'])
            ? this._router.navigate(this.redirectPath, {
                state: { rememberValues: true },
            })
            : this._router.navigate(this.redirectPath);
    }

    private recalculateAlgStunden() {
        this.startEndePauseSignal.set(this.calcStunden(
            convertToDecimal(this.formZeitbuchung.get('startzeit')?.value ?? '00:00'),
            convertToDecimal(this.formZeitbuchung.get('endzeit')?.value ?? '00:00'),
            this.formZeitbuchung.get('pause')?.value ?? 0
        ));
    }

    calcStunden(start: number, end: number, pause: number): number {
        if (end === 0) { end = 24; }

        return parseFloat(
            (end - start - pause).toFixed(2)
        );
    }

    convertToDecimal(time: string): number {
        return convertToDecimal(time);
    }

    zeitValidator(control: AbstractControl): ValidationErrors | null {
        const startzeit = this.formZeitbuchung?.get('startzeit')?.value;
        let endzeit = this.formZeitbuchung?.get('endzeit')?.value;

        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        if (!timeRegex.test(control.value)) {
            return { invalidTime: true, message: 'Ungültige Zeitangabe' };
        }

        if (!startzeit || !endzeit) {
            return { invalidTime: true, message: 'Bitte Start- und Endzeit angeben' };
        }

        if (this.calcStunden(
            convertToDecimal(startzeit ?? '00:00'),
            convertToDecimal(endzeit ?? '00:00'),
            this.formZeitbuchung.get('pause')?.value ?? 0
        ) < 0) {
            return { invalidTime: true, message: 'Endzeit muss nach Startzeit liegen' };
        }

        return null;
    }
}
