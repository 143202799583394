import { CommonModule, NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LogoComponent } from '../../svg/logo/logo.component';
import { UserRoleService } from '../../services/user-role.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  imports: [LogoComponent, NgClass, CommonModule, RouterLink, RouterLinkActive]
})
export class SidebarComponent {
  public isOpen = false;
  public RoleConstants = UserRoleService.RoleConstants;
  public UserRoles = inject(UserRoleService);
}
