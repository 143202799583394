import { trigger, transition, style, animate } from '@angular/animations';
import * as i2 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, PLATFORM_ID, Inject, Pipe, EventEmitter, forwardRef, Output, Input, ViewEncapsulation, Component, computed, signal, inject, numberAttribute, booleanAttribute, ContentChildren, ContentChild, ViewChild, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i3 from '@angular/router';
import { RouterModule } from '@angular/router';
import { uuid, focus, relativePosition, absolutePosition, appendChild, isTouchDevice, find, findSingle } from '@primeuix/utils';
import * as i5 from 'primeng/api';
import { SharedModule, PrimeTemplate } from 'primeng/api';
import * as i4 from 'primeng/badge';
import { BadgeModule } from 'primeng/badge';
import { BaseComponent } from 'primeng/basecomponent';
import { ConnectedOverlayScrollHandler } from 'primeng/dom';
import { Ripple } from 'primeng/ripple';
import * as i6 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import { ZIndexUtils } from 'primeng/utils';
import { BaseStyle } from 'primeng/base';
import * as i1 from '@angular/platform-browser';
const _c0 = ["pMenuItemContent", ""];
const _c1 = a0 => ({
  "p-disabled": a0
});
const _c2 = a0 => ({
  $implicit: a0
});
const _c3 = () => ({
  exact: false
});
function MenuItemContent_ng_container_1_a_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function MenuItemContent_ng_container_1_a_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 6);
    i0.ɵɵtemplate(1, MenuItemContent_ng_container_1_a_1_ng_container_1_Template, 1, 0, "ng-container", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    const itemContent_r3 = i0.ɵɵreference(4);
    i0.ɵɵproperty("target", ctx_r1.item.target)("ngClass", i0.ɵɵpureFunction1(9, _c1, ctx_r1.item.disabled));
    i0.ɵɵattribute("title", ctx_r1.item.title)("href", ctx_r1.item.url || null, i0.ɵɵsanitizeUrl)("data-automationid", ctx_r1.item.automationId)("tabindex", -1)("data-pc-section", "action");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", itemContent_r3)("ngTemplateOutletContext", i0.ɵɵpureFunction1(11, _c2, ctx_r1.item));
  }
}
function MenuItemContent_ng_container_1_a_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function MenuItemContent_ng_container_1_a_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 8);
    i0.ɵɵtemplate(1, MenuItemContent_ng_container_1_a_2_ng_container_1_Template, 1, 0, "ng-container", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    const itemContent_r3 = i0.ɵɵreference(4);
    i0.ɵɵproperty("routerLink", ctx_r1.item.routerLink)("queryParams", ctx_r1.item.queryParams)("routerLinkActiveOptions", ctx_r1.item.routerLinkActiveOptions || i0.ɵɵpureFunction0(17, _c3))("target", ctx_r1.item.target)("ngClass", i0.ɵɵpureFunction1(18, _c1, ctx_r1.item.disabled))("fragment", ctx_r1.item.fragment)("queryParamsHandling", ctx_r1.item.queryParamsHandling)("preserveFragment", ctx_r1.item.preserveFragment)("skipLocationChange", ctx_r1.item.skipLocationChange)("replaceUrl", ctx_r1.item.replaceUrl)("state", ctx_r1.item.state);
    i0.ɵɵattribute("data-automationid", ctx_r1.item.automationId)("tabindex", -1)("data-pc-section", "action")("title", ctx_r1.item.title);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", itemContent_r3)("ngTemplateOutletContext", i0.ɵɵpureFunction1(20, _c2, ctx_r1.item));
  }
}
function MenuItemContent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MenuItemContent_ng_container_1_a_1_Template, 2, 13, "a", 4)(2, MenuItemContent_ng_container_1_a_2_Template, 2, 22, "a", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !(ctx_r1.item == null ? null : ctx_r1.item.routerLink));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.item == null ? null : ctx_r1.item.routerLink);
  }
}
function MenuItemContent_ng_container_2_1_ng_template_0_Template(rf, ctx) {}
function MenuItemContent_ng_container_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MenuItemContent_ng_container_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function MenuItemContent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, MenuItemContent_ng_container_2_1_Template, 1, 0, null, 7);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c2, ctx_r1.item));
  }
}
function MenuItemContent_ng_template_3_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 12);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.item.iconClass);
    i0.ɵɵproperty("ngClass", ctx_r1.item.icon)("ngStyle", ctx_r1.item.iconStyle);
  }
}
function MenuItemContent_ng_template_3_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.item.label);
  }
}
function MenuItemContent_ng_template_3_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 14);
    i0.ɵɵpipe(1, "safeHtml");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind1(1, 1, ctx_r1.item.label), i0.ɵɵsanitizeHtml);
  }
}
function MenuItemContent_ng_template_3_p_badge_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "p-badge", 15);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("styleClass", ctx_r1.item.badgeStyleClass)("value", ctx_r1.item.badge);
  }
}
function MenuItemContent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MenuItemContent_ng_template_3_span_0_Template, 1, 4, "span", 9)(1, MenuItemContent_ng_template_3_span_1_Template, 2, 1, "span", 10)(2, MenuItemContent_ng_template_3_ng_template_2_Template, 2, 3, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(4, MenuItemContent_ng_template_3_p_badge_4_Template, 1, 2, "p-badge", 11);
  }
  if (rf & 2) {
    const htmlLabel_r4 = i0.ɵɵreference(3);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.item.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.item.escape !== false)("ngIfElse", htmlLabel_r4);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r1.item.badge);
  }
}
const _c4 = ["start"];
const _c5 = ["end"];
const _c6 = ["header"];
const _c7 = ["item"];
const _c8 = ["submenuheader"];
const _c9 = ["list"];
const _c10 = ["container"];
const _c11 = a0 => ({
  "p-menu p-component": true,
  "p-menu-overlay": a0
});
const _c12 = (a0, a1) => ({
  showTransitionParams: a0,
  hideTransitionParams: a1
});
const _c13 = a0 => ({
  value: "visible",
  params: a0
});
const _c14 = (a0, a1) => ({
  "p-hidden": a0,
  flex: a1
});
const _c15 = (a0, a1) => ({
  "p-focus": a0,
  "p-disabled": a1
});
function Menu_div_0_div_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Menu_div_0_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtemplate(1, Menu_div_0_div_2_ng_container_1_Template, 1, 0, "ng-container", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_5_0;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("data-pc-section", "start");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", (tmp_5_0 = ctx_r1.startTemplate) !== null && tmp_5_0 !== undefined ? tmp_5_0 : ctx_r1._startTemplate);
  }
}
function Menu_div_0_5_ng_template_0_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 14);
  }
}
function Menu_div_0_5_ng_template_0_li_1_ng_container_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const submenu_r3 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(submenu_r3.label);
  }
}
function Menu_div_0_5_ng_template_0_li_1_ng_container_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 18);
    i0.ɵɵpipe(1, "safeHtml");
  }
  if (rf & 2) {
    const submenu_r3 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind1(1, 1, submenu_r3.label), i0.ɵɵsanitizeHtml);
  }
}
function Menu_div_0_5_ng_template_0_li_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Menu_div_0_5_ng_template_0_li_1_ng_container_1_span_1_Template, 2, 1, "span", 17)(2, Menu_div_0_5_ng_template_0_li_1_ng_container_1_ng_template_2_Template, 2, 3, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const htmlSubmenuLabel_r4 = i0.ɵɵreference(3);
    const submenu_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", submenu_r3.escape !== false)("ngIfElse", htmlSubmenuLabel_r4);
  }
}
function Menu_div_0_5_ng_template_0_li_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Menu_div_0_5_ng_template_0_li_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 15);
    i0.ɵɵtemplate(1, Menu_div_0_5_ng_template_0_li_1_ng_container_1_Template, 4, 2, "ng-container", 7)(2, Menu_div_0_5_ng_template_0_li_1_ng_container_2_Template, 1, 0, "ng-container", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_13_0;
    const ctx_r4 = i0.ɵɵnextContext();
    const submenu_r3 = ctx_r4.$implicit;
    const i_r6 = ctx_r4.index;
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(7, _c14, submenu_r3.visible === false, submenu_r3.visible))("tooltipOptions", submenu_r3.tooltipOptions);
    i0.ɵɵattribute("data-automationid", submenu_r3.automationId)("id", ctx_r1.menuitemId(submenu_r3, ctx_r1.id, i_r6));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.submenuHeaderTemplate && !ctx_r1._submenuHeaderTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", (tmp_13_0 = ctx_r1.submenuHeaderTemplate) !== null && tmp_13_0 !== undefined ? tmp_13_0 : ctx_r1._submenuHeaderTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(10, _c2, submenu_r3));
  }
}
function Menu_div_0_5_ng_template_0_ng_template_2_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 14);
  }
}
function Menu_div_0_5_ng_template_0_ng_template_2_li_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 20);
    i0.ɵɵlistener("onMenuItemClick", function Menu_div_0_5_ng_template_0_ng_template_2_li_1_Template_li_onMenuItemClick_0_listener($event) {
      i0.ɵɵrestoreView(_r7);
      const ctx_r7 = i0.ɵɵnextContext();
      const item_r9 = ctx_r7.$implicit;
      const j_r10 = ctx_r7.index;
      const i_r6 = i0.ɵɵnextContext().index;
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.itemClick($event, ctx_r1.menuitemId(item_r9, ctx_r1.id, i_r6, j_r10)));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_13_0;
    const ctx_r7 = i0.ɵɵnextContext();
    const item_r9 = ctx_r7.$implicit;
    const j_r10 = ctx_r7.index;
    const i_r6 = i0.ɵɵnextContext().index;
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(item_r9.styleClass);
    i0.ɵɵproperty("pMenuItemContent", item_r9)("itemTemplate", (tmp_13_0 = ctx_r1.itemTemplate) !== null && tmp_13_0 !== undefined ? tmp_13_0 : ctx_r1._itemTemplate)("ngClass", i0.ɵɵpureFunction2(13, _c15, ctx_r1.focusedOptionId() && ctx_r1.menuitemId(item_r9, ctx_r1.id, i_r6, j_r10) === ctx_r1.focusedOptionId(), ctx_r1.disabled(item_r9.disabled)))("ngStyle", item_r9.style)("tooltipOptions", item_r9.tooltipOptions);
    i0.ɵɵattribute("data-pc-section", "menuitem")("aria-label", ctx_r1.label(item_r9.label))("data-p-focused", ctx_r1.isItemFocused(ctx_r1.menuitemId(item_r9, ctx_r1.id, i_r6, j_r10)))("data-p-disabled", ctx_r1.disabled(item_r9.disabled))("aria-disabled", ctx_r1.disabled(item_r9.disabled))("id", ctx_r1.menuitemId(item_r9, ctx_r1.id, i_r6, j_r10));
  }
}
function Menu_div_0_5_ng_template_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_5_ng_template_0_ng_template_2_li_0_Template, 1, 0, "li", 12)(1, Menu_div_0_5_ng_template_0_ng_template_2_li_1_Template, 1, 16, "li", 19);
  }
  if (rf & 2) {
    const item_r9 = ctx.$implicit;
    const submenu_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngIf", item_r9.separator && (item_r9.visible !== false || submenu_r3.visible !== false));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !item_r9.separator && item_r9.visible !== false && (item_r9.visible !== undefined || submenu_r3.visible !== false));
  }
}
function Menu_div_0_5_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_5_ng_template_0_li_0_Template, 1, 0, "li", 12)(1, Menu_div_0_5_ng_template_0_li_1_Template, 3, 12, "li", 13)(2, Menu_div_0_5_ng_template_0_ng_template_2_Template, 2, 2, "ng-template", 11);
  }
  if (rf & 2) {
    const submenu_r3 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", submenu_r3.separator && submenu_r3.visible !== false);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !submenu_r3.separator);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", submenu_r3.items);
  }
}
function Menu_div_0_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_5_ng_template_0_Template, 3, 3, "ng-template", 11);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.model);
  }
}
function Menu_div_0_6_ng_template_0_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 14);
  }
}
function Menu_div_0_6_ng_template_0_li_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 20);
    i0.ɵɵlistener("onMenuItemClick", function Menu_div_0_6_ng_template_0_li_1_Template_li_onMenuItemClick_0_listener($event) {
      i0.ɵɵrestoreView(_r11);
      const ctx_r11 = i0.ɵɵnextContext();
      const item_r13 = ctx_r11.$implicit;
      const i_r14 = ctx_r11.index;
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.itemClick($event, ctx_r1.menuitemId(item_r13, ctx_r1.id, i_r14)));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_10_0;
    const ctx_r11 = i0.ɵɵnextContext();
    const item_r13 = ctx_r11.$implicit;
    const i_r14 = ctx_r11.index;
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(item_r13.styleClass);
    i0.ɵɵproperty("pMenuItemContent", item_r13)("itemTemplate", (tmp_10_0 = ctx_r1.itemTemplate) !== null && tmp_10_0 !== undefined ? tmp_10_0 : ctx_r1._itemTemplate)("ngClass", i0.ɵɵpureFunction2(13, _c15, ctx_r1.focusedOptionId() && ctx_r1.menuitemId(item_r13, ctx_r1.id, i_r14) === ctx_r1.focusedOptionId(), ctx_r1.disabled(item_r13.disabled)))("ngStyle", item_r13.style)("tooltipOptions", item_r13.tooltipOptions);
    i0.ɵɵattribute("data-pc-section", "menuitem")("aria-label", ctx_r1.label(item_r13.label))("data-p-focused", ctx_r1.isItemFocused(ctx_r1.menuitemId(item_r13, ctx_r1.id, i_r14)))("data-p-disabled", ctx_r1.disabled(item_r13.disabled))("aria-disabled", ctx_r1.disabled(item_r13.disabled))("id", ctx_r1.menuitemId(item_r13, ctx_r1.id, i_r14));
  }
}
function Menu_div_0_6_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_6_ng_template_0_li_0_Template, 1, 0, "li", 12)(1, Menu_div_0_6_ng_template_0_li_1_Template, 1, 16, "li", 19);
  }
  if (rf & 2) {
    const item_r13 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", item_r13.separator && item_r13.visible !== false);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !item_r13.separator && item_r13.visible !== false);
  }
}
function Menu_div_0_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_6_ng_template_0_Template, 2, 2, "ng-template", 11);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.model);
  }
}
function Menu_div_0_div_7_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Menu_div_0_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 21);
    i0.ɵɵtemplate(1, Menu_div_0_div_7_ng_container_1_Template, 1, 0, "ng-container", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_5_0;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("data-pc-section", "end");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", (tmp_5_0 = ctx_r1.endTemplate) !== null && tmp_5_0 !== undefined ? tmp_5_0 : ctx_r1._endTemplate);
  }
}
function Menu_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4, 0);
    i0.ɵɵlistener("click", function Menu_div_0_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onOverlayClick($event));
    })("@overlayAnimation.start", function Menu_div_0_Template_div_animation_overlayAnimation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onOverlayAnimationStart($event));
    })("@overlayAnimation.done", function Menu_div_0_Template_div_animation_overlayAnimation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onOverlayAnimationEnd($event));
    });
    i0.ɵɵtemplate(2, Menu_div_0_div_2_Template, 2, 2, "div", 5);
    i0.ɵɵelementStart(3, "ul", 6, 1);
    i0.ɵɵlistener("focus", function Menu_div_0_Template_ul_focus_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onListFocus($event));
    })("blur", function Menu_div_0_Template_ul_blur_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onListBlur($event));
    })("keydown", function Menu_div_0_Template_ul_keydown_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onListKeyDown($event));
    });
    i0.ɵɵtemplate(5, Menu_div_0_5_Template, 1, 1, null, 7)(6, Menu_div_0_6_Template, 1, 1, null, 7);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, Menu_div_0_div_7_Template, 2, 2, "div", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_10_0;
    let tmp_19_0;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.styleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(18, _c11, ctx_r1.popup))("ngStyle", ctx_r1.style)("@overlayAnimation", i0.ɵɵpureFunction1(23, _c13, i0.ɵɵpureFunction2(20, _c12, ctx_r1.showTransitionOptions, ctx_r1.hideTransitionOptions)))("@.disabled", ctx_r1.popup !== true);
    i0.ɵɵattribute("data-pc-name", "menu")("id", ctx_r1.id);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", (tmp_10_0 = ctx_r1.startTemplate) !== null && tmp_10_0 !== undefined ? tmp_10_0 : ctx_r1._startTemplate);
    i0.ɵɵadvance();
    i0.ɵɵattribute("id", ctx_r1.id + "_list")("tabindex", ctx_r1.getTabIndexValue())("data-pc-section", "menu")("aria-activedescendant", ctx_r1.activedescendant())("aria-label", ctx_r1.ariaLabel)("aria-labelledBy", ctx_r1.ariaLabelledBy);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.hasSubMenu());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.hasSubMenu());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", (tmp_19_0 = ctx_r1.endTemplate) !== null && tmp_19_0 !== undefined ? tmp_19_0 : ctx_r1._endTemplate);
  }
}
const theme = ({
  dt
}) => `
.p-menu {
    background: ${dt('menu.background')};
    color: ${dt('menu.color')};
    border: 1px solid ${dt('menu.border.color')};
    border-radius: ${dt('menu.border.radius')};
    min-width: 12.5rem;
}

.p-menu-list {
    margin: 0;
    padding: ${dt('menu.list.padding')};
    outline: 0 none;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: ${dt('menu.list.gap')};
}

.p-menu-item-content {
    transition: background ${dt('menu.transition.duration')}, color ${dt('menu.transition.duration')};
    border-radius: ${dt('menu.item.border.radius')};
    color: ${dt('menu.item.color')};
}

.p-menu-item-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    color: inherit;
    padding: ${dt('menu.item.padding')};
    gap: ${dt('menu.item.gap')};
    user-select: none;
    outline: 0 none;
}

.p-menu-item-label {
    line-height: 1;
}

.p-menu-item-icon {
    color: ${dt('menu.item.icon.color')};
}

.p-menu-item.p-focus .p-menu-item-content {
    color: ${dt('menu.item.focus.color')};
    background: ${dt('menu.item.focus.background')};
}

.p-menu-item.p-focus .p-menu-item-icon {
    color: ${dt('menu.item.icon.focus.color')};
}

.p-menu-item:not(.p-disabled) .p-menu-item-content:hover {
    color: ${dt('menu.item.focus.color')};
    background: ${dt('menu.item.focus.background')};
}

.p-menu-item:not(.p-disabled) .p-menu-item-content:hover .p-menu-item-icon {
    color: ${dt('menu.item.icon.focus.color')};
}

.p-menu-overlay {
    box-shadow: ${dt('menu.shadow')};
}

.p-menu-submenu-label {
    background: ${dt('menu.submenu.label.background')};
    padding: ${dt('menu.submenu.label.padding')};
    color: ${dt('menu.submenu.label.color')};
    font-weight: ${dt('menu.submenu.label.font.weight')};
}

.p-menu-separator {
    border-top: 1px solid ${dt('menu.separator.border.color')};
}

/* For PrimeNG */
.p-menu-overlay {
    position: absolute;
}
`;
const classes = {
  root: ({
    props
  }) => ['p-menu p-component', {
    'p-menu-overlay': props.popup
  }],
  start: 'p-menu-start',
  list: 'p-menu-list',
  submenuLabel: 'p-menu-submenu-label',
  separator: 'p-menu-separator',
  end: 'p-menu-end',
  item: ({
    instance
  }) => ['p-menu-item', {
    'p-focus': instance.id === instance.focusedOptionId,
    'p-disabled': instance.disabled()
  }],
  itemContent: 'p-menu-item-content',
  itemLink: 'p-menu-item-link',
  itemIcon: 'p-menu-item-icon',
  itemLabel: 'p-menu-item-label'
};
class MenuStyle extends BaseStyle {
  name = 'menu';
  theme = theme;
  classes = classes;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵMenuStyle_BaseFactory;
    return function MenuStyle_Factory(__ngFactoryType__) {
      return (ɵMenuStyle_BaseFactory || (ɵMenuStyle_BaseFactory = i0.ɵɵgetInheritedFactory(MenuStyle)))(__ngFactoryType__ || MenuStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MenuStyle,
    factory: MenuStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * Menu is a navigation / command component that supports dynamic and static positioning.
 *
 * [Live Demo](https://www.primeng.org/menu/)
 *
 * @module menustyle
 *
 */
var MenuClasses;
(function (MenuClasses) {
  /**
   * Class name of the root element
   */
  MenuClasses["root"] = "p-menu";
  /**
   * Class name of the start element
   */
  MenuClasses["start"] = "p-menu-start";
  /**
   * Class name of the list element
   */
  MenuClasses["list"] = "p-menu-list";
  /**
   * Class name of the submenu item element
   */
  MenuClasses["submenuItem"] = "p-menu-submenu-item";
  /**
   * Class name of the separator element
   */
  MenuClasses["separator"] = "p-menu-separator";
  /**
   * Class name of the end element
   */
  MenuClasses["end"] = "p-menu-end";
  /**
   * Class name of the item element
   */
  MenuClasses["item"] = "p-menu-item";
  /**
   * Class name of the item content element
   */
  MenuClasses["itemContent"] = "p-menu-item-content";
  /**
   * Class name of the item link element
   */
  MenuClasses["itemLink"] = "p-menu-item-link";
  /**
   * Class name of the item icon element
   */
  MenuClasses["itemIcon"] = "p-menu-item-icon";
  /**
   * Class name of the item label element
   */
  MenuClasses["itemLabel"] = "p-menu-item-label";
})(MenuClasses || (MenuClasses = {}));
class SafeHtmlPipe {
  platformId;
  sanitizer;
  constructor(platformId, sanitizer) {
    this.platformId = platformId;
    this.sanitizer = sanitizer;
  }
  transform(value) {
    if (!value || !isPlatformBrowser(this.platformId)) {
      return value;
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  static ɵfac = function SafeHtmlPipe_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SafeHtmlPipe)(i0.ɵɵdirectiveInject(PLATFORM_ID, 16), i0.ɵɵdirectiveInject(i1.DomSanitizer, 16));
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "safeHtml",
    type: SafeHtmlPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SafeHtmlPipe, [{
    type: Pipe,
    args: [{
      name: 'safeHtml',
      standalone: true
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i1.DomSanitizer
  }], null);
})();
class MenuItemContent {
  item;
  itemTemplate;
  onMenuItemClick = new EventEmitter();
  menu;
  constructor(menu) {
    this.menu = menu;
  }
  onItemClick(event, item) {
    this.onMenuItemClick.emit({
      originalEvent: event,
      item
    });
  }
  static ɵfac = function MenuItemContent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MenuItemContent)(i0.ɵɵdirectiveInject(forwardRef(() => Menu)));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: MenuItemContent,
    selectors: [["", "pMenuItemContent", ""]],
    inputs: {
      item: [0, "pMenuItemContent", "item"],
      itemTemplate: "itemTemplate"
    },
    outputs: {
      onMenuItemClick: "onMenuItemClick"
    },
    attrs: _c0,
    decls: 5,
    vars: 3,
    consts: [["itemContent", ""], ["htmlLabel", ""], [1, "p-menu-item-content", 3, "click"], [4, "ngIf"], ["class", "p-menu-item-link", "pRipple", "", 3, "target", "ngClass", 4, "ngIf"], ["routerLinkActive", "p-menu-item-link-active", "class", "p-menu-item-link", "pRipple", "", 3, "routerLink", "queryParams", "routerLinkActiveOptions", "target", "ngClass", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", 4, "ngIf"], ["pRipple", "", 1, "p-menu-item-link", 3, "target", "ngClass"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["routerLinkActive", "p-menu-item-link-active", "pRipple", "", 1, "p-menu-item-link", 3, "routerLink", "queryParams", "routerLinkActiveOptions", "target", "ngClass", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state"], ["class", "p-menu-item-icon", 3, "ngClass", "class", "ngStyle", 4, "ngIf"], ["class", "p-menu-item-label", 4, "ngIf", "ngIfElse"], [3, "styleClass", "value", 4, "ngIf"], [1, "p-menu-item-icon", 3, "ngClass", "ngStyle"], [1, "p-menu-item-label"], [1, "p-menu-item-label", 3, "innerHTML"], [3, "styleClass", "value"]],
    template: function MenuItemContent_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 2);
        i0.ɵɵlistener("click", function MenuItemContent_Template_div_click_0_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onItemClick($event, ctx.item));
        });
        i0.ɵɵtemplate(1, MenuItemContent_ng_container_1_Template, 3, 2, "ng-container", 3)(2, MenuItemContent_ng_container_2_Template, 2, 4, "ng-container", 3)(3, MenuItemContent_ng_template_3_Template, 5, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵattribute("data-pc-section", "content");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.itemTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.itemTemplate);
      }
    },
    dependencies: [CommonModule, i2.NgClass, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, RouterModule, i3.RouterLink, i3.RouterLinkActive, Ripple, TooltipModule, BadgeModule, i4.Badge, SharedModule, SafeHtmlPipe],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuItemContent, [{
    type: Component,
    args: [{
      selector: '[pMenuItemContent]',
      standalone: true,
      imports: [CommonModule, RouterModule, Ripple, TooltipModule, BadgeModule, SharedModule, SafeHtmlPipe],
      template: `
        <div [attr.data-pc-section]="'content'" class="p-menu-item-content" (click)="onItemClick($event, item)">
            <ng-container *ngIf="!itemTemplate">
                <a
                    *ngIf="!item?.routerLink"
                    [attr.title]="item.title"
                    [attr.href]="item.url || null"
                    [attr.data-automationid]="item.automationId"
                    [attr.tabindex]="-1"
                    [attr.data-pc-section]="'action'"
                    class="p-menu-item-link"
                    [target]="item.target"
                    [ngClass]="{ 'p-disabled': item.disabled }"
                    pRipple
                >
                    <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: item }"></ng-container>
                </a>
                <a
                    *ngIf="item?.routerLink"
                    [routerLink]="item.routerLink"
                    [attr.data-automationid]="item.automationId"
                    [attr.tabindex]="-1"
                    [attr.data-pc-section]="'action'"
                    [attr.title]="item.title"
                    [queryParams]="item.queryParams"
                    routerLinkActive="p-menu-item-link-active"
                    [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
                    class="p-menu-item-link"
                    [target]="item.target"
                    [ngClass]="{ 'p-disabled': item.disabled }"
                    [fragment]="item.fragment"
                    [queryParamsHandling]="item.queryParamsHandling"
                    [preserveFragment]="item.preserveFragment"
                    [skipLocationChange]="item.skipLocationChange"
                    [replaceUrl]="item.replaceUrl"
                    [state]="item.state"
                    pRipple
                >
                    <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: item }"></ng-container>
                </a>
            </ng-container>

            <ng-container *ngIf="itemTemplate">
                <ng-template *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-template>
            </ng-container>

            <ng-template #itemContent>
                <span class="p-menu-item-icon" *ngIf="item.icon" [ngClass]="item.icon" [class]="item.iconClass" [ngStyle]="item.iconStyle"></span>
                <span class="p-menu-item-label" *ngIf="item.escape !== false; else htmlLabel">{{ item.label }}</span>
                <ng-template #htmlLabel><span class="p-menu-item-label" [innerHTML]="item.label | safeHtml"></span></ng-template>
                <p-badge *ngIf="item.badge" [styleClass]="item.badgeStyleClass" [value]="item.badge" />
            </ng-template>
        </div>
    `,
      encapsulation: ViewEncapsulation.None
    }]
  }], () => [{
    type: Menu,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => Menu)]
    }]
  }], {
    item: [{
      type: Input,
      args: ['pMenuItemContent']
    }],
    itemTemplate: [{
      type: Input
    }],
    onMenuItemClick: [{
      type: Output
    }]
  });
})();
/**
 * Menu is a navigation / command component that supports dynamic and static positioning.
 * @group Components
 */
class Menu extends BaseComponent {
  overlayService;
  /**
   * An array of menuitems.
   * @group Props
   */
  model;
  /**
   * Defines if menu would displayed as a popup.
   * @group Props
   */
  popup;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   * @group Props
   */
  appendTo;
  /**
   * Whether to automatically manage layering.
   * @group Props
   */
  autoZIndex = true;
  /**
   * Base zIndex value to use in layering.
   * @group Props
   */
  baseZIndex = 0;
  /**
   * Transition options of the show animation.
   * @group Props
   */
  showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Transition options of the hide animation.
   * @group Props
   */
  hideTransitionOptions = '.1s linear';
  /**
   * Defines a string value that labels an interactive element.
   * @group Props
   */
  ariaLabel;
  /**
   * Identifier of the underlying input element.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Current id state as a string.
   * @group Props
   */
  id;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex = 0;
  /**
   * Callback to invoke when overlay menu is shown.
   * @group Emits
   */
  onShow = new EventEmitter();
  /**
   * Callback to invoke when overlay menu is hidden.
   * @group Emits
   */
  onHide = new EventEmitter();
  /**
   * Callback to invoke when the list loses focus.
   * @param {Event} event - blur event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  /**
   * Callback to invoke when the list receives focus.
   * @param {Event} event - focus event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  listViewChild;
  containerViewChild;
  container;
  scrollHandler;
  documentClickListener;
  documentResizeListener;
  preventDocumentDefault;
  target;
  visible;
  focusedOptionId = computed(() => {
    return this.focusedOptionIndex() !== -1 ? this.focusedOptionIndex() : null;
  });
  focusedOptionIndex = signal(-1);
  selectedOptionIndex = signal(-1);
  focused = false;
  overlayVisible = false;
  relativeAlign;
  _componentStyle = inject(MenuStyle);
  constructor(overlayService) {
    super();
    this.overlayService = overlayService;
    this.id = this.id || uuid('pn_id_');
  }
  /**
   * Toggles the visibility of the popup menu.
   * @param {Event} event - Browser event.
   * @group Method
   */
  toggle(event) {
    if (this.visible) this.hide();else this.show(event);
    this.preventDocumentDefault = true;
  }
  /**
   * Displays the popup menu.
   * @param {Event} event - Browser event.
   * @group Method
   */
  show(event) {
    this.target = event.currentTarget;
    this.relativeAlign = event.relativeAlign;
    this.visible = true;
    this.preventDocumentDefault = true;
    this.overlayVisible = true;
    this.cd.markForCheck();
  }
  ngOnInit() {
    super.ngOnInit();
    if (!this.popup) {
      this.bindDocumentClickListener();
    }
  }
  /**
   * Defines template option for start.
   * @group Templates
   */
  startTemplate;
  _startTemplate;
  /**
   * Defines template option for end.
   * @group Templates
   */
  endTemplate;
  _endTemplate;
  /**
   * Defines template option for header.
   * @group Templates
   */
  headerTemplate;
  _headerTemplate;
  /**
   * Defines template option for item.
   * @group Templates
   */
  itemTemplate;
  _itemTemplate;
  /**
   * Defines template option for item.
   * @group Templates
   */
  submenuHeaderTemplate;
  _submenuHeaderTemplate;
  templates;
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'start':
          this._startTemplate = item.template;
          break;
        case 'end':
          this._endTemplate = item.template;
          break;
        case 'item':
          this._itemTemplate = item.template;
          break;
        case 'submenuheader':
          this._submenuHeaderTemplate = item.template;
          break;
        default:
          this._itemTemplate = item.template;
          break;
      }
    });
  }
  getTabIndexValue() {
    return this.tabindex !== undefined ? this.tabindex.toString() : null;
  }
  onOverlayAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        if (this.popup) {
          this.container = event.element;
          this.moveOnTop();
          this.onShow.emit({});
          this.appendOverlay();
          this.alignOverlay();
          this.bindDocumentClickListener();
          this.bindDocumentResizeListener();
          this.bindScrollListener();
          focus(this.listViewChild.nativeElement);
        }
        break;
      case 'void':
        this.onOverlayHide();
        this.onHide.emit({});
        break;
    }
  }
  onOverlayAnimationEnd(event) {
    switch (event.toState) {
      case 'void':
        if (this.autoZIndex) {
          ZIndexUtils.clear(event.element);
        }
        break;
    }
  }
  alignOverlay() {
    if (this.relativeAlign) relativePosition(this.container, this.target);else absolutePosition(this.container, this.target);
  }
  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') this.renderer.appendChild(this.document.body, this.container);else appendChild(this.appendTo, this.container);
    }
  }
  restoreOverlayAppend() {
    if (this.container && this.appendTo) {
      this.renderer.appendChild(this.el.nativeElement, this.container);
    }
  }
  moveOnTop() {
    if (this.autoZIndex) {
      ZIndexUtils.set('menu', this.container, this.baseZIndex + this.config.zIndex.menu);
    }
  }
  /**
   * Hides the popup menu.
   * @group Method
   */
  hide() {
    this.visible = false;
    this.relativeAlign = false;
    this.cd.markForCheck();
  }
  onWindowResize() {
    if (this.visible && !isTouchDevice()) {
      this.hide();
    }
  }
  menuitemId(item, id, index, childIndex) {
    return item?.id ?? `${id}_${index}${childIndex !== undefined ? '_' + childIndex : ''}`;
  }
  isItemFocused(id) {
    return this.focusedOptionId() === id;
  }
  label(label) {
    return typeof label === 'function' ? label() : label;
  }
  disabled(disabled) {
    return typeof disabled === 'function' ? disabled() : typeof disabled === 'undefined' ? false : disabled;
  }
  activedescendant() {
    return this.focused ? this.focusedOptionId() : undefined;
  }
  onListFocus(event) {
    if (!this.focused) {
      this.focused = true;
      this.onFocus.emit(event);
    }
  }
  onListBlur(event) {
    if (this.focused) {
      this.focused = false;
      this.changeFocusedOptionIndex(-1);
      this.selectedOptionIndex.set(-1);
      this.focusedOptionIndex.set(-1);
      this.onBlur.emit(event);
    }
  }
  onListKeyDown(event) {
    switch (event.code) {
      case 'ArrowDown':
        this.onArrowDownKey(event);
        break;
      case 'ArrowUp':
        this.onArrowUpKey(event);
        break;
      case 'Home':
        this.onHomeKey(event);
        break;
      case 'End':
        this.onEndKey(event);
        break;
      case 'Enter':
        this.onEnterKey(event);
        break;
      case 'NumpadEnter':
        this.onEnterKey(event);
        break;
      case 'Space':
        this.onSpaceKey(event);
        break;
      case 'Escape':
      case 'Tab':
        if (this.popup) {
          focus(this.target);
          this.hide();
        }
        this.overlayVisible && this.hide();
        break;
      default:
        break;
    }
  }
  onArrowDownKey(event) {
    const optionIndex = this.findNextOptionIndex(this.focusedOptionIndex());
    this.changeFocusedOptionIndex(optionIndex);
    event.preventDefault();
  }
  onArrowUpKey(event) {
    if (event.altKey && this.popup) {
      focus(this.target);
      this.hide();
      event.preventDefault();
    } else {
      const optionIndex = this.findPrevOptionIndex(this.focusedOptionIndex());
      this.changeFocusedOptionIndex(optionIndex);
      event.preventDefault();
    }
  }
  onHomeKey(event) {
    this.changeFocusedOptionIndex(0);
    event.preventDefault();
  }
  onEndKey(event) {
    this.changeFocusedOptionIndex(find(this.containerViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]').length - 1);
    event.preventDefault();
  }
  onEnterKey(event) {
    const element = findSingle(this.containerViewChild.nativeElement, `li[id="${`${this.focusedOptionIndex()}`}"]`);
    const anchorElement = element && findSingle(element, 'a[data-pc-section="action"]');
    this.popup && focus(this.target);
    anchorElement ? anchorElement.click() : element && element.click();
    event.preventDefault();
  }
  onSpaceKey(event) {
    this.onEnterKey(event);
  }
  findNextOptionIndex(index) {
    const links = find(this.containerViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]');
    const matchedOptionIndex = [...links].findIndex(link => link.id === index);
    return matchedOptionIndex > -1 ? matchedOptionIndex + 1 : 0;
  }
  findPrevOptionIndex(index) {
    const links = find(this.containerViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]');
    const matchedOptionIndex = [...links].findIndex(link => link.id === index);
    return matchedOptionIndex > -1 ? matchedOptionIndex - 1 : 0;
  }
  changeFocusedOptionIndex(index) {
    const links = find(this.containerViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]');
    if (links.length > 0) {
      let order = index >= links.length ? links.length - 1 : index < 0 ? 0 : index;
      order > -1 && this.focusedOptionIndex.set(links[order].getAttribute('id'));
    }
  }
  itemClick(event, id) {
    const {
      originalEvent,
      item
    } = event;
    if (!this.focused) {
      this.focused = true;
      this.onFocus.emit();
    }
    if (item.disabled) {
      originalEvent.preventDefault();
      return;
    }
    if (!item.url && !item.routerLink) {
      originalEvent.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: originalEvent,
        item: item
      });
    }
    if (this.popup) {
      this.hide();
    }
    if (!this.popup && this.focusedOptionIndex() !== id) {
      this.focusedOptionIndex.set(id);
    }
  }
  onOverlayClick(event) {
    if (this.popup) {
      this.overlayService.add({
        originalEvent: event,
        target: this.el.nativeElement
      });
    }
    this.preventDocumentDefault = true;
  }
  bindDocumentClickListener() {
    if (!this.documentClickListener && isPlatformBrowser(this.platformId)) {
      const documentTarget = this.el ? this.el.nativeElement.ownerDocument : 'document';
      this.documentClickListener = this.renderer.listen(documentTarget, 'click', event => {
        const isOutsideContainer = this.containerViewChild?.nativeElement && !this.containerViewChild?.nativeElement.contains(event.target);
        const isOutsideTarget = !(this.target && (this.target === event.target || this.target.contains(event.target)));
        if (!this.popup && isOutsideContainer && isOutsideTarget) {
          this.onListBlur(event);
        }
        if (this.preventDocumentDefault && this.overlayVisible && isOutsideContainer && isOutsideTarget) {
          this.hide();
          this.preventDocumentDefault = false;
        }
      });
    }
  }
  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
  bindDocumentResizeListener() {
    if (!this.documentResizeListener && isPlatformBrowser(this.platformId)) {
      const window = this.document.defaultView;
      this.documentResizeListener = this.renderer.listen(window, 'resize', this.onWindowResize.bind(this));
    }
  }
  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      this.documentResizeListener();
      this.documentResizeListener = null;
    }
  }
  bindScrollListener() {
    if (!this.scrollHandler && isPlatformBrowser(this.platformId)) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.target, () => {
        if (this.visible) {
          this.hide();
        }
      });
    }
    this.scrollHandler?.bindScrollListener();
  }
  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.unbindScrollListener();
    this.preventDocumentDefault = false;
    if (!this.cd.destroyed) {
      this.target = null;
    }
  }
  ngOnDestroy() {
    if (this.popup) {
      if (this.scrollHandler) {
        this.scrollHandler.destroy();
        this.scrollHandler = null;
      }
      if (this.container && this.autoZIndex) {
        ZIndexUtils.clear(this.container);
      }
      this.restoreOverlayAppend();
      this.onOverlayHide();
    }
    if (!this.popup) {
      this.unbindDocumentClickListener();
    }
    super.ngOnDestroy();
  }
  hasSubMenu() {
    return this.model?.some(item => item.items) ?? false;
  }
  isItemHidden(item) {
    if (item.separator) {
      return item.visible === false || item.items && item.items.some(subitem => subitem.visible !== false);
    }
    return item.visible === false;
  }
  static ɵfac = function Menu_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Menu)(i0.ɵɵdirectiveInject(i5.OverlayService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Menu,
    selectors: [["p-menu"]],
    contentQueries: function Menu_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, _c4, 4);
        i0.ɵɵcontentQuery(dirIndex, _c5, 4);
        i0.ɵɵcontentQuery(dirIndex, _c6, 4);
        i0.ɵɵcontentQuery(dirIndex, _c7, 4);
        i0.ɵɵcontentQuery(dirIndex, _c8, 4);
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.startTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.endTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.submenuHeaderTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function Menu_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c9, 5);
        i0.ɵɵviewQuery(_c10, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.listViewChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.containerViewChild = _t.first);
      }
    },
    inputs: {
      model: "model",
      popup: [2, "popup", "popup", booleanAttribute],
      style: "style",
      styleClass: "styleClass",
      appendTo: "appendTo",
      autoZIndex: [2, "autoZIndex", "autoZIndex", booleanAttribute],
      baseZIndex: [2, "baseZIndex", "baseZIndex", numberAttribute],
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy",
      id: "id",
      tabindex: [2, "tabindex", "tabindex", numberAttribute]
    },
    outputs: {
      onShow: "onShow",
      onHide: "onHide",
      onBlur: "onBlur",
      onFocus: "onFocus"
    },
    features: [i0.ɵɵProvidersFeature([MenuStyle]), i0.ɵɵInheritDefinitionFeature],
    decls: 1,
    vars: 1,
    consts: [["container", ""], ["list", ""], ["htmlSubmenuLabel", ""], [3, "ngClass", "class", "ngStyle", "click", 4, "ngIf"], [3, "click", "ngClass", "ngStyle"], ["class", "p-menu-start", 4, "ngIf"], ["role", "menu", 1, "p-menu-list", "p-reset", 3, "focus", "blur", "keydown"], [4, "ngIf"], ["class", "p-menu-end", 4, "ngIf"], [1, "p-menu-start"], [4, "ngTemplateOutlet"], ["ngFor", "", 3, "ngForOf"], ["class", "p-menu-separator", "role", "separator", 4, "ngIf"], ["class", "p-menu-submenu-label", "pTooltip", "", "role", "none", 3, "ngClass", "tooltipOptions", 4, "ngIf"], ["role", "separator", 1, "p-menu-separator"], ["pTooltip", "", "role", "none", 1, "p-menu-submenu-label", 3, "ngClass", "tooltipOptions"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [4, "ngIf", "ngIfElse"], [3, "innerHTML"], ["class", "p-menu-item", "pTooltip", "", "role", "menuitem", 3, "pMenuItemContent", "itemTemplate", "ngClass", "ngStyle", "class", "tooltipOptions", "onMenuItemClick", 4, "ngIf"], ["pTooltip", "", "role", "menuitem", 1, "p-menu-item", 3, "onMenuItemClick", "pMenuItemContent", "itemTemplate", "ngClass", "ngStyle", "tooltipOptions"], [1, "p-menu-end"]],
    template: function Menu_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, Menu_div_0_Template, 8, 25, "div", 3);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", !ctx.popup || ctx.visible);
      }
    },
    dependencies: [CommonModule, i2.NgClass, i2.NgForOf, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, RouterModule, MenuItemContent, TooltipModule, i6.Tooltip, BadgeModule, SharedModule, SafeHtmlPipe],
    encapsulation: 2,
    data: {
      animation: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Menu, [{
    type: Component,
    args: [{
      selector: 'p-menu',
      standalone: true,
      imports: [CommonModule, RouterModule, MenuItemContent, TooltipModule, BadgeModule, SharedModule, SafeHtmlPipe],
      template: `
        <div
            #container
            [ngClass]="{ 'p-menu p-component': true, 'p-menu-overlay': popup }"
            [class]="styleClass"
            [ngStyle]="style"
            *ngIf="!popup || visible"
            (click)="onOverlayClick($event)"
            [@overlayAnimation]="{
                value: 'visible',
                params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
            }"
            [@.disabled]="popup !== true"
            (@overlayAnimation.start)="onOverlayAnimationStart($event)"
            (@overlayAnimation.done)="onOverlayAnimationEnd($event)"
            [attr.data-pc-name]="'menu'"
            [attr.id]="id"
        >
            <div *ngIf="startTemplate ?? _startTemplate" class="p-menu-start" [attr.data-pc-section]="'start'">
                <ng-container *ngTemplateOutlet="startTemplate ?? _startTemplate"></ng-container>
            </div>
            <ul
                #list
                class="p-menu-list p-reset"
                role="menu"
                [attr.id]="id + '_list'"
                [attr.tabindex]="getTabIndexValue()"
                [attr.data-pc-section]="'menu'"
                [attr.aria-activedescendant]="activedescendant()"
                [attr.aria-label]="ariaLabel"
                [attr.aria-labelledBy]="ariaLabelledBy"
                (focus)="onListFocus($event)"
                (blur)="onListBlur($event)"
                (keydown)="onListKeyDown($event)"
            >
                <ng-template ngFor let-submenu let-i="index" [ngForOf]="model" *ngIf="hasSubMenu()">
                    <li class="p-menu-separator" *ngIf="submenu.separator && submenu.visible !== false" role="separator"></li>
                    <li
                        class="p-menu-submenu-label"
                        [attr.data-automationid]="submenu.automationId"
                        *ngIf="!submenu.separator"
                        [ngClass]="{ 'p-hidden': submenu.visible === false, flex: submenu.visible }"
                        pTooltip
                        [tooltipOptions]="submenu.tooltipOptions"
                        role="none"
                        [attr.id]="menuitemId(submenu, id, i)"
                    >
                        <ng-container *ngIf="!submenuHeaderTemplate && !_submenuHeaderTemplate">
                            <span *ngIf="submenu.escape !== false; else htmlSubmenuLabel">{{ submenu.label }}</span>
                            <ng-template #htmlSubmenuLabel><span [innerHTML]="submenu.label | safeHtml"></span></ng-template>
                        </ng-container>
                        <ng-container *ngTemplateOutlet="submenuHeaderTemplate ?? _submenuHeaderTemplate; context: { $implicit: submenu }"></ng-container>
                    </li>
                    <ng-template ngFor let-item let-j="index" [ngForOf]="submenu.items">
                        <li class="p-menu-separator" *ngIf="item.separator && (item.visible !== false || submenu.visible !== false)" role="separator"></li>
                        <li
                            class="p-menu-item"
                            *ngIf="!item.separator && item.visible !== false && (item.visible !== undefined || submenu.visible !== false)"
                            [pMenuItemContent]="item"
                            [itemTemplate]="itemTemplate ?? _itemTemplate"
                            [ngClass]="{
                                'p-focus': focusedOptionId() && menuitemId(item, id, i, j) === focusedOptionId(),
                                'p-disabled': disabled(item.disabled)
                            }"
                            [ngStyle]="item.style"
                            [class]="item.styleClass"
                            (onMenuItemClick)="itemClick($event, menuitemId(item, id, i, j))"
                            pTooltip
                            [tooltipOptions]="item.tooltipOptions"
                            role="menuitem"
                            [attr.data-pc-section]="'menuitem'"
                            [attr.aria-label]="label(item.label)"
                            [attr.data-p-focused]="isItemFocused(menuitemId(item, id, i, j))"
                            [attr.data-p-disabled]="disabled(item.disabled)"
                            [attr.aria-disabled]="disabled(item.disabled)"
                            [attr.id]="menuitemId(item, id, i, j)"
                        ></li>
                    </ng-template>
                </ng-template>
                <ng-template ngFor let-item let-i="index" [ngForOf]="model" *ngIf="!hasSubMenu()">
                    <li class="p-menu-separator" *ngIf="item.separator && item.visible !== false" role="separator"></li>
                    <li
                        class="p-menu-item"
                        *ngIf="!item.separator && item.visible !== false"
                        [pMenuItemContent]="item"
                        [itemTemplate]="itemTemplate ?? _itemTemplate"
                        [ngClass]="{
                            'p-focus': focusedOptionId() && menuitemId(item, id, i) === focusedOptionId(),
                            'p-disabled': disabled(item.disabled)
                        }"
                        [ngStyle]="item.style"
                        [class]="item.styleClass"
                        (onMenuItemClick)="itemClick($event, menuitemId(item, id, i))"
                        pTooltip
                        [tooltipOptions]="item.tooltipOptions"
                        role="menuitem"
                        [attr.data-pc-section]="'menuitem'"
                        [attr.aria-label]="label(item.label)"
                        [attr.data-p-focused]="isItemFocused(menuitemId(item, id, i))"
                        [attr.data-p-disabled]="disabled(item.disabled)"
                        [attr.aria-disabled]="disabled(item.disabled)"
                        [attr.id]="menuitemId(item, id, i)"
                    ></li>
                </ng-template>
            </ul>
            <div *ngIf="endTemplate ?? _endTemplate" class="p-menu-end" [attr.data-pc-section]="'end'">
                <ng-container *ngTemplateOutlet="endTemplate ?? _endTemplate"></ng-container>
            </div>
        </div>
    `,
      animations: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [MenuStyle]
    }]
  }], () => [{
    type: i5.OverlayService
  }], {
    model: [{
      type: Input
    }],
    popup: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    appendTo: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    baseZIndex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    tabindex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    listViewChild: [{
      type: ViewChild,
      args: ['list']
    }],
    containerViewChild: [{
      type: ViewChild,
      args: ['container']
    }],
    startTemplate: [{
      type: ContentChild,
      args: ['start', {
        descendants: false
      }]
    }],
    endTemplate: [{
      type: ContentChild,
      args: ['end', {
        descendants: false
      }]
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['header', {
        descendants: false
      }]
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['item', {
        descendants: false
      }]
    }],
    submenuHeaderTemplate: [{
      type: ContentChild,
      args: ['submenuheader', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class MenuModule {
  static ɵfac = function MenuModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MenuModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MenuModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [Menu, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuModule, [{
    type: NgModule,
    args: [{
      imports: [Menu, SharedModule, SafeHtmlPipe],
      exports: [Menu, SharedModule, SafeHtmlPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Menu, MenuClasses, MenuItemContent, MenuModule, MenuStyle, SafeHtmlPipe };
