import { MsalService } from '@azure/msal-angular';
import { RoleConstants } from '../constants/role.constant';
import { AccountInfo } from '@azure/msal-browser';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class UserRoleService {
    private accountInfo: AccountInfo | null;
    public static RoleConstants = RoleConstants;

    constructor(_msalService: MsalService) {
        this.accountInfo = _msalService.instance.getActiveAccount();

        if (!this.accountInfo && _msalService.instance.getAllAccounts().length > 0) {
            const accounts = _msalService.instance.getAllAccounts()
            _msalService.instance.setActiveAccount(accounts[0]);
            this.accountInfo = _msalService.instance.getActiveAccount();
        }
    }

    public GetUserRole(): string[] {
        return this.accountInfo?.idTokenClaims?.roles ?? [];
    }

    public HasUserRole(roles: string | string[]): boolean {
        return this.GetUserRole().some(role => roles.includes(role));
    }
}