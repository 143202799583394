<ng-container *transloco="let t">
    <!--<label class="hidden sm:p-1 xl:p-1 sm:block xl:block" for="lang">{{ t('choose_language') }}: </label>
    <select class="p-lang-selector" (change)="onChange($event)" id="lang">
        <option *ngFor="let lang of languages" [value]="lang" [selected]="lang === currentLang">{{ t(lang) }}</option>
    </select>
    -->
    <p-select name="lang" [size]="'small'" [styleClass]="'p-lang-selector'" [options]="languages" [(ngModel)]="currentLang" (onChange)="onChange($event)">
        <ng-template let-option pTemplate="item">
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" style="margin-right: 0.5rem;" viewBox="0 0 64 64" enable-background="new 0 0 64 64">
                <switch><g [innerHTML]="option | languageToFlag"></g></switch>
            </svg>
            {{ t(option) }}
        </ng-template>

        <ng-template let-option pTemplate="selectedItem">
            <div style="float: left;">
                <svg style="display: inline; margin-right: 0.5rem;" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem"  viewBox="0 0 64 64" enable-background="new 0 0 64 64">
                    <switch><g [innerHTML]="option | languageToFlag"></g></switch>
                </svg>
                <span style="display: inline;">{{ t(option) }}</span>
            </div>    
        </ng-template>
    </p-select>
</ng-container>