<div class="h-full w-full flex items-center justify-center spinner-container" *ngIf="isLoading">
    <p-progressSpinner></p-progressSpinner>
</div>
<section *ngIf="!isLoading" class="max-w-4xl p-6 sm:my-4 mx-auto bg-white sm:rounded-2xl sm:border sm:border-gray-300">
    <div class="grid grid-cols-3">
        <button class="w-fit px-3 transition-colors duration-300 flex items-center justify-center py-1 rounded-md"
            (click)="back()">
            <i class="pi pi-angle-left"></i><span class="hidden sm:block"> Zurück</span>
        </button>
        <h2 class="text-lg text-center content-center font-semibold text-gray-700">
            Kostenträger
        </h2>
        <div>
            <svg class="ml-auto size-8 md:size-10 stroke-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
    </div>
    <form [formGroup]="formKostentraeger" *ngIf="!isLoading">
        <div class="grid grid-cols-1 gap-2 my-4 sm:grid-cols-2">
            <app-form-input inputType="text" labelName="Name*" inputId="name" formControlName="name" />
            <app-form-input inputType="text" labelName="Beschreibung*" inputId="beschreibung"
                formControlName="beschreibung" />
            <div class="flex w-full gap-3">
                <app-form-input labelName="Stundensatz*" inputId="stundensatz" inputType="price"
                    formControlName="stundensatz" />
                <app-form-input labelName="Kontingent" inputId="kontingent" inputType="number"
                    formControlName="kontingent" />
            </div>
            <div class="flex w-full gap-3 pt-6">
                <div class="flex gap-3 justify-center mb-3">
                    <label for="aktiv">Aktiv</label>
                    <p-inputSwitch inputId="aktiv" formControlName="aktiv" />
                </div>
                <div class="flex gap-3 justify-center mb-3">
                    <label for="intern">Intern</label>
                    <p-inputSwitch inputId="intern" formControlName="intern" />
                </div>
            </div>
        </div>

        <div class="flex justify-center mb-8 gap-2">
            <button
                class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-primary rounded-md hover:bg-primary focus:outline-none focus:bg-gray-600 disabled:bg-gray-200"
                [disabled]="formKostentraeger.invalid" type="submit" (click)="onSubmit()">
                Speichern
            </button>
            <button
                class="px-8 py-2.5 leading-5 text-primary transition-colors duration-300 transform border border-gray-200 rounded-md hover:bg-secondaryhover active:bg-primary active:transition-none"
                (click)="resetForm()">
                Zurücksetzen
            </button>
        </div>
        <p class="text-danger mt-3" *ngIf="formKostentraeger.invalid">
            Bitte alle benötigten (*) Felder ausfüllen!
        </p>
    </form>
</section>