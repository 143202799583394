import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { taetigkeitDeleteTaetigkeit } from 'src/app/global/apis/backend/fn/taetigkeit/taetigkeit-delete-taetigkeit';
import { taetigkeitExportFilteredTaetigkeit } from 'src/app/global/apis/backend/fn/taetigkeit/taetigkeit-export-filtered-taetigkeit';
import { taetigkeitExportTaetigkeit } from 'src/app/global/apis/backend/fn/taetigkeit/taetigkeit-export-taetigkeit';
import { taetigkeitFilterTaetigkeit } from 'src/app/global/apis/backend/fn/taetigkeit/taetigkeit-filter-taetigkeit';
import { TaetigkeitService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import {
    FunctionInjectionMap,
    GLOBAL_SEARCH_COLLUMS,
    LazyTableService,
    SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';

@Component({
    selector: 'app-taetigkeit',
    templateUrl: './taetigkeit.component.html',
    styleUrls: [],
    standalone: true,
    providers: [
        DialogService,
        {
            provide: GLOBAL_SEARCH_COLLUMS,
            useValue: [
                'mitarbeiter',
                'kostentraeger',
                'zusammenfassung'
            ],
        },
        {
            provide: SERVICE_FUNCTIONS,
            useValue: {
                getLazyData: taetigkeitFilterTaetigkeit,
                exportAll: taetigkeitExportFilteredTaetigkeit,
                exportSelected: taetigkeitExportTaetigkeit,
                deactivateSelected: taetigkeitDeleteTaetigkeit,
                archiveSelected: null,
            } as FunctionInjectionMap,
        },
        LazyTableService<TaetigkeitService>,
    ],
    imports: [
        CommonModule,
        ToastModule,
        TableModule,
        ProgressSpinnerModule,
        LazyTableComponent,
    ],
})
export class TaetigkeitComponent {
    diaRef!: DynamicDialogRef;
    public dataService: LazyTableService<TaetigkeitService> = inject(
        LazyTableService<TaetigkeitService>
    );

    public cols = [
        { field: 'mitarbeiter', header: 'Mitarbeiter', type: 'text' },
        { field: 'kostentraeger', header: 'Kostentraeger', type: 'text' },
        { field: 'datum', header: 'Datum', type: 'date' },
        { field: 'zusammenfassung', header: 'Zusammenfassung', type: 'text' },
        { field: 'stunden', header: 'Stunden', type: 'text' },
    ];

    constructor() { }
}
