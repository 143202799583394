<label [for]="inputId()">{{ labelName() }}</label>
<div class="relative">
  <i class="pi pi-calendar absolute z-10 top-3 right-3 pointer-events-none"></i>
  <p-calendar appendTo="body" inputStyleClass="width-100" dateFormat="dd.mm.yy" [showButtonBar]="true" [ngClass]="
      control().invalid && control().touched
        ? 'border-danger'
        : disabled()
        ? 'border-gray-100'
        : ' border-gray-400'
    "
    class="block w-full mt-2 text-gray-700 bg-white border rounded-md focus:border-primary focus:outline-none disabled:text-gray-500 disabled:border-gray-100"
    [ngModel]="value()" [ngModelOptions]="{ standalone: true }" (onSelect)="selectDate($event)"
    [disabled]="disabled()"></p-calendar>
</div>