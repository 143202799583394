<section *transloco="let t" class="grid grid-cols-1 gap-8 px-6 xl:grid-cols-3 2xl:grid-cols-4 md:grid-cols-2 mt-2">
    <!-- <div class="bg-white rounded-lg shadow-md shadow-gray-200 ">
        <app-quick-actions></app-quick-actions>
    </div> -->

    <div class="bg-white rounded-lg shadow-md shadow-gray-200 ">
        <app-current-time-bookings></app-current-time-bookings>
    </div>

    <div class="bg-white rounded-lg shadow-md shadow-gray-200 ">
        <app-bookings-warning></app-bookings-warning>
    </div>
</section>