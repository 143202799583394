import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FailedComponent } from './failed/failed.component';
import { CostCenterComponent } from './features/costcenter/costcenter.component';
import CostCenterDetailComponent from './features/costcenter/costcenter.detail/costcenter.detail.component';
import { EmployeeComponent } from './features/employee/employee.component';
import EmployeeDetailComponent from './features/employee/employee.detail/employee.detail.component';
import { TaetigkeitComponent } from './features/activity/activity.component';
import { TimeBookingComponent } from './features/timebooking/timebooking.component';
import TimeBookingDetailComponent from './features/timebooking/timebooking.detail/timebooking.detail.component';
import  AccountingComponent from './features/accounting/accounting.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { RoleConstants } from './shared/constants/role.constant';
import { RoleGuard } from './shared/guards/role.guard';

export const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR, RoleConstants.BASIC] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'timebooking',
    component: TimeBookingComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR, RoleConstants.BASIC] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'timebooking/detail',
    component: TimeBookingDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR, RoleConstants.BASIC] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'timebooking/detail/:id',
    component: TimeBookingDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR, RoleConstants.BASIC] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'activity',
    component: TaetigkeitComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'costcenter',
    component: CostCenterComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'costcenter/detail',
    component: CostCenterDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'costcenter/detail/:id',
    component: CostCenterDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'employee',
    component: EmployeeComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'employee/detail',
    component: EmployeeDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'employee/detail/:id',
    component: EmployeeDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'accounting',
    component: AccountingComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: '',
    component: HomeComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR, RoleConstants.BASIC] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
];
