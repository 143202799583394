import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';

const Preset = definePreset(Aura, {
    semantic: {
        primary: {
            "50": "#6492da",
            "100": "#5a88d0",
            "200": "#507ec6",
            "300": "#4674bc",
            "400": "#3c6ab2",
            "500": "var(--primary-color)",
            "600": "#28569e",
            "700": "#1e4c94",
            "800": "#14428a",
            "900": "#0a3880",
        },
        focusRing: {
            width: '2px',
            style: 'dashed',
            color: '{primary.color}',
            offset: '1px'
        },
        colorScheme: {
            light: {
                primary: {
                    color: '{primary.500}',
                    inverseColor: '#ffffff',
                    hoverColor: '{primary.900}',
                    activeColor: '{primary.800}'
                },
                highlight: {
                    background: '{primary.400}',
                    focusBackground: '{primary.400}',
                    color: '#ffffff',
                    focusColor: '#ffffff'
                }
            },
        },
    }
});

export default Preset;