import * as i0 from '@angular/core';
import { Injectable, booleanAttribute, Input, ViewEncapsulation, ChangeDetectionStrategy, Component } from '@angular/core';
import { isEmpty } from '@primeuix/utils';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const _c0 = ["*"];
const css = `
.p-icon {
    display: inline-block;
    vertical-align: baseline;
}

.p-icon-spin {
    -webkit-animation: p-icon-spin 2s infinite linear;
    animation: p-icon-spin 2s infinite linear;
}

@-webkit-keyframes p-icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes p-icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
`;
class BaseIconStyle extends BaseStyle {
  name = 'baseicon';
  inlineStyles = css;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵBaseIconStyle_BaseFactory;
    return function BaseIconStyle_Factory(__ngFactoryType__) {
      return (ɵBaseIconStyle_BaseFactory || (ɵBaseIconStyle_BaseFactory = i0.ɵɵgetInheritedFactory(BaseIconStyle)))(__ngFactoryType__ || BaseIconStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BaseIconStyle,
    factory: BaseIconStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseIconStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * [Live Demo](https://www.primeng.org/)
 *
 * @module baseiconstyle
 *
 */
var BaseIconClasses;
(function (BaseIconClasses) {
  BaseIconClasses["root"] = "p-icon";
})(BaseIconClasses || (BaseIconClasses = {}));
class BaseIcon extends BaseComponent {
  label;
  spin = false;
  styleClass;
  role;
  ariaLabel;
  ariaHidden;
  ngOnInit() {
    super.ngOnInit();
    this.getAttributes();
  }
  getAttributes() {
    const isLabelEmpty = isEmpty(this.label);
    this.role = !isLabelEmpty ? 'img' : undefined;
    this.ariaLabel = !isLabelEmpty ? this.label : undefined;
    this.ariaHidden = isLabelEmpty;
  }
  getClassNames() {
    return `p-icon ${this.styleClass ? this.styleClass + ' ' : ''}${this.spin ? 'p-icon-spin' : ''}`;
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵBaseIcon_BaseFactory;
    return function BaseIcon_Factory(__ngFactoryType__) {
      return (ɵBaseIcon_BaseFactory || (ɵBaseIcon_BaseFactory = i0.ɵɵgetInheritedFactory(BaseIcon)))(__ngFactoryType__ || BaseIcon);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: BaseIcon,
    selectors: [["ng-component"]],
    hostAttrs: [1, "p-component", "p-iconwrapper"],
    inputs: {
      label: "label",
      spin: [2, "spin", "spin", booleanAttribute],
      styleClass: "styleClass"
    },
    features: [i0.ɵɵProvidersFeature([BaseIconStyle]), i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function BaseIcon_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseIcon, [{
    type: Component,
    args: [{
      template: ` <ng-content></ng-content> `,
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [BaseIconStyle],
      host: {
        class: 'p-component p-iconwrapper'
      }
    }]
  }], null, {
    label: [{
      type: Input
    }],
    spin: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    styleClass: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BaseIcon, BaseIconClasses, BaseIconStyle };
