import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorNotificationService implements ErrorHandler {

  constructor(private snackbar: MatSnackBar, private zone: NgZone) { }

  // general error handler
  handleError(error: any) {
    this.zone.run(() => {
      this.snackbar.open(
        error,
        'Close', {
          panelClass: 'snackbar-warning',
          verticalPosition: 'top',
          horizontalPosition: 'right'
        }
      );
    })
    console.warn(`Caught by Custom Error Handler: `, error);
  }
}
