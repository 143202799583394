import { CommonModule, registerLocaleData } from '@angular/common';
import { Component, inject, LOCALE_ID, OnInit } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { FilterMatchMode, PrimeNGConfig } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MainlayoutComponent } from './shared/layout/mainlayout/mainlayout.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    MainlayoutComponent,
    CommonModule,
    ToastModule
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig
  ) {
    registerLocaleData(this.localeDe);
  }
  private localeDe = inject(LOCALE_ID);
  title = 'copacking-frontend';
  isIframe = false

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.primengConfig.filterMatchModeOptions = {
      text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
      ],
      date: [
        FilterMatchMode.DATE_IS,
        FilterMatchMode.DATE_IS_NOT,
        FilterMatchMode.DATE_BEFORE,
        FilterMatchMode.DATE_AFTER,
      ],
    };
    this.primengConfig.setTranslation({
      accept: 'Akzeptieren',
      reject: 'Abbrechen',
      startsWith: 'Beginnt mit',
      contains: 'Enthält',
      notContains: 'Enthält nicht',
      endsWith: 'Endet mit',
      equals: 'Gleich',
      notEquals: 'Nicht gleich',
      noFilter: 'Kein Filter',
      lt: 'Weniger als',
      lte: 'Weniger oder gleich',
      gt: 'Größer als',
      gte: 'Größer oder gleich',
      is: 'Ist',
      isNot: 'Ist nicht',
      before: 'Vor',
      after: 'Nach',
      clear: 'Löschen',
      apply: 'Anwenden',
      dateIs: 'gleich',
      dateIsNot: 'nicht gleich',
      dateBefore: 'vor',
      dateAfter: 'nach',
      matchAll: 'Alle Einträge',
      matchAny: 'Mindestens Einer',
      addRule: 'Regel hinzufügen',
      removeRule: 'Regel entfernen',
      dateFormat: 'dd.mm.yy',
    });
  }
}
