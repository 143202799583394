import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { zeitbuchungDeActivate } from 'src/app/global/apis/backend/fn/zeitbuchung/zeitbuchung-de-activate';
import { zeitbuchungExportFilteredZeitbuchung } from 'src/app/global/apis/backend/fn/zeitbuchung/zeitbuchung-export-filtered-zeitbuchung';
import { zeitbuchungExportZeitbuchung } from 'src/app/global/apis/backend/fn/zeitbuchung/zeitbuchung-export-zeitbuchung';
import { zeitbuchungFilterZeitbuchung } from 'src/app/global/apis/backend/fn/zeitbuchung/zeitbuchung-filter-zeitbuchung';
import { ZeitbuchungService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import {
    FunctionInjectionMap,
    GLOBAL_SEARCH_COLLUMS,
    LazyTableService,
    SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';

@Component({
    selector: 'app-zeitbuchung',
    templateUrl: './zeitbuchung.component.html',
    styleUrls: [],
    standalone: true,
    providers: [
        DialogService,
        {
            provide: GLOBAL_SEARCH_COLLUMS,
            useValue: [
                'mitarbeiter'
            ],
        },
        {
            provide: SERVICE_FUNCTIONS,
            useValue: {
                getLazyData: zeitbuchungFilterZeitbuchung,
                exportAll: zeitbuchungExportFilteredZeitbuchung,
                exportSelected: zeitbuchungExportZeitbuchung,
                deactivateSelected: zeitbuchungDeActivate,
                archiveSelected: null,
            } as FunctionInjectionMap,
        },
        LazyTableService<ZeitbuchungService>,
    ],
    imports: [
        CommonModule,
        ToastModule,
        TableModule,
        ProgressSpinnerModule,
        LazyTableComponent,
    ],
})
export class ZeitbuchungComponent {
    diaRef!: DynamicDialogRef;
    // private readonly destroy$ = new Subject();
    public dataService: LazyTableService<ZeitbuchungService> = inject(
        LazyTableService<ZeitbuchungService>
    );

    public cols = [
        { field: 'mitarbeiter', header: 'Mitarbeiter', type: 'text' },
        { field: 'datum', header: 'Datum', type: 'date' },
        { field: 'startzeit', header: 'Startzeit', type: 'time', noFilter: true, transform: (value: string) => new Date(`1970-01-01T${value}`) },
        { field: 'endzeit', header: 'Endzeit', type: 'time', noFilter: true, transform: (value: string) => new Date(`1970-01-01T${value}`) },
        { field: 'pause', header: 'Pause', type: 'numeric' },
        { field: 'stundenExklPause', header: 'Stunden exkl. Pause', type: 'numeric' },
        { field: 'stundenInklPause', header: 'Stunden inkl. Pause', type: 'numeric' },
        { field: 'stundenOffen', header: 'Stunden offen', type: 'numeric' },
        { field: 'stundenVerrechenbar', header: 'Stunden verrechenbar', type: 'numeric' },
        { field: 'verrechenbarkeit', header: 'Verrechenbarkeit', type: 'numeric' },
    ];

    constructor() { }
}
