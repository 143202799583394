<label [for]="inputId()">{{ labelName() }}</label>
<div class="relative">
  <i class="pi pi-clock absolute z-10 top-3 right-3 pointer-events-none"></i>
  <p-calendar [timeOnly]="true" appendTo="body" inputStyleClass="width-100" dateFormat="dd.mm.yy" [ngClass]="
      control().invalid && control().touched
        ? 'border-danger'
        : ' border-gray-400'
    " class="block w-full mt-2 text-gray-700 bg-white border rounded-md focus:border-primary focus:outline-none"
    [ngModel]="value()" [ngModelOptions]="{ standalone: true }" (onSelect)="selectDate($event)"
    [showSeconds]="showSeconds()" [stepMinute]="stepMinute()" [readonlyInput]="readonlyInput()"
    [maxDate]="maxDate() ? maxDate() : undefined" [minDate]="minDate() ? minDate() : undefined"></p-calendar>
</div>