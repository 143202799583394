import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { activityComputeField } from 'src/app/global/apis/backend/fn/activity/activity-compute-field';
import { activityDeleteActivity } from 'src/app/global/apis/backend/fn/activity/activity-delete-activity';
import { activityExportActivity } from 'src/app/global/apis/backend/fn/activity/activity-export-activity';
import { activityExportFilteredActivity } from 'src/app/global/apis/backend/fn/activity/activity-export-filtered-activity';
import { activityFilterActivity } from 'src/app/global/apis/backend/fn/activity/activity-filter-activity';
import { ActivityService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import {
  FunctionInjectionMap,
  GLOBAL_SEARCH_COLLUMS,
  LazyTableService,
  SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';
import { CostCentreFilter } from 'src/app/lazy-table/table-filters/cost-centre-filter';
import { EmployeeFilter } from 'src/app/lazy-table/table-filters/employee-filter';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: [],
  providers: [
    DialogService,
    {
      provide: GLOBAL_SEARCH_COLLUMS,
      useValue: [
        'employee',
        'costcenter',
        'summary'
      ],
    },
    {
      provide: SERVICE_FUNCTIONS,
      useValue: {
        getLazyData: activityFilterActivity,
        exportAll: activityExportFilteredActivity,
        exportSelected: activityExportActivity,
        deactivateSelected: activityDeleteActivity,
        computeField: activityComputeField,
        archiveSelected: null,
      } as FunctionInjectionMap,
    },
    LazyTableService<ActivityService>,
  ],
  imports: [
    CommonModule,
    ToastModule,
    TableModule,
    ProgressSpinnerModule,
    LazyTableComponent,
  ]
})
export class TaetigkeitComponent {
  diaRef!: DynamicDialogRef;
  public dataService: LazyTableService<ActivityService> = inject(
    LazyTableService<ActivityService>
  );
  public employeeFilter: EmployeeFilter = new EmployeeFilter;
  public costcentreFilter: CostCentreFilter = new CostCentreFilter;
  private _translocoService = inject(TranslocoService);

  public cols = [
    { field: 'employee', header: 'employee', type: 'text', selectionFilter: this.employeeFilter.EmployeeFilter() },
    { field: 'costCenter', header: 'cost_centre', type: 'text', selectionFilter: this.costcentreFilter.CostCentreFilter() },
    { field: 'date', header: 'date', type: 'date' },
    { field: 'summary', header: 'summary', type: 'text' },
    { field: 'hours', header: 'hours', type: 'numeric' },
    { field: 'workLocation', header: 'worklocation', type: 'text', preselect: false },
    { field: 'surcharge', header: 'surcharge', type: 'numeric', preselect: false },
    { field: 'factor', header: 'factor', type: 'numeric', preselect: false },
    {
      field: 'homeOffice',
      header: 'home-office',
      type: 'text',
      tag: true,
      transform: (value: any) => (value ? this._translocoService.translate('features.athome') : this._translocoService.translate('features.onsite')),
      filter: [{ action: 'equals', value: this._translocoService.translate('features.athome') }],
      selectionFilter: [
        { value: true, name: this._translocoService.translate('features.onsite') },
        { value: false, name: this._translocoService.translate('features.athome') },
      ],
      preselect: false
    }
  ];
  constructor() { }
}
