<div *transloco="let t">
    <h3 class="text-xl sm:grid sm:place-items-center sm:text-3xl sm:py-3">{{t('current')}} {{t('timeBookings')}}</h3>
    <div class="flex justify-center">
        <div class="sm:grid sm:place-items-center sm:mx-10 sm:mt-4">
            <table class="sm:grid sm:place-items-center sm:table-auto sm:w-full sm:h-full sm:border-collapse">
                <thead class="border-b-2 border-gray-500">
                <div *transloco="let ft; scope:'features'; prefix:'features'; loadingTpl: loading">    
                <tr>
                    <th class="px-1 py-0.5 sm:px-4 sm:py-2">{{ ft('beginning') }}</th>
                    <th class="px-1 py-0.5 sm:px-4 sm:py-2">{{ ft('ending') }}</th>
                    <th class="px-1 py-0.5 sm:px-4 sm:py-2">{{ ft('date') }}</th>
                </tr>
                </div>
                </thead>
                <tbody>
                    @for(booking of _timeBookings; track booking.id){
                        <tr>
                            <td class="px-1 py-0.5 sm:px-4 sm:py-2">{{ booking.start }}</td>
                            <td class="px-0 py-0.5 sm:px-4 sm:py-2 sm:border-r-2 sm:border-gray-500">{{ booking.end }}</td>
                            <td class="px-1 py-0.5 sm:px-4 sm:py-2"><a href="/timebooking/detail/{{booking.id}}">{{ booking.date }}</a></td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>
<ng-template #loading>
    <p data-cy="lazy-page-loading">Loading...</p>
</ng-template>
