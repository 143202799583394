<header *transloco="let t" class="flex items-center justify-between h-16 px-6 bg-white">
    <div class="relative flex items-center">
        <app-language-switcher
            class="flex space-x-4 py-2.5 pl-10 pr-4 text-gray-700 placeholder-gray-400 bg-white border border-transparent border-gray-200 rounded-lg sm:w-auto w-48 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" />
    </div>

    <div class="flex items-center">
        <div class="relative">
            <div class="flex items-center md:-mx-2 ">
                <div *ngIf="accountInfo" class="hidden md:mx-2 md:flex md:flex-col md:items-end md:leading-tight">
                    <span class="font-semibold text-sm text-gray-800">{{ accountInfo.name }}</span>
                    <span class="text-sm text-gray-600">{{ accountInfo.idTokenClaims?.roles }}</span>
                </div>

                <img *ngIf="photo" class="flex-shrink-0 w-10 h-10 overflow-hidden bg-gray-100 rounded-full md:mx-2"
                    [src]="photo" alt="user profile photo">
            </div>
        </div>

        <button *ngIf="!loginDisplay" (click)="loginRedirect()"
            class="p-2 text-gray-400 transition-colors duration-300 rounded-full focus:outline-none hover:bg-gray-100 hover:text-gray-600 focus:bg-gray-100">
            <span class="sr-only">{{ t('logout') }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
            </svg>

        </button>
        <button *ngIf="loginDisplay" (click)="logout()"
            class="p-2 text-gray-400 transition-colors duration-300 rounded-full focus:outline-none hover:bg-gray-100 hover:text-gray-600 focus:bg-gray-100">
            <span class="sr-only">{{ t('logout') }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
            </svg>

        </button>
    </div>
</header>