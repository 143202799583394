<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.34 80.5">
  <defs>
    <style>
      .cls-1,
      .cls-2 {
        fill: #fff;
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-1,
      .cls-2,
      .cls-3 {
        stroke: #fff;
      }

      .cls-2 {
        stroke-width: 2px;
      }

      .cls-3 {
        fill: none;
        stroke-miterlimit: 5;
        stroke-width: 3px;
      }
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <path class="cls-3"
      d="M6.45,52.7c1.78,3.83,5.35,10.26,11.65,15.84,1.68,1.49,5.29,4.66,10.6,7.05,15.44,6.95,30.09,1.27,31.97.51,1.43-.58,12.08-5.25,18.78-16.29M27.49,10.87c2.83-.39,5.16-3.31,4.44-6-.61-2.32-3.27-3.62-5.56-3.33-2.52.31-5.02,2.6-4.67,5.33.34,2.68,3.25,4.35,5.78,4ZM83.37,51.08s.41-1.4.69-2.83c2.86-14.51-4.53-30.8-17.33-40C52.53-1.94,34.05,2.48,30.87,3.06M48.37,60.14c2.83-.39,5.16-3.31,4.44-6-.61-2.32-3.27-3.62-5.56-3.33-2.52.31-5.02,2.6-4.67,5.33.34,2.68,3.25,4.35,5.78,4ZM75.3,28.36l-23.37,24M82.27,60.25c2.83-.39,5.16-3.31,4.44-6-.61-2.32-3.27-3.62-5.56-3.33-2.52.31-5.02,2.6-4.67,5.33.34,2.68,3.25,4.35,5.78,4ZM71.22,32.54l-12.49-13.34-8.69,8.54M84.52,38.75l-9.21-10.39-13.04,13.33-25.44-27.89-26.89,30.26M36.82,13.81l-27.43,30.87" />
    <circle class="cls-3" cx="6.14" cy="48.08" r="4.64" />
    <polygon class="cls-2"
      points="36.75 21.36 27.93 31.89 30.63 39.09 34.5 33.24 39.27 39.09 41.34 33.6 49.71 36.39 36.75 21.36" />
    <polygon class="cls-2"
      points="71.38 38.86 75.03 34.51 80.39 40.72 76.93 39.57 76.07 41.84 73.66 40.31 71.38 38.86" />
    <polyline class="cls-1" points="55.79 26.4 58.32 28.09 59.53 26.85 61.54 27.58 58.42 23.65 55.79 26.4" />
  </g>
</svg>