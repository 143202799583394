import { Component, inject, OnInit } from '@angular/core';
import { TimeBookingService } from 'src/app/global/apis/backend/services';
import { FilterTimeBookingCommand, TimeBookingFilter } from 'src/app/global/apis/backend/models';
import { Router } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  standalone: true,
  selector: 'app-current-time-bookings',
  imports: [TranslocoDirective],
  templateUrl: './current-time-bookings.component.html',
})
export class CurrentTimeBookingsComponent implements OnInit {
  constructor(private router: Router){}
  private readonly _timeBookingService = inject(TimeBookingService);
  public _timeBookings: TimeBookingFilter[] = [];

  ngOnInit(): void {
    this.loadTimeBookings();
  }

  loadTimeBookings(): void {
    const params: FilterTimeBookingCommand = {
      tableFilterPayload: {
        first: 0,
        rows: 3,
        sortField: 'date',
        sortOrder: -1
      }
    };

    this._timeBookingService.timeBookingFilterTimeBooking({body: params })
      .subscribe((response) => {
        this._timeBookings = response.data!;
      });
  }
}
