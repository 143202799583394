import { Component } from '@angular/core';
import { BannerComponent } from "../../svg/banner/banner.component";

@Component({
    selector: 'app-footer',
    standalone: true,
    templateUrl: './footer.component.html',
    imports: [BannerComponent]
})
export class FooterComponent {

}
