<footer class="bg-gray-800">
    <div class="px-5 xl:px-12 py-3 mx-auto overflow-hidden">
        <div class="flex flex-col sm:flex-row sm:justify-between items-center sm:items-end">
            <div>
                <span class="text-base text-white sm:pb-10">© ALPSCALE 2024. All Rights Reserved.</span>
            </div>
            <a href="https://alpscale.io/" class="text-white text-center w-32">
                <span class="text-xs">powered by</span>
                <app-banner />
            </a>
            <div class="grid grid-cols-2 sm:grid-cols-none">
                <div class="flex mt-3 -mx-2 sm:mt-0 pb-5 sm:pb-0">
                    <a href="https://alpscale.io/privacy/" target="_blank" class="mx-2 text-base text-white underline"
                        aria-label="Privacy">Privacy</a>
                    <a href="https://alpscale.io/legal-notice/" target="_blank"
                        class="mx-2 text-base text-white underline" aria-label="Legal Notice">Legal
                        Notice</a>
                </div>
            </div>
        </div>
    </div>
</footer>