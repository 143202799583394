/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activityComputeField } from '../fn/activity/activity-compute-field';
import { ActivityComputeField$Params } from '../fn/activity/activity-compute-field';
import { activityCreateActivity } from '../fn/activity/activity-create-activity';
import { ActivityCreateActivity$Params } from '../fn/activity/activity-create-activity';
import { activityDeleteActivity } from '../fn/activity/activity-delete-activity';
import { ActivityDeleteActivity$Params } from '../fn/activity/activity-delete-activity';
import { ActivityDto } from '../models/activity-dto';
import { activityExportActivity } from '../fn/activity/activity-export-activity';
import { ActivityExportActivity$Params } from '../fn/activity/activity-export-activity';
import { activityExportFilteredActivity } from '../fn/activity/activity-export-filtered-activity';
import { ActivityExportFilteredActivity$Params } from '../fn/activity/activity-export-filtered-activity';
import { activityFilterActivity } from '../fn/activity/activity-filter-activity';
import { ActivityFilterActivity$Params } from '../fn/activity/activity-filter-activity';
import { activityGetActivityById } from '../fn/activity/activity-get-activity-by-id';
import { ActivityGetActivityById$Params } from '../fn/activity/activity-get-activity-by-id';
import { activityUpdateActivity } from '../fn/activity/activity-update-activity';
import { ActivityUpdateActivity$Params } from '../fn/activity/activity-update-activity';
import { PagedListOfActivityFilter } from '../models/paged-list-of-activity-filter';
import { ResultOfListOfGuid } from '../models/result-of-list-of-guid';

@Injectable({ providedIn: 'root' })
export class ActivityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activityGetActivityById()` */
  static readonly ActivityGetActivityByIdPath = '/api/Activity/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityGetActivityById()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityGetActivityById$Response(params: ActivityGetActivityById$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityDto>> {
    return activityGetActivityById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityGetActivityById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activityGetActivityById(params: ActivityGetActivityById$Params, context?: HttpContext): Observable<ActivityDto> {
    return this.activityGetActivityById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDto>): ActivityDto => r.body)
    );
  }

  /** Path part for operation `activityUpdateActivity()` */
  static readonly ActivityUpdateActivityPath = '/api/Activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityUpdateActivity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityUpdateActivity$Response(params: ActivityUpdateActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return activityUpdateActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityUpdateActivity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityUpdateActivity(params: ActivityUpdateActivity$Params, context?: HttpContext): Observable<string> {
    return this.activityUpdateActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `activityCreateActivity()` */
  static readonly ActivityCreateActivityPath = '/api/Activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityCreateActivity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityCreateActivity$Response(params: ActivityCreateActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return activityCreateActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityCreateActivity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityCreateActivity(params: ActivityCreateActivity$Params, context?: HttpContext): Observable<string> {
    return this.activityCreateActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `activityDeleteActivity()` */
  static readonly ActivityDeleteActivityPath = '/api/Activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDeleteActivity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDeleteActivity$Response(params: ActivityDeleteActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<ResultOfListOfGuid>> {
    return activityDeleteActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDeleteActivity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDeleteActivity(params: ActivityDeleteActivity$Params, context?: HttpContext): Observable<ResultOfListOfGuid> {
    return this.activityDeleteActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResultOfListOfGuid>): ResultOfListOfGuid => r.body)
    );
  }

  /** Path part for operation `activityExportActivity()` */
  static readonly ActivityExportActivityPath = '/api/Activity/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityExportActivity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityExportActivity$Response(params: ActivityExportActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return activityExportActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityExportActivity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityExportActivity(params: ActivityExportActivity$Params, context?: HttpContext): Observable<Blob> {
    return this.activityExportActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `activityFilterActivity()` */
  static readonly ActivityFilterActivityPath = '/api/Activity/filter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityFilterActivity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityFilterActivity$Response(params: ActivityFilterActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedListOfActivityFilter>> {
    return activityFilterActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityFilterActivity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityFilterActivity(params: ActivityFilterActivity$Params, context?: HttpContext): Observable<PagedListOfActivityFilter> {
    return this.activityFilterActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagedListOfActivityFilter>): PagedListOfActivityFilter => r.body)
    );
  }

  /** Path part for operation `activityExportFilteredActivity()` */
  static readonly ActivityExportFilteredActivityPath = '/api/Activity/filter/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityExportFilteredActivity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityExportFilteredActivity$Response(params: ActivityExportFilteredActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return activityExportFilteredActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityExportFilteredActivity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityExportFilteredActivity(params: ActivityExportFilteredActivity$Params, context?: HttpContext): Observable<Blob> {
    return this.activityExportFilteredActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `activityComputeField()` */
  static readonly ActivityComputeFieldPath = '/api/Activity/filter/compute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityComputeField()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityComputeField$Response(params: ActivityComputeField$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return activityComputeField(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityComputeField$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityComputeField(params: ActivityComputeField$Params, context?: HttpContext): Observable<number> {
    return this.activityComputeField$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
