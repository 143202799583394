/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum Familienstand {
  Ledig = 0,
  Verheiratet = 1,
  Geschieden = 2,
  Verwitwet = 3
}
