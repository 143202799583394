/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { kostentraegerCreateKostentraeger } from '../fn/kostentraeger/kostentraeger-create-kostentraeger';
import { KostentraegerCreateKostentraeger$Params } from '../fn/kostentraeger/kostentraeger-create-kostentraeger';
import { kostentraegerDeActivateKostentraeger } from '../fn/kostentraeger/kostentraeger-de-activate-kostentraeger';
import { KostentraegerDeActivateKostentraeger$Params } from '../fn/kostentraeger/kostentraeger-de-activate-kostentraeger';
import { KostentraegerDto } from '../models/kostentraeger-dto';
import { kostentraegerExportFilteredKostentraeger } from '../fn/kostentraeger/kostentraeger-export-filtered-kostentraeger';
import { KostentraegerExportFilteredKostentraeger$Params } from '../fn/kostentraeger/kostentraeger-export-filtered-kostentraeger';
import { kostentraegerExportKostentraeger } from '../fn/kostentraeger/kostentraeger-export-kostentraeger';
import { KostentraegerExportKostentraeger$Params } from '../fn/kostentraeger/kostentraeger-export-kostentraeger';
import { kostentraegerFilterKostentraeger } from '../fn/kostentraeger/kostentraeger-filter-kostentraeger';
import { KostentraegerFilterKostentraeger$Params } from '../fn/kostentraeger/kostentraeger-filter-kostentraeger';
import { kostentraegerGetAllKostentraeger } from '../fn/kostentraeger/kostentraeger-get-all-kostentraeger';
import { KostentraegerGetAllKostentraeger$Params } from '../fn/kostentraeger/kostentraeger-get-all-kostentraeger';
import { kostentraegerGetKostentraegerById } from '../fn/kostentraeger/kostentraeger-get-kostentraeger-by-id';
import { KostentraegerGetKostentraegerById$Params } from '../fn/kostentraeger/kostentraeger-get-kostentraeger-by-id';
import { KostentraegerMinimalDto } from '../models/kostentraeger-minimal-dto';
import { kostentraegerUpdateKostentraeger } from '../fn/kostentraeger/kostentraeger-update-kostentraeger';
import { KostentraegerUpdateKostentraeger$Params } from '../fn/kostentraeger/kostentraeger-update-kostentraeger';
import { PagedListOfKostentraegerFilter } from '../models/paged-list-of-kostentraeger-filter';
import { ResultOfListOfGuid } from '../models/result-of-list-of-guid';

@Injectable({ providedIn: 'root' })
export class KostentraegerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `kostentraegerGetKostentraegerById()` */
  static readonly KostentraegerGetKostentraegerByIdPath = '/api/Kostentraeger/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kostentraegerGetKostentraegerById()` instead.
   *
   * This method doesn't expect any request body.
   */
  kostentraegerGetKostentraegerById$Response(params: KostentraegerGetKostentraegerById$Params, context?: HttpContext): Observable<StrictHttpResponse<KostentraegerDto>> {
    return kostentraegerGetKostentraegerById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kostentraegerGetKostentraegerById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  kostentraegerGetKostentraegerById(params: KostentraegerGetKostentraegerById$Params, context?: HttpContext): Observable<KostentraegerDto> {
    return this.kostentraegerGetKostentraegerById$Response(params, context).pipe(
      map((r: StrictHttpResponse<KostentraegerDto>): KostentraegerDto => r.body)
    );
  }

  /** Path part for operation `kostentraegerGetAllKostentraeger()` */
  static readonly KostentraegerGetAllKostentraegerPath = '/api/Kostentraeger';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kostentraegerGetAllKostentraeger()` instead.
   *
   * This method doesn't expect any request body.
   */
  kostentraegerGetAllKostentraeger$Response(params?: KostentraegerGetAllKostentraeger$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KostentraegerMinimalDto>>> {
    return kostentraegerGetAllKostentraeger(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kostentraegerGetAllKostentraeger$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  kostentraegerGetAllKostentraeger(params?: KostentraegerGetAllKostentraeger$Params, context?: HttpContext): Observable<Array<KostentraegerMinimalDto>> {
    return this.kostentraegerGetAllKostentraeger$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KostentraegerMinimalDto>>): Array<KostentraegerMinimalDto> => r.body)
    );
  }

  /** Path part for operation `kostentraegerUpdateKostentraeger()` */
  static readonly KostentraegerUpdateKostentraegerPath = '/api/Kostentraeger';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kostentraegerUpdateKostentraeger()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerUpdateKostentraeger$Response(params: KostentraegerUpdateKostentraeger$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return kostentraegerUpdateKostentraeger(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kostentraegerUpdateKostentraeger$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerUpdateKostentraeger(params: KostentraegerUpdateKostentraeger$Params, context?: HttpContext): Observable<string> {
    return this.kostentraegerUpdateKostentraeger$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `kostentraegerCreateKostentraeger()` */
  static readonly KostentraegerCreateKostentraegerPath = '/api/Kostentraeger';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kostentraegerCreateKostentraeger()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerCreateKostentraeger$Response(params: KostentraegerCreateKostentraeger$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return kostentraegerCreateKostentraeger(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kostentraegerCreateKostentraeger$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerCreateKostentraeger(params: KostentraegerCreateKostentraeger$Params, context?: HttpContext): Observable<string> {
    return this.kostentraegerCreateKostentraeger$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `kostentraegerExportKostentraeger()` */
  static readonly KostentraegerExportKostentraegerPath = '/api/Kostentraeger/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kostentraegerExportKostentraeger()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerExportKostentraeger$Response(params: KostentraegerExportKostentraeger$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return kostentraegerExportKostentraeger(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kostentraegerExportKostentraeger$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerExportKostentraeger(params: KostentraegerExportKostentraeger$Params, context?: HttpContext): Observable<Blob> {
    return this.kostentraegerExportKostentraeger$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `kostentraegerFilterKostentraeger()` */
  static readonly KostentraegerFilterKostentraegerPath = '/api/Kostentraeger/filter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kostentraegerFilterKostentraeger()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerFilterKostentraeger$Response(params: KostentraegerFilterKostentraeger$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedListOfKostentraegerFilter>> {
    return kostentraegerFilterKostentraeger(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kostentraegerFilterKostentraeger$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerFilterKostentraeger(params: KostentraegerFilterKostentraeger$Params, context?: HttpContext): Observable<PagedListOfKostentraegerFilter> {
    return this.kostentraegerFilterKostentraeger$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagedListOfKostentraegerFilter>): PagedListOfKostentraegerFilter => r.body)
    );
  }

  /** Path part for operation `kostentraegerExportFilteredKostentraeger()` */
  static readonly KostentraegerExportFilteredKostentraegerPath = '/api/Kostentraeger/filter/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kostentraegerExportFilteredKostentraeger()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerExportFilteredKostentraeger$Response(params: KostentraegerExportFilteredKostentraeger$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return kostentraegerExportFilteredKostentraeger(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kostentraegerExportFilteredKostentraeger$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerExportFilteredKostentraeger(params: KostentraegerExportFilteredKostentraeger$Params, context?: HttpContext): Observable<Blob> {
    return this.kostentraegerExportFilteredKostentraeger$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `kostentraegerDeActivateKostentraeger()` */
  static readonly KostentraegerDeActivateKostentraegerPath = '/api/Kostentraeger/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `kostentraegerDeActivateKostentraeger()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerDeActivateKostentraeger$Response(params: KostentraegerDeActivateKostentraeger$Params, context?: HttpContext): Observable<StrictHttpResponse<ResultOfListOfGuid>> {
    return kostentraegerDeActivateKostentraeger(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `kostentraegerDeActivateKostentraeger$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  kostentraegerDeActivateKostentraeger(params: KostentraegerDeActivateKostentraeger$Params, context?: HttpContext): Observable<ResultOfListOfGuid> {
    return this.kostentraegerDeActivateKostentraeger$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResultOfListOfGuid>): ResultOfListOfGuid => r.body)
    );
  }

}
