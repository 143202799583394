/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PagedListOfTaetigkeitFilter } from '../models/paged-list-of-taetigkeit-filter';
import { ResultOfListOfGuid } from '../models/result-of-list-of-guid';
import { taetigkeitCreateTaetigkeit } from '../fn/taetigkeit/taetigkeit-create-taetigkeit';
import { TaetigkeitCreateTaetigkeit$Params } from '../fn/taetigkeit/taetigkeit-create-taetigkeit';
import { taetigkeitDeleteTaetigkeit } from '../fn/taetigkeit/taetigkeit-delete-taetigkeit';
import { TaetigkeitDeleteTaetigkeit$Params } from '../fn/taetigkeit/taetigkeit-delete-taetigkeit';
import { TaetigkeitDto } from '../models/taetigkeit-dto';
import { taetigkeitExportFilteredTaetigkeit } from '../fn/taetigkeit/taetigkeit-export-filtered-taetigkeit';
import { TaetigkeitExportFilteredTaetigkeit$Params } from '../fn/taetigkeit/taetigkeit-export-filtered-taetigkeit';
import { taetigkeitExportTaetigkeit } from '../fn/taetigkeit/taetigkeit-export-taetigkeit';
import { TaetigkeitExportTaetigkeit$Params } from '../fn/taetigkeit/taetigkeit-export-taetigkeit';
import { taetigkeitFilterTaetigkeit } from '../fn/taetigkeit/taetigkeit-filter-taetigkeit';
import { TaetigkeitFilterTaetigkeit$Params } from '../fn/taetigkeit/taetigkeit-filter-taetigkeit';
import { taetigkeitGetTaetigkeitById } from '../fn/taetigkeit/taetigkeit-get-taetigkeit-by-id';
import { TaetigkeitGetTaetigkeitById$Params } from '../fn/taetigkeit/taetigkeit-get-taetigkeit-by-id';
import { taetigkeitUpdateTaetigkeit } from '../fn/taetigkeit/taetigkeit-update-taetigkeit';
import { TaetigkeitUpdateTaetigkeit$Params } from '../fn/taetigkeit/taetigkeit-update-taetigkeit';

@Injectable({ providedIn: 'root' })
export class TaetigkeitService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `taetigkeitGetTaetigkeitById()` */
  static readonly TaetigkeitGetTaetigkeitByIdPath = '/api/Taetigkeit/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taetigkeitGetTaetigkeitById()` instead.
   *
   * This method doesn't expect any request body.
   */
  taetigkeitGetTaetigkeitById$Response(params: TaetigkeitGetTaetigkeitById$Params, context?: HttpContext): Observable<StrictHttpResponse<TaetigkeitDto>> {
    return taetigkeitGetTaetigkeitById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taetigkeitGetTaetigkeitById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taetigkeitGetTaetigkeitById(params: TaetigkeitGetTaetigkeitById$Params, context?: HttpContext): Observable<TaetigkeitDto> {
    return this.taetigkeitGetTaetigkeitById$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaetigkeitDto>): TaetigkeitDto => r.body)
    );
  }

  /** Path part for operation `taetigkeitUpdateTaetigkeit()` */
  static readonly TaetigkeitUpdateTaetigkeitPath = '/api/Taetigkeit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taetigkeitUpdateTaetigkeit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitUpdateTaetigkeit$Response(params: TaetigkeitUpdateTaetigkeit$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return taetigkeitUpdateTaetigkeit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taetigkeitUpdateTaetigkeit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitUpdateTaetigkeit(params: TaetigkeitUpdateTaetigkeit$Params, context?: HttpContext): Observable<string> {
    return this.taetigkeitUpdateTaetigkeit$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `taetigkeitCreateTaetigkeit()` */
  static readonly TaetigkeitCreateTaetigkeitPath = '/api/Taetigkeit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taetigkeitCreateTaetigkeit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitCreateTaetigkeit$Response(params: TaetigkeitCreateTaetigkeit$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return taetigkeitCreateTaetigkeit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taetigkeitCreateTaetigkeit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitCreateTaetigkeit(params: TaetigkeitCreateTaetigkeit$Params, context?: HttpContext): Observable<string> {
    return this.taetigkeitCreateTaetigkeit$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `taetigkeitDeleteTaetigkeit()` */
  static readonly TaetigkeitDeleteTaetigkeitPath = '/api/Taetigkeit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taetigkeitDeleteTaetigkeit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitDeleteTaetigkeit$Response(params: TaetigkeitDeleteTaetigkeit$Params, context?: HttpContext): Observable<StrictHttpResponse<ResultOfListOfGuid>> {
    return taetigkeitDeleteTaetigkeit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taetigkeitDeleteTaetigkeit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitDeleteTaetigkeit(params: TaetigkeitDeleteTaetigkeit$Params, context?: HttpContext): Observable<ResultOfListOfGuid> {
    return this.taetigkeitDeleteTaetigkeit$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResultOfListOfGuid>): ResultOfListOfGuid => r.body)
    );
  }

  /** Path part for operation `taetigkeitExportTaetigkeit()` */
  static readonly TaetigkeitExportTaetigkeitPath = '/api/Taetigkeit/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taetigkeitExportTaetigkeit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitExportTaetigkeit$Response(params: TaetigkeitExportTaetigkeit$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return taetigkeitExportTaetigkeit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taetigkeitExportTaetigkeit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitExportTaetigkeit(params: TaetigkeitExportTaetigkeit$Params, context?: HttpContext): Observable<Blob> {
    return this.taetigkeitExportTaetigkeit$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `taetigkeitFilterTaetigkeit()` */
  static readonly TaetigkeitFilterTaetigkeitPath = '/api/Taetigkeit/filter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taetigkeitFilterTaetigkeit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitFilterTaetigkeit$Response(params: TaetigkeitFilterTaetigkeit$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedListOfTaetigkeitFilter>> {
    return taetigkeitFilterTaetigkeit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taetigkeitFilterTaetigkeit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitFilterTaetigkeit(params: TaetigkeitFilterTaetigkeit$Params, context?: HttpContext): Observable<PagedListOfTaetigkeitFilter> {
    return this.taetigkeitFilterTaetigkeit$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagedListOfTaetigkeitFilter>): PagedListOfTaetigkeitFilter => r.body)
    );
  }

  /** Path part for operation `taetigkeitExportFilteredTaetigkeit()` */
  static readonly TaetigkeitExportFilteredTaetigkeitPath = '/api/Taetigkeit/filter/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taetigkeitExportFilteredTaetigkeit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitExportFilteredTaetigkeit$Response(params: TaetigkeitExportFilteredTaetigkeit$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return taetigkeitExportFilteredTaetigkeit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taetigkeitExportFilteredTaetigkeit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taetigkeitExportFilteredTaetigkeit(params: TaetigkeitExportFilteredTaetigkeit$Params, context?: HttpContext): Observable<Blob> {
    return this.taetigkeitExportFilteredTaetigkeit$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
