/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FilterEmployeeCommand } from '../../models/filter-employee-command';
import { PagedListOfEmployeeFilter } from '../../models/paged-list-of-employee-filter';

export interface EmployeeFilterEmployee$Params {
      body: FilterEmployeeCommand
}

export function employeeFilterEmployee(http: HttpClient, rootUrl: string, params: EmployeeFilterEmployee$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedListOfEmployeeFilter>> {
  const rb = new RequestBuilder(rootUrl, employeeFilterEmployee.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PagedListOfEmployeeFilter>;
    })
  );
}

employeeFilterEmployee.PATH = '/api/Employee/filter';
