/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { mitarbeiterCreateMitarbeiter } from '../fn/mitarbeiter/mitarbeiter-create-mitarbeiter';
import { MitarbeiterCreateMitarbeiter$Params } from '../fn/mitarbeiter/mitarbeiter-create-mitarbeiter';
import { MitarbeiterDto } from '../models/mitarbeiter-dto';
import { mitarbeiterExportFilteredMitarbeiter } from '../fn/mitarbeiter/mitarbeiter-export-filtered-mitarbeiter';
import { MitarbeiterExportFilteredMitarbeiter$Params } from '../fn/mitarbeiter/mitarbeiter-export-filtered-mitarbeiter';
import { mitarbeiterExportMitarbeiter } from '../fn/mitarbeiter/mitarbeiter-export-mitarbeiter';
import { MitarbeiterExportMitarbeiter$Params } from '../fn/mitarbeiter/mitarbeiter-export-mitarbeiter';
import { mitarbeiterFilterMitarbeiter } from '../fn/mitarbeiter/mitarbeiter-filter-mitarbeiter';
import { MitarbeiterFilterMitarbeiter$Params } from '../fn/mitarbeiter/mitarbeiter-filter-mitarbeiter';
import { mitarbeiterGetAllMitarbeiter } from '../fn/mitarbeiter/mitarbeiter-get-all-mitarbeiter';
import { MitarbeiterGetAllMitarbeiter$Params } from '../fn/mitarbeiter/mitarbeiter-get-all-mitarbeiter';
import { mitarbeiterGetMitarbeiterById } from '../fn/mitarbeiter/mitarbeiter-get-mitarbeiter-by-id';
import { MitarbeiterGetMitarbeiterById$Params } from '../fn/mitarbeiter/mitarbeiter-get-mitarbeiter-by-id';
import { MitarbeiterMinimalDto } from '../models/mitarbeiter-minimal-dto';
import { mitarbeiterUpdateMitarbeiter } from '../fn/mitarbeiter/mitarbeiter-update-mitarbeiter';
import { MitarbeiterUpdateMitarbeiter$Params } from '../fn/mitarbeiter/mitarbeiter-update-mitarbeiter';
import { PagedListOfMitarbeiterFilter } from '../models/paged-list-of-mitarbeiter-filter';

@Injectable({ providedIn: 'root' })
export class MitarbeiterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mitarbeiterGetMitarbeiterById()` */
  static readonly MitarbeiterGetMitarbeiterByIdPath = '/api/Mitarbeiter/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mitarbeiterGetMitarbeiterById()` instead.
   *
   * This method doesn't expect any request body.
   */
  mitarbeiterGetMitarbeiterById$Response(params: MitarbeiterGetMitarbeiterById$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return mitarbeiterGetMitarbeiterById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mitarbeiterGetMitarbeiterById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mitarbeiterGetMitarbeiterById(params: MitarbeiterGetMitarbeiterById$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.mitarbeiterGetMitarbeiterById$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /** Path part for operation `mitarbeiterGetAllMitarbeiter()` */
  static readonly MitarbeiterGetAllMitarbeiterPath = '/api/Mitarbeiter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mitarbeiterGetAllMitarbeiter()` instead.
   *
   * This method doesn't expect any request body.
   */
  mitarbeiterGetAllMitarbeiter$Response(params?: MitarbeiterGetAllMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MitarbeiterMinimalDto>>> {
    return mitarbeiterGetAllMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mitarbeiterGetAllMitarbeiter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mitarbeiterGetAllMitarbeiter(params?: MitarbeiterGetAllMitarbeiter$Params, context?: HttpContext): Observable<Array<MitarbeiterMinimalDto>> {
    return this.mitarbeiterGetAllMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MitarbeiterMinimalDto>>): Array<MitarbeiterMinimalDto> => r.body)
    );
  }

  /** Path part for operation `mitarbeiterUpdateMitarbeiter()` */
  static readonly MitarbeiterUpdateMitarbeiterPath = '/api/Mitarbeiter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mitarbeiterUpdateMitarbeiter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mitarbeiterUpdateMitarbeiter$Response(params: MitarbeiterUpdateMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return mitarbeiterUpdateMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mitarbeiterUpdateMitarbeiter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mitarbeiterUpdateMitarbeiter(params: MitarbeiterUpdateMitarbeiter$Params, context?: HttpContext): Observable<string> {
    return this.mitarbeiterUpdateMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `mitarbeiterCreateMitarbeiter()` */
  static readonly MitarbeiterCreateMitarbeiterPath = '/api/Mitarbeiter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mitarbeiterCreateMitarbeiter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mitarbeiterCreateMitarbeiter$Response(params: MitarbeiterCreateMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return mitarbeiterCreateMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mitarbeiterCreateMitarbeiter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mitarbeiterCreateMitarbeiter(params: MitarbeiterCreateMitarbeiter$Params, context?: HttpContext): Observable<string> {
    return this.mitarbeiterCreateMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `mitarbeiterExportMitarbeiter()` */
  static readonly MitarbeiterExportMitarbeiterPath = '/api/Mitarbeiter/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mitarbeiterExportMitarbeiter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mitarbeiterExportMitarbeiter$Response(params: MitarbeiterExportMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return mitarbeiterExportMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mitarbeiterExportMitarbeiter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mitarbeiterExportMitarbeiter(params: MitarbeiterExportMitarbeiter$Params, context?: HttpContext): Observable<Blob> {
    return this.mitarbeiterExportMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `mitarbeiterFilterMitarbeiter()` */
  static readonly MitarbeiterFilterMitarbeiterPath = '/api/Mitarbeiter/filter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mitarbeiterFilterMitarbeiter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mitarbeiterFilterMitarbeiter$Response(params: MitarbeiterFilterMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedListOfMitarbeiterFilter>> {
    return mitarbeiterFilterMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mitarbeiterFilterMitarbeiter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mitarbeiterFilterMitarbeiter(params: MitarbeiterFilterMitarbeiter$Params, context?: HttpContext): Observable<PagedListOfMitarbeiterFilter> {
    return this.mitarbeiterFilterMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagedListOfMitarbeiterFilter>): PagedListOfMitarbeiterFilter => r.body)
    );
  }

  /** Path part for operation `mitarbeiterExportFilteredMitarbeiter()` */
  static readonly MitarbeiterExportFilteredMitarbeiterPath = '/api/Mitarbeiter/filter/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mitarbeiterExportFilteredMitarbeiter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mitarbeiterExportFilteredMitarbeiter$Response(params: MitarbeiterExportFilteredMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return mitarbeiterExportFilteredMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mitarbeiterExportFilteredMitarbeiter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mitarbeiterExportFilteredMitarbeiter(params: MitarbeiterExportFilteredMitarbeiter$Params, context?: HttpContext): Observable<Blob> {
    return this.mitarbeiterExportFilteredMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
