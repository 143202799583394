import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { kostentraegerDeActivateKostentraeger } from 'src/app/global/apis/backend/fn/kostentraeger/kostentraeger-de-activate-kostentraeger';
import { kostentraegerExportFilteredKostentraeger } from 'src/app/global/apis/backend/fn/kostentraeger/kostentraeger-export-filtered-kostentraeger';
import { kostentraegerExportKostentraeger } from 'src/app/global/apis/backend/fn/kostentraeger/kostentraeger-export-kostentraeger';
import { kostentraegerFilterKostentraeger } from 'src/app/global/apis/backend/fn/kostentraeger/kostentraeger-filter-kostentraeger';
import { KostentraegerService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import {
    FunctionInjectionMap,
    GLOBAL_SEARCH_COLLUMS,
    LazyTableService,
    SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';

@Component({
    selector: 'app-kostentraeger',
    templateUrl: './kostentraeger.component.html',
    styleUrls: [],
    standalone: true,
    providers: [
        DialogService,
        {
            provide: GLOBAL_SEARCH_COLLUMS,
            useValue: [
                'name',
                'beschreibung'
            ],
        },
        {
            provide: SERVICE_FUNCTIONS,
            useValue: {
                getLazyData: kostentraegerFilterKostentraeger,
                exportAll: kostentraegerExportFilteredKostentraeger,
                exportSelected: kostentraegerExportKostentraeger,
                deactivateSelected: kostentraegerDeActivateKostentraeger,
                archiveSelected: null,
            } as FunctionInjectionMap,
        },
        LazyTableService<KostentraegerService>,
    ],
    imports: [
        CommonModule,
        ToastModule,
        TableModule,
        ProgressSpinnerModule,
        LazyTableComponent,
    ],
})
export class KostentraegerComponent {
    diaRef!: DynamicDialogRef;
    public dataService: LazyTableService<KostentraegerService> = inject(
        LazyTableService<KostentraegerService>
    );

    public cols = [
        {
            field: 'aktiv',
            header: 'Status',
            type: 'text',
            tag: true,
            transform: (value: any) => (value ? 'aktiv' : 'inaktiv'),
            filter: [{ action: 'equals', value: 'aktiv' }],
            selectionFilter: [
                { value: true, name: 'aktiv' },
                { value: false, name: 'inaktiv' },
            ],
        },
        { field: 'name', header: 'Name', type: 'text' },
        { field: 'beschreibung', header: 'Beschreibung', type: 'text' },
        { field: 'stundensatz', header: 'Stundensatz', type: 'numeric' },
        {
            field: 'intern',
            header: 'Typ',
            type: 'text',
            tag: true,
            transform: (value: any) => (value ? 'intern' : 'extern'),
            filter: [{ action: 'equals', value: 'intern' }],
            selectionFilter: [
                { value: true, name: 'intern' },
                { value: false, name: 'extern' },
            ],
        },

    ];

    constructor() { }
}
