import { InjectionToken, Signal, inject, signal } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable, of, tap } from 'rxjs';
import { ApiFnRequired, ApiService } from 'src/app/global/apis/backend/api.service';
import { TableFilterModel } from 'src/app/global/apis/backend/models';
import { LazyDataService } from '../models/alptable.models';

export const GLOBAL_SEARCH_COLLUMS = new InjectionToken<string[]>('columns');
export const SERVICE_FUNCTIONS = new InjectionToken<FunctionInjectionMap>(
  'functions'
);

export type FunctionInjectionMap = {
  getLazyData: Function | null;
  exportAll: Function | null;
  exportSelected: Function | null;
  deactivateSelected: Function | null;
  archiveSelected: Function | null;
};

export class LazyTableService<T extends Object> implements LazyDataService<T> {
  // private attributes
  private _dataService: ApiService = inject(ApiService);
  private _$state = signal<T[]>([]);
  private _$totalEntries = signal<number>(0);
  private _$loaded = signal<boolean>(false);
  private _columns: string[] = inject(GLOBAL_SEARCH_COLLUMS);
  private _functions: FunctionInjectionMap = inject(SERVICE_FUNCTIONS);
  onInit() { }

  public getLazyData(filter: TableFilterModel): Observable<any> {
    filter.globalFilter = this._columns;
    return this._dataService
      .invoke(this._functions.getLazyData as ApiFnRequired<any, T>, {
        body: { "tableFilterPayload": filter },
      })
      .pipe(
        tap((x: any) => {
          this._$state.update((_) => x.data || []);
          this._$totalEntries.update((_) => x.totalCount || 0);
          this._$loaded.update((_) => false);
        })
      );
  }

  public getDataSignal(): Signal<T[]> {
    return this._$state;
  }

  public getIsLoadedSignal(): Signal<boolean> {
    return this._$loaded;
  }

  public getEntriesSignal(): Signal<number> {
    return this._$totalEntries;
  }

  public exportAllEntries(filter: TableFilterModel): void {
    this._dataService
      .invoke(this._functions.exportAll as ApiFnRequired<any, T>, {
        body: { "tableFilterPayload": filter },
      })
      .subscribe((response: any) => {
        saveAs(response, 'export_all.xlsx');
      });
  }

  public exportSelectedEntries(selectedData: T[]): void {
    const body = {
      data: selectedData,
    };
    this._dataService
      .invoke(this._functions.exportSelected as ApiFnRequired<any, T>, {
        body: body,
      })
      .subscribe((response: any) => {
        saveAs(response, 'export_selected.xlsx');
      });
  }
  public deactivateSelectedEntries(
    selectedData: string[],
    filter: TableFilterModel
  ): Observable<any> {
    this._$loaded.update((_) => true);
    if (!this._functions.deactivateSelected) {
      // not needed for this
      console.error('Deactivate Selected not Implemented');
      this._$loaded.update((_) => false);
      return of();
    }

    const deActivateCommand = {
      aktive: false,
      ids: selectedData,
    };

    return this._dataService.invoke(
      this._functions.deactivateSelected as ApiFnRequired<any, T>,
      {
        body: deActivateCommand,
      }
    );
  }

  public archiveSelectedEntries(
    selectedData: string[],
    filter: TableFilterModel
  ): Observable<any> {
    this._$loaded.update((_) => true);
    if (!this._functions.archiveSelected) {
      // not needed for this
      console.error('Archive Selected not Implemented');
      this._$loaded.update((_) => false);
      return of();
    }

    const archiveCommand = {
      aktive: false,
      ids: selectedData,
    };
    return this._dataService.invoke(
      this._functions.archiveSelected as ApiFnRequired<any, T>,
      {
        body: archiveCommand,
      }
    );
  }

  public isArchiveAvailable(): boolean {
    if (!this._functions.archiveSelected) {
      return false;
    }

    return true;
  }

  public isDeactivateAvailable(): boolean {
    if (!this._functions.deactivateSelected) {
      return false;
    }

    return true;
  }
}
