import { MitarbeiterStatus } from '../apis/backend/models';

export function transformMitarbeiterStatus(value: number): string {
    const enumKey =
        Object.keys(MitarbeiterStatus).find(
            (key: string) => (MitarbeiterStatus as any)[key] === value
        ) || 'error';
    return enumKey;
}

export function getMitarbeiterStatusSelection(): Array<{
    value: string;
    key: number;
}> {
    return Object.keys(MitarbeiterStatus)
        .filter(StringIsNumber)
        .map((key: any) => {
            return { value: MitarbeiterStatus[key], key: key };
        });
}

export function getMitarbeiterStatusKeyValue(item: MitarbeiterStatus): { value: string; key: number; } {
    return {
        value: MitarbeiterStatus[item],
        key: item,
    };
}

const StringIsNumber = (value: any) => isNaN(Number(value)) === false;