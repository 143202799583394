import { Component, OnInit } from '@angular/core';
import { TranslocoService, TranslocoDirective } from '@jsverse/transloco';
import { SelectChangeEvent, SelectModule } from 'primeng/select';
import { FormsModule } from '@angular/forms';
import { LangCodeToFlagImgPipe } from '../shared/pipes/langCodeToFlagImg.pipe'
import { EmployeeService } from '../global/apis/backend/services';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { UpdateEmployeeLanguageCommand } from '../global/apis/backend/models';

@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [
    FormsModule,
    SelectModule,
    TranslocoDirective,
    LangCodeToFlagImgPipe
  ],
  templateUrl: './language-switcher.component.html'
})

export class LanguageSwitcherComponent implements OnInit{
  currentLang!: string;  // Stores the current language
  languages: string[];  // Stores available languages
  private profile: AccountInfo; // Info about current account containing current UUID

  constructor(private profileService: MsalService, private translocoService: TranslocoService, private employeeService: EmployeeService) {
    this.profile = this.profileService.instance.getActiveAccount()!;
    // Get the current language and available languages
    const availableLangs = this.translocoService.getAvailableLangs();
    // Check if languages are provided as strings or objects, and extract accordingly
    if (Array.isArray(availableLangs) && typeof availableLangs[0] === 'string') {
      this.languages = availableLangs as string[];
    } else {
      this.languages = (availableLangs as { id: string; label: string }[]).map(lang => lang.id);
    }
  }

  ngOnInit(): void {
    this.employeeService.employeeGetEmployeeLanguageById()
    .subscribe((lang) =>
      {
        this.currentLang = lang;
        this.translocoService.setActiveLang(this.currentLang);
      });
  }

  async onChange(event: any): Promise<void> {
    const langCode = (event as SelectChangeEvent).value;
    const updateLangCmd: UpdateEmployeeLanguageCommand = {language: this.currentLang}
    this.employeeService.employeeUpdateEmployeeLanguage({body: updateLangCmd }).subscribe();

    this.translocoService.setActiveLang(langCode);
    this.currentLang = langCode;
  }
}
