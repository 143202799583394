import { CommonModule } from '@angular/common';
import {
    Component,
    Injector,
    Input,
    OnInit,
    computed,
    inject
} from '@angular/core';
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { CreateMitarbeiterCommand, Familienstand, Geschlecht, MitarbeiterDto, MitarbeiterStatus, UpdateMitarbeiterCommand } from 'src/app/global/apis/backend/models';
import { MitarbeiterService } from 'src/app/global/apis/backend/services';
import { toISODateString } from 'src/app/global/helper/isoDateFormater';
import { getMitarbeiterStatusKeyValue, getMitarbeiterStatusSelection } from 'src/app/global/helper/mitarbeiterStatusTransform';
import { FormDatePickerComponent } from 'src/app/shared/ui/form-date-picker/form-date-picker.component';

import { FormDropdownComponent } from 'src/app/shared/ui/form-dropdown/form-dropdown.component';
import { FormInputComponent } from 'src/app/shared/ui/form-input/form-input.component';
import { FormTimePickerComponent } from 'src/app/shared/ui/form-time-picker/form-time-picker.component';

@Component({
    selector: 'app-mitarbeiter.detail',
    templateUrl: './mitarbeiter.detail.component.html',
    styleUrls: [],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        FloatLabelModule,
        InputTextModule,
        DropdownModule,
        InputNumberModule,
        MultiSelectModule,
        InputSwitchModule,
        FormInputComponent,
        FormDropdownComponent,
        FormTimePickerComponent,
        FormDatePickerComponent,
        TableModule
    ],
})
export default class MitarbeiterDetailComponent implements OnInit {
    @Input({ required: true }) readonly id?: string; // -> from route param

    public _isEdit = false;
    private readonly _mitarbeiterService = inject(MitarbeiterService);
    private readonly _fb = inject(FormBuilder);
    private readonly _componentInjector = inject(Injector);
    private readonly _messageService = inject(MessageService);
    private readonly _router = inject(Router);
    private _entry: MitarbeiterDto | null = null;

    mitarbeiterNummerSignal = computed(() => {
        if (this._isEdit) {
            return this._entry?.id;
        }
        return -1;
    });

    private redirectPath = ['mitarbeiter'];
    private route: ActivatedRoute = inject(ActivatedRoute);

    public mitarbeiterStatusAuswahl = getMitarbeiterStatusSelection();

    public isLoading = true;
    public formMitarbeiter = this._fb.group({
        id: [''],
        personalnummer: ['', [Validators.required]],
        vorname: ['', [Validators.required]],
        nachname: ['', [Validators.required]],
        email: ['', [Validators.required]],
        telefon: ['', [Validators.required]],
        geburtsdatum: [new Date(), [Validators.required]],
        eintrittsdatum: [new Date(), [Validators.required]],
        unternehmen: ['', [Validators.required]],
        status: [getMitarbeiterStatusKeyValue(MitarbeiterStatus.Aktiv), [Validators.required]]
    });

    constructor() {
        const state = this._router.getCurrentNavigation()?.extras.state;

        if (state) {
            this.redirectPath = state['redirectPath'] ?? ['mitarbeiter'];
        }
    }

    ngOnInit(): void {
        if (this.id) {
            this._isEdit = true;
            this._mitarbeiterService.mitarbeiterGetMitarbeiterById({ id: this.id }).subscribe((x) => {
                this._entry = x;
                this.setFormFromEntry(x);
                this.isLoading = false;
            });
        }

        this.isLoading = false;
    }

    onSubmit() {
        if (this._isEdit) this.update();
        else this.create();
    }

    update() {
        const neueMitarbeiter: UpdateMitarbeiterCommand = {
            id: this.formMitarbeiter.get('id')?.value ?? '',
            personalnummer: this.formMitarbeiter.get('personalnummer')?.value ?? '',
            vorname: this.formMitarbeiter.get('vorname')?.value ?? '',
            nachname: this.formMitarbeiter.get('nachname')?.value ?? '',
            email: this.formMitarbeiter.get('email')?.value ?? '',
            telefon: this.formMitarbeiter.get('telefon')?.value ?? '',
            geburtsdatum: toISODateString(this.formMitarbeiter.get('geburtsdatum')?.value ?? new Date()),
            eintrittsdatum: toISODateString(this.formMitarbeiter.get('eintrittsdatum')?.value ?? new Date()),
            unternehmen: this.formMitarbeiter.get('unternehmen')?.value ?? '',
            zusatzEmails: [],
            status: +(this.formMitarbeiter.get('status')?.value?.key ?? MitarbeiterStatus.Aktiv),
            land: 'Österreich',
            plz: '1234',
            ort: 'Wien',
            strasse: 'Musterstraße 123',
            iban: 'AT123456789012345678',
            bic: 'BIC12345',
            bankname: 'Bank',
            svnr: '1234567890',
            jobtitel: 'Jobtitel',
            gehalt: 0,
            gewerkschaftsbeitrag: 0,
            alleinverdienerKinder: 0,
            austrittsdatum: toISODateString(new Date()),
            familienstand: Familienstand.Ledig,
            geschlecht: Geschlecht.Maennlich,
            homeOfficeTage: 0,
            pendlerKilometer: 0,
            wochenarbeitszeit: 0
        };
        this._mitarbeiterService.mitarbeiterUpdateMitarbeiter({ body: neueMitarbeiter })
            .subscribe((x) => {
                this._messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail:
                        'Mitarbeiter ' +
                        neueMitarbeiter.id +
                        ' erfolgreich bearbeitet!',
                });
                this.back();
            });
    }

    create() {
        const neueMitarbeiter: CreateMitarbeiterCommand = {
            id: this.formMitarbeiter.get('id')?.value ?? '',
            personalnummer: this.formMitarbeiter.get('personalnummer')?.value ?? '',
            vorname: this.formMitarbeiter.get('vorname')?.value ?? '',
            nachname: this.formMitarbeiter.get('nachname')?.value ?? '',
            email: this.formMitarbeiter.get('email')?.value ?? '',
            telefon: this.formMitarbeiter.get('telefon')?.value ?? '',
            geburtsdatum: toISODateString(this.formMitarbeiter.get('geburtsdatum')?.value ?? new Date()),
            eintrittsdatum: toISODateString(this.formMitarbeiter.get('eintrittsdatum')?.value ?? new Date()),
            unternehmen: this.formMitarbeiter.get('unternehmen')?.value ?? '',
            zusatzEmails: [],
            status: +(this.formMitarbeiter.get('status')?.value?.key ?? MitarbeiterStatus.Aktiv),
            land: 'Österreich',
            plz: '1234',
            ort: 'Wien',
            strasse: 'Musterstraße 123',
            iban: 'AT123456789012345678',
            bic: 'BIC12345',
            bankname: 'Bank',
            svnr: '1234567890',
            jobtitel: 'Jobtitel',
            gehalt: 0,
            gewerkschaftsbeitrag: 0,
            alleinverdienerKinder: 0,
            austrittsdatum: toISODateString(new Date()),
            familienstand: Familienstand.Ledig,
            geschlecht: Geschlecht.Maennlich,
            homeOfficeTage: 0,
            pendlerKilometer: 0,
            wochenarbeitszeit: 0
        };
        this._mitarbeiterService.mitarbeiterCreateMitarbeiter({ body: neueMitarbeiter })
            .subscribe((response) => {
                this._messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail:
                        'Mitarbeiter erfolgreich angelegt!',
                });
                this.back();
            });
    }

    private setFormFromEntry(dto: MitarbeiterDto) {
        try {
            this.formMitarbeiter.setValue(
                {
                    id: dto.id,
                    personalnummer: dto.personalnummer,
                    vorname: dto.vorname,
                    nachname: dto.nachname,
                    email: dto.email,
                    telefon: dto.telefon,
                    geburtsdatum: new Date(dto.geburtsdatum),
                    eintrittsdatum: new Date(dto.eintrittsdatum),
                    unternehmen: dto.unternehmen,
                    status: getMitarbeiterStatusKeyValue(dto.status)
                },
                { emitEvent: false }
            );
        } catch (e) {
            console.error('Error setting form values: ', e);
        }
    }

    public resetForm() {
        if (this._isEdit) {

            this.setFormFromEntry(this._entry!);
            return;
        }
        this.formMitarbeiter.setValue({
            id: '',
            personalnummer: '',
            vorname: '',
            nachname: '',
            email: '',
            telefon: '',
            geburtsdatum: new Date(),
            eintrittsdatum: new Date(),
            unternehmen: '',
            status: getMitarbeiterStatusKeyValue(MitarbeiterStatus.Aktiv)
        });
    }

    public back() {
        structuredClone(this.redirectPath) !==
            structuredClone(['mitarbeiter'])
            ? this._router.navigate(this.redirectPath, {
                state: { rememberValues: true },
            })
            : this._router.navigate(this.redirectPath);
    }
}
