import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Provider,
  forwardRef,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { Calendar, CalendarModule } from 'primeng/calendar';

const DATEPICKER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormDatePickerComponent),
  multi: true,
};

@Component({
  selector: 'app-form-date-picker',
  standalone: true,
  imports: [CalendarModule, ReactiveFormsModule, FormsModule, NgClass],
  templateUrl: './form-date-picker.component.html',
  styleUrl: './form-date-picker.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [DATEPICKER_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDatePickerComponent {
  control = signal<FormControl>(new FormControl());

  inputId = input.required<string>();
  labelName = input.required<string>();

  value = signal<any>('');
  disabled = signal<boolean>(false);

  private onTouched: Function = () => {};
  private onChanged: Function = () => {};

  injector = inject(Injector);
  private _cdr = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    const ngControl = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control.set(ngControl.control as FormControl);
    }
  }

  selectDate(event: any) {
    this.setValue(event, true);
  }

  protected setValue(obj: any, emitEvent: boolean) {
    this.value.set(obj);
    if (emitEvent && this.onChanged) {
      this.onChanged(obj);
      this.onTouched();
    }
  }

  writeValue(obj: any): void {
    this.setValue(obj, false);
    this._cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }
}
