import { inject } from '@angular/core';
import { EmployeeService } from 'src/app/global/apis/backend/services'

export class EmployeeFilter{
    private readonly _employeeService = inject(EmployeeService);

    EmployeeFilter(): {value: string; name: string}[] {
        const values: {value: string; name: string}[] = []
        this._employeeService.employeeGetAllEmployee().forEach(element => {element.forEach(employee => {
          const fullName: string = employee.lastName + ' ' + employee.firstName;
          if (fullName != undefined) {
            values.push({value: fullName, name: fullName})
          }  
        })
        });

        return values;
    }
}