import { Component, inject, OnInit } from '@angular/core';
import { TimeBookingService } from 'src/app/global/apis/backend/services';
import { FilterTimeBookingCommand, TimeBookingFilter } from 'src/app/global/apis/backend/models';
import { Router } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  standalone: true,
  selector: 'app-bookings-warning',
  imports: [TranslocoDirective],
  templateUrl: './bookings-warning.component.html',
})
export class BookingsWarningComponent implements OnInit {
  constructor(private router: Router){this._warning = false;}
  private readonly _timeBookingService = inject(TimeBookingService);
  private _timeBookings: TimeBookingFilter[] = [];
  _warning: boolean;

  ngOnInit(): void {
    this.loadTimeBookings();
  }

  // warns the user, if there are no timeBookings within the last 5 days
  // incl. weekends
  calculateWarning(): void {
    const lb_date = new Date();
    const rb_date = new Date();
    lb_date.setDate(lb_date.getDate() - 6);
    rb_date.setDate(rb_date.getDate() - 1);
    let result = 0;
    this._timeBookings.forEach((booking) => {
      const temp = new Date(booking.date!);
      temp < rb_date ? (temp > lb_date ? result += 1 : result += 0) : result += 0;
    });

    result > 0 ? this._warning = false : this._warning = true;
  }

  loadTimeBookings(): void {
    const params: FilterTimeBookingCommand = {
      tableFilterPayload: {
        first: 0,
        rows: 3,
        sortField: 'date',
        sortOrder: -1
      }
    };

    this._timeBookingService.timeBookingFilterTimeBooking({body: params })
      .subscribe((response) => {
        this._timeBookings = response.data!;
        this.calculateWarning();
      });
  }
}
