<section class="max-w-4xl p-6 sm:my-4 mx-auto bg-white sm:rounded-2xl sm:border sm:border-gray-300">
  <div *transloco="let t">
    <div *transloco="let ft; scope: 'features'; prefix: 'features'">
      <div class="grid grid-cols-3">
        <div><!-- Empty div on purpose --></div>
        <h2 class="text-lg text-center content-center font-semibold text-gray-700">
          {{ t('accounting')}}
        </h2>
        <div class="text-right">
          <i class="pi pi-file-import" style="font-size: 2rem"></i>
        </div>
      </div>
      <form [formGroup]="formAccountingUpload">
        <div class="grid grid-cols-1 gap-2 mt-4 sm:grid-cols-2">
          <div>
            <div class="mb-3">
              <app-form-date-picker formControlName="date" inputId="date"
                labelName="{{ ft('billingdate')}}{{t('required') }}" />
            </div>
            <div class="mb-3">
              <app-form-input inputType="text" labelName="{{ ft('company')}}{{t('required') }}"
                formControlName="company" inputId="company" />
            </div>
            <div class="mb-3">
              <app-form-input inputType="text" labelName="{{ ft('purpose')}}{{t('required') }}"
                formControlName="purpose" inputId="purpose" />
            </div>
          </div>
          <div>
            <app-form-file-upload inputType="file" labelName="{{ ft('file')}}{{t('required') }}" formControlName="file"
              inputId="file" (change)="onFileSelected($event)" />
            <!---->
          </div>
          <div class="flex justify-center mb-8 gap-2">
            <button
              class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-primary rounded-md hover:bg-primary focus:outline-none focus:bg-gray-600 disabled:bg-gray-200"
              [disabled]="(formAccountingUpload.invalid || formAccountingUpload.pristine)" type="submit"
              (click)="onSubmit()">
              {{t('upload')}}
            </button>
            <button
              class="px-8 py-2.5 leading-5 text-primary transition-colors duration-300 transform border border-gray-200 rounded-md hover:bg-secondaryhover active:bg-primary active:transition-none"
              (click)="resetForm()">
              {{t('restore')}}
            </button>
          </div>
        </div>


        @if (invalidFileExtensionMessage) {
        <p class="text-danger mt-3">
          {{ invalidFileExtensionMessage }}
        </p>
        }
      </form>
    </div>
  </div>
</section>