import { inject } from '@angular/core';
import { CostCenterService } from 'src/app/global/apis/backend/services'

export class CostCentreFilter{
    private readonly _costcentreService = inject(CostCenterService);

    CostCentreFilter(): {value: string; name: string}[] {
        const values: {value: string; name: string}[] = []
        this._costcentreService.costCenterGetAllCostCenter().forEach(element => {element.forEach(costCentre => {
          if (costCentre.name != undefined) {
            values.push({value: costCentre.name, name: costCentre.name})
          }  
        })
        });

        return values;
    }
}