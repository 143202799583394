export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: '52d23f22-d0e2-4631-9b6c-015604095d27',
      authority: 'https://login.microsoftonline.com/a5a16e2e-8fce-4604-aa7b-d3f05607917b',
    },
  },
  apiConfig: {
    scopes: ['api://256baacb-9e2f-4ec0-9d55-d0f0ee244aa4/access_as_user'],
    uri: 'https://alpctrl-api.dev.alpscale.io/',
  },
};
