import { CommonModule } from '@angular/common';
import {
  Component,
  Injector,
  Input,
  OnInit,
  Signal,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { addMinutes } from 'date-fns';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ActivityDto, CreateActivityCommand, CreateTimeBookingCommand, TimeBookingDto, UpdateTimeBookingCommand } from 'src/app/global/apis/backend/models';
import { ActivityService, CostCenterService, TimeBookingService } from 'src/app/global/apis/backend/services';
import { toISODateString, toISOTimeStringWithoutSeconds } from 'src/app/global/helper/isoDateFormater';
import { convertToDecimal, convertToTimespan } from 'src/app/global/helper/numToTimespan';
import { roundDateToNearestQuarterHour } from 'src/app/global/helper/roundTime';
import { SuccessNotificationService } from 'src/app/shared/services/success-notification.service';
import { FormDatePickerComponent } from 'src/app/shared/ui/form-date-picker/form-date-picker.component';
import { FormDropdownComponent } from 'src/app/shared/ui/form-dropdown/form-dropdown.component';
import { FormInputComponent } from 'src/app/shared/ui/form-input/form-input.component';


@Component({
  selector: 'app-timebooking.detail',
  templateUrl: './timebooking.detail.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    FloatLabelModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    InputSwitchModule,
    FormInputComponent,
    FormDropdownComponent,
    FormDatePickerComponent,
    TableModule,
    TranslocoDirective
  ],
})
export default class TimeBookingDetailComponent implements OnInit {
  @Input({ required: true }) readonly id?: string; // -> from route param

  public _isEdit = false;
  private readonly _TimeBookingService = inject(TimeBookingService);
  private readonly _taetigkeitService = inject(ActivityService);
  private readonly _fb = inject(FormBuilder);
  private readonly _componentInjector = inject(Injector);
  private readonly _messageService = inject(MessageService);
  private readonly _router = inject(Router);
  private _entry: TimeBookingDto | null = null;
  private readonly _activeCostCenterSignal = toSignal(inject(CostCenterService).costCenterGetActiveCostCenter());
  private readonly _default_worklocation = 'Nordbahnstraße 21, 1020 Wien';
  private _successService = inject(SuccessNotificationService);

  timeBookingNummerSignal = computed(() => {
    if (this._isEdit) {
      return this._entry?.id;
    }
    return -1;
  });

  private redirectPath = ['timeBooking'];
  private readonly initialDateTime = roundDateToNearestQuarterHour(new Date());
  private route: ActivatedRoute = inject(ActivatedRoute);

  public isLoading = true;
  public formTimeBooking = this._fb.group({
    id: [''],
    date: [this.initialDateTime, [Validators.required]],
    start: [toISOTimeStringWithoutSeconds(this.initialDateTime), [this.timeValidator.bind(this)]],
    break: [0.5, [Validators.required, Validators.min(0)]],
    end: [toISOTimeStringWithoutSeconds(addMinutes(this.initialDateTime, 15)),
    [this.timeValidator.bind(this), this.divByZeroCheck.bind(this)]],
    homeOffice: [false, [Validators.required]],
    workLocation: [this._default_worklocation]
  });

  public formActivity = this._fb.group({
    id: [''],
    costcenter: [null, [Validators.required]],
    summary: ['', [Validators.required]],
    hours: [0, [Validators.required, Validators.min(0)]],
    note: ['']
  });

  public costcenterAuswahlSignal = computed(() => this._activeCostCenterSignal() ?? []);
  public activitySignal: WritableSignal<ActivityDto[]> = signal([]);
  public gesamtStundenSignal: Signal<number> = signal(0);
  public startEndebreakSignal: WritableSignal<number> = signal(0);

  constructor() {
    const state = this._router.getCurrentNavigation()?.extras.state;

    if (state) {
      this.redirectPath = state['redirectPath'] ?? ['timeBooking'];
    }
  }

  ngOnInit(): void {
    if (this.id) {
      this._isEdit = true;
      this._TimeBookingService.timeBookingGetTimeBookingById({ id: this.id || '' })
        .subscribe((response) => {
          this._entry = response;

          if (this._entry) {
            this.activitySignal = signal(this._entry?.activities ?? []);
            this.gesamtStundenSignal = computed(() => {
              return this.activitySignal().reduce((acc, x) => acc + convertToDecimal(x.hours), 0);
            });
            this.setFormFromEntry(this._entry);
            this.recalculateAvgHours();
            this.formTimeBooking.markAsPristine();
          }
        });
    }

    this.isLoading = false;
  }

  onSubmit() {
    if (!this.formTimeBooking.valid) return;
    if (this._isEdit) { this.update(); }
    else { this.create(); }
  }

  onSubmitActivity() {
    console.log()
    const newActivity: CreateActivityCommand = {
      costCenterId: (this.formActivity.get('costcenter')?.value as any).id,
      hours: convertToTimespan(this.formActivity.get('hours')?.value ?? 0) ?? '00:00:00',
      summary: this.formActivity.get('summary')?.value ?? '',
      note: this.formActivity.get('note')?.value ?? '',
      timeBookingId: this._entry?.id ?? ''
    };

    this._taetigkeitService.activityCreateActivity({ body: newActivity })
      .subscribe((response) => {
        this._messageService.add({
          severity: 'success',
          summary: 'Success',
          detail:
            'Activity successfully added!',
        });
        this._successService.displaySuccessionMsg('activityAddSuccess');
        const ActivityDto: ActivityDto = {
          id: response,
          costCenterId: newActivity.costCenterId ?? '',
          costCenterName: (this.formActivity.get('costcenter')?.value as any).name,
          hours: newActivity.hours ?? '00:00:00',
          description: newActivity.summary ?? '',
          note: newActivity.note ?? '',
          timeBookingId: newActivity.timeBookingId ?? '',
        };

        this.activitySignal.set([...(this.activitySignal() ?? []), ActivityDto]);
      });
  }

  deleteTaetigkeit(id: string) {
    this._taetigkeitService.activityDeleteActivity({ body: { ids: [id] } })
      .subscribe(() => {
        this._messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail:
            'Activity ' +
            id +
            ' deleted successfully!',
        });
        this._successService.displaySuccessionMsg('timebookingDeleteSuccess');
        this.activitySignal.set((this.activitySignal() ?? []).filter((x) => x.id !== id));
      });
  }

  update() {
    const neueTimeBooking: UpdateTimeBookingCommand = {
      id: this.formTimeBooking.get('id')?.value ?? '',
      date: toISODateString(this.formTimeBooking.get('date')?.value ?? new Date()),
      // add :00 to string
      start: `${this.formTimeBooking.get('start')?.value ?? toISOTimeStringWithoutSeconds(new Date())}:00`,
      break: convertToTimespan(this.formTimeBooking.get('break')?.value ?? 0),
      end: `${this.formTimeBooking.get('end')?.value ?? toISOTimeStringWithoutSeconds(new Date())}:00`,
      employeeId: this._entry?.employeeId ?? '',
      homeOffice: this.formTimeBooking.get('homeOffice')?.value ?? false,
      worklocation: this.formTimeBooking.get('workLocation')?.value ?? this._default_worklocation
    };

    this._TimeBookingService.timeBookingUpdateTimeBooking({ body: neueTimeBooking })
      .subscribe(() => {
        this._messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail:
            'TimeBooking ' +
            neueTimeBooking.id +
            ' edited successfully!',
        });
        this._successService.displaySuccessionMsg('timebookingEditSuccess');
        this.recalculateAvgHours();
        this.formTimeBooking.markAsPristine();
      });
  }

  create() {
    const neueTimeBooking: CreateTimeBookingCommand = {
      date: toISODateString(this.formTimeBooking.get('date')?.value ?? new Date()),
      start: `${this.formTimeBooking.get('start')?.value ?? toISOTimeStringWithoutSeconds(new Date())}:00`,
      break: convertToTimespan(this.formTimeBooking.get('break')?.value ?? 0.5),
      end: `${this.formTimeBooking.get('end')?.value ?? toISOTimeStringWithoutSeconds(new Date())}:15`,
      homeOffice: this.formTimeBooking.get('homeOffice')?.value ?? false,
      worklocation: this.formTimeBooking.get('workLocation')?.value ?? this._default_worklocation
    };

    this._TimeBookingService.timeBookingCreateTimeBooking({ body: neueTimeBooking })
      .subscribe((response) => {
        this._messageService.add({
          severity: 'success',
          summary: 'Success',
          detail:
            'TimeBooking added successfully!',
        });
        this._successService.displaySuccessionMsg('timebookingAddSuccess');
        this._router.navigate([response], { relativeTo: this.route });
      });
  }

  private setFormFromEntry(dto: TimeBookingDto) {
    try {
      this.formTimeBooking.setValue(
        {
          id: dto.id,
          date: new Date(dto.date),
          start: dto.start.slice(0, 5),
          break: convertToDecimal(dto.break || '00:00:00'),
          end: dto.end.slice(0, 5),
          homeOffice: dto.homeOffice,
          workLocation: dto.worklocation
        },
        { emitEvent: false }
      );
    } catch (e) {
      console.error('Error setting form values: ', e);
      throw e;
    }
  }

  public resetForm() {
    if (this._isEdit) {
      this.setFormFromEntry(this._entry!);
      return;
    }
    this.formTimeBooking.setValue({
      id: '',
      date: this.initialDateTime,
      start: toISOTimeStringWithoutSeconds(this.initialDateTime),
      break: 1,
      end: toISOTimeStringWithoutSeconds(addMinutes(this.initialDateTime, 15)),
      homeOffice: false,
      workLocation: this._default_worklocation
    });
    this.formTimeBooking.markAsPristine();
  }

  private recalculateAvgHours() {
    this.startEndebreakSignal.set(this.calcHours(
      convertToDecimal(this.formTimeBooking.get('start')?.value ?? '00:00'),
      convertToDecimal(this.formTimeBooking.get('end')?.value ?? '00:00'),
      this.formTimeBooking.get('break')?.value ?? 0
    ));
  }

  // break was altered to not be a keyword of the angular compiler
  calcHours(start: number, end: number, break1: number): number {
    if (end === 0) { end = 24; }

    return parseFloat(
      (end - start - break1).toFixed(2)
    );
  }

  convertToDecimal(time: string): number {
    return convertToDecimal(time);
  }

  timeValidator(control: AbstractControl): ValidationErrors | null {
    const beginning = this.formTimeBooking?.get('start')?.value ?? '00:00';
    const ending = this.formTimeBooking?.get('end')?.value ?? '00:00';
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

    if (!timeRegex.test(control.value)) {
      return { invalidTime: true, message: 'Ungültige Zeitangabe' };
    }

    if (!beginning || !ending) {
      return { invalidTime: true, message: 'Bitte Start- und ending angeben' };
    }

    if (this.calcHours(
      convertToDecimal(beginning ?? '00:00'),
      convertToDecimal(ending ?? '00:00'),
      this.formTimeBooking?.get('break')?.value ?? 0
    ) < 0) {
      return { invalidTime: true, message: 'ending muss nach beginning liegen' };
    }

    return null;
  }

  private divByZeroCheck(): ValidationErrors | null {
    const beginning = this.formTimeBooking?.get('start')?.value ?? '00:00';
    const ending = this.formTimeBooking?.get('end')?.value ?? '00:01';
    const employeeBreak = this.formTimeBooking?.get('break')?.value ?? 0;
    const start = parseInt(beginning.split(':')[0]) + (parseInt(beginning.split(':')[1]) / 60);
    const end = parseInt(ending.split(':')[0]) + (parseInt(ending.split(':')[1]) / 60);
    return (end - start) === employeeBreak ? { invalidTime: true, message: 'divide by zero' } : null;
  }
}
