/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { costCenterComputeField } from '../fn/cost-center/cost-center-compute-field';
import { CostCenterComputeField$Params } from '../fn/cost-center/cost-center-compute-field';
import { costCenterCreateCostCenter } from '../fn/cost-center/cost-center-create-cost-center';
import { CostCenterCreateCostCenter$Params } from '../fn/cost-center/cost-center-create-cost-center';
import { costCenterDeActivateCostCenter } from '../fn/cost-center/cost-center-de-activate-cost-center';
import { CostCenterDeActivateCostCenter$Params } from '../fn/cost-center/cost-center-de-activate-cost-center';
import { CostCenterDto } from '../models/cost-center-dto';
import { costCenterExportCostCenter } from '../fn/cost-center/cost-center-export-cost-center';
import { CostCenterExportCostCenter$Params } from '../fn/cost-center/cost-center-export-cost-center';
import { costCenterExportFilteredCostCenter } from '../fn/cost-center/cost-center-export-filtered-cost-center';
import { CostCenterExportFilteredCostCenter$Params } from '../fn/cost-center/cost-center-export-filtered-cost-center';
import { CostCenterFilter } from '../models/cost-center-filter';
import { costCenterFilterCostCenter } from '../fn/cost-center/cost-center-filter-cost-center';
import { CostCenterFilterCostCenter$Params } from '../fn/cost-center/cost-center-filter-cost-center';
import { costCenterGetActiveCostCenter } from '../fn/cost-center/cost-center-get-active-cost-center';
import { CostCenterGetActiveCostCenter$Params } from '../fn/cost-center/cost-center-get-active-cost-center';
import { costCenterGetAllCostCenter } from '../fn/cost-center/cost-center-get-all-cost-center';
import { CostCenterGetAllCostCenter$Params } from '../fn/cost-center/cost-center-get-all-cost-center';
import { costCenterGetCostCenterById } from '../fn/cost-center/cost-center-get-cost-center-by-id';
import { CostCenterGetCostCenterById$Params } from '../fn/cost-center/cost-center-get-cost-center-by-id';
import { CostCenterMinimalDto } from '../models/cost-center-minimal-dto';
import { costCenterUpdateCostCenter } from '../fn/cost-center/cost-center-update-cost-center';
import { CostCenterUpdateCostCenter$Params } from '../fn/cost-center/cost-center-update-cost-center';
import { PagedListOfCostCenterFilter } from '../models/paged-list-of-cost-center-filter';
import { ResultOfListOfGuid } from '../models/result-of-list-of-guid';

@Injectable({ providedIn: 'root' })
export class CostCenterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `costCenterGetCostCenterById()` */
  static readonly CostCenterGetCostCenterByIdPath = '/api/CostCenter/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterGetCostCenterById()` instead.
   *
   * This method doesn't expect any request body.
   */
  costCenterGetCostCenterById$Response(params: CostCenterGetCostCenterById$Params, context?: HttpContext): Observable<StrictHttpResponse<CostCenterDto>> {
    return costCenterGetCostCenterById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `costCenterGetCostCenterById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  costCenterGetCostCenterById(params: CostCenterGetCostCenterById$Params, context?: HttpContext): Observable<CostCenterDto> {
    return this.costCenterGetCostCenterById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CostCenterDto>): CostCenterDto => r.body)
    );
  }

  /** Path part for operation `costCenterGetAllCostCenter()` */
  static readonly CostCenterGetAllCostCenterPath = '/api/CostCenter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterGetAllCostCenter()` instead.
   *
   * This method doesn't expect any request body.
   */
  costCenterGetAllCostCenter$Response(params?: CostCenterGetAllCostCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CostCenterMinimalDto>>> {
    return costCenterGetAllCostCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `costCenterGetAllCostCenter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  costCenterGetAllCostCenter(params?: CostCenterGetAllCostCenter$Params, context?: HttpContext): Observable<Array<CostCenterMinimalDto>> {
    return this.costCenterGetAllCostCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CostCenterMinimalDto>>): Array<CostCenterMinimalDto> => r.body)
    );
  }

  /** Path part for operation `costCenterUpdateCostCenter()` */
  static readonly CostCenterUpdateCostCenterPath = '/api/CostCenter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterUpdateCostCenter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterUpdateCostCenter$Response(params: CostCenterUpdateCostCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return costCenterUpdateCostCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `costCenterUpdateCostCenter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterUpdateCostCenter(params: CostCenterUpdateCostCenter$Params, context?: HttpContext): Observable<string> {
    return this.costCenterUpdateCostCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `costCenterCreateCostCenter()` */
  static readonly CostCenterCreateCostCenterPath = '/api/CostCenter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterCreateCostCenter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterCreateCostCenter$Response(params: CostCenterCreateCostCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return costCenterCreateCostCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `costCenterCreateCostCenter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterCreateCostCenter(params: CostCenterCreateCostCenter$Params, context?: HttpContext): Observable<string> {
    return this.costCenterCreateCostCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `costCenterGetActiveCostCenter()` */
  static readonly CostCenterGetActiveCostCenterPath = '/api/CostCenter/GetActiveCostCenter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterGetActiveCostCenter()` instead.
   *
   * This method doesn't expect any request body.
   */
  costCenterGetActiveCostCenter$Response(params?: CostCenterGetActiveCostCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CostCenterFilter>>> {
    return costCenterGetActiveCostCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `costCenterGetActiveCostCenter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  costCenterGetActiveCostCenter(params?: CostCenterGetActiveCostCenter$Params, context?: HttpContext): Observable<Array<CostCenterFilter>> {
    return this.costCenterGetActiveCostCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CostCenterFilter>>): Array<CostCenterFilter> => r.body)
    );
  }

  /** Path part for operation `costCenterExportCostCenter()` */
  static readonly CostCenterExportCostCenterPath = '/api/CostCenter/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterExportCostCenter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterExportCostCenter$Response(params: CostCenterExportCostCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return costCenterExportCostCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `costCenterExportCostCenter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterExportCostCenter(params: CostCenterExportCostCenter$Params, context?: HttpContext): Observable<Blob> {
    return this.costCenterExportCostCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `costCenterFilterCostCenter()` */
  static readonly CostCenterFilterCostCenterPath = '/api/CostCenter/filter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterFilterCostCenter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterFilterCostCenter$Response(params: CostCenterFilterCostCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedListOfCostCenterFilter>> {
    return costCenterFilterCostCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `costCenterFilterCostCenter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterFilterCostCenter(params: CostCenterFilterCostCenter$Params, context?: HttpContext): Observable<PagedListOfCostCenterFilter> {
    return this.costCenterFilterCostCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagedListOfCostCenterFilter>): PagedListOfCostCenterFilter => r.body)
    );
  }

  /** Path part for operation `costCenterExportFilteredCostCenter()` */
  static readonly CostCenterExportFilteredCostCenterPath = '/api/CostCenter/filter/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterExportFilteredCostCenter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterExportFilteredCostCenter$Response(params: CostCenterExportFilteredCostCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return costCenterExportFilteredCostCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `costCenterExportFilteredCostCenter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterExportFilteredCostCenter(params: CostCenterExportFilteredCostCenter$Params, context?: HttpContext): Observable<Blob> {
    return this.costCenterExportFilteredCostCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `costCenterComputeField()` */
  static readonly CostCenterComputeFieldPath = '/api/CostCenter/filter/compute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterComputeField()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterComputeField$Response(params: CostCenterComputeField$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return costCenterComputeField(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `costCenterComputeField$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterComputeField(params: CostCenterComputeField$Params, context?: HttpContext): Observable<number> {
    return this.costCenterComputeField$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `costCenterDeActivateCostCenter()` */
  static readonly CostCenterDeActivateCostCenterPath = '/api/CostCenter/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterDeActivateCostCenter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterDeActivateCostCenter$Response(params: CostCenterDeActivateCostCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<ResultOfListOfGuid>> {
    return costCenterDeActivateCostCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `costCenterDeActivateCostCenter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  costCenterDeActivateCostCenter(params: CostCenterDeActivateCostCenter$Params, context?: HttpContext): Observable<ResultOfListOfGuid> {
    return this.costCenterDeActivateCostCenter$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResultOfListOfGuid>): ResultOfListOfGuid => r.body)
    );
  }

}
