import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class SuccessNotificationService {

  constructor(private snackbar: MatSnackBar, private translocoservice: TranslocoService) { }

  // in order to work with transloco pls call this method with the corresponding translation key.
  displaySuccessionMsg(msg: string) : void {
      this.snackbar.open(this.translocoservice.translate(msg), this.translocoservice.translate('close'), {
        duration: 8000,
        panelClass: 'snackbar-success',
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
  }
}
