import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Provider,
  forwardRef,
  inject,
  input,
  signal
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';

const INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormInputComponent),
  multi: true,
};

@Component({
  selector: 'app-form-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, CalendarModule],
  templateUrl: './form-input.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputComponent implements ControlValueAccessor {
  control = signal<FormControl>(new FormControl());

  inputType = input.required<string>();
  inputId = input.required<string>();
  labelName = input.required<string>();

  max = input<number>(Number.MAX_SAFE_INTEGER);
  min = input<number>(Number.MIN_SAFE_INTEGER);
  step = input<number>(Number.MIN_SAFE_INTEGER);
  readonly = input<boolean>(false);
  noMargin = input<boolean>(false);

  value = signal<any>('');
  disabled = signal<boolean>(false);

  private onTouched: Function = () => { };
  private onChanged: Function = () => { };

  injector = inject(Injector);
  private _cdr = inject(ChangeDetectorRef);

  isValidTime = signal<boolean>(true);

  ngAfterViewInit(): void {
    const ngControl = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control.set(ngControl.control as FormControl);
    }
  }

  inputOnChange(event: Event) {
    var inputValue = (event.target as any).value;
    if (this.inputType() === 'number') {
      if (inputValue < this.min()) {
        (event.target as any).value = this.min();
      } else if (inputValue > this.max()) {
        (event.target as any).value = this.max();
      }
    }

    if (this.inputType() === 'number') {
      this.setValue(Number((event.target as any).value), true);
      return;
    }

    if (this.inputType() === 'time') {
      this.setValue(this.validateTime((event.target as any).value), true);
      return;
    }

    this.setValue((event.target as any).value, true);
  }

  protected setValue(obj: any, emitEvent: boolean) {
    this.value.set(obj);
    if (emitEvent && this.onChanged) {
      this.onChanged(obj);
      this.onTouched();
    }
  }

  writeValue(obj: any): void {
    this.setValue(obj, false);
    this._cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  onTimeChange(newValue: string) {
    this.value.set(newValue); // Update the time signal with the new input value
  }

  private validateTime(time: string): string {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (!timeRegex.test(time)) {
      this.isValidTime.set(false);
      return time;
    }

    const [hours, minutes] = time.split(':').map(Number);
    if (minutes % 15 !== 0) {
      const roundedMinutes = Math.round(minutes / 15) * 15;
      this.isValidTime.set(true);
      return `${this.padTime(hours)}:${this.padTime(roundedMinutes)}`;
    }

    this.isValidTime.set(true);
    return time;
  }

  private padTime(time: number): string {
    return time < 10 ? `0${time}` : time.toString();
  }
}
