import { CommonModule } from '@angular/common';
import {
  Component,
  Injector,
  Input,
  OnInit,
  computed,
  inject
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { CreateEmployeeCommand, FamilyStatus, Gender, EmployeeDto, EmployeeStatus, UpdateEmployeeCommand } from 'src/app/global/apis/backend/models';
import { EmployeeService } from 'src/app/global/apis/backend/services';
import { toISODateString } from 'src/app/global/helper/isoDateFormater';
import { getEmployeeStatusKeyValue, getEmployeeStatusSelection } from 'src/app/global/helper/employeeStatusTransform';
import { FormDatePickerComponent } from 'src/app/shared/ui/form-date-picker/form-date-picker.component';
import { FormDropdownComponent } from 'src/app/shared/ui/form-dropdown/form-dropdown.component';
import { FormInputComponent } from 'src/app/shared/ui/form-input/form-input.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { SuccessNotificationService } from 'src/app/shared/services/success-notification.service';

@Component({
  selector: 'app-employee.detail',
  templateUrl: './employee.detail.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    FloatLabelModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    InputSwitchModule,
    FormInputComponent,
    FormDropdownComponent,
    FormDatePickerComponent,
    TableModule,
    TranslocoDirective
  ],
})
export default class EmployeeDetailComponent implements OnInit {
    @Input({ required: true }) readonly id?: string; // -> from route param

    public _isEdit = false;
    private readonly _employeeService = inject(EmployeeService);
    private readonly _fb = inject(FormBuilder);
    private readonly _componentInjector = inject(Injector);
    private readonly _messageService = inject(MessageService);
    private readonly _router = inject(Router);
    private _entry: EmployeeDto | null = null;
    private _successService = inject(SuccessNotificationService);


    employeeNumberSignal = computed(() => {
      if (this._isEdit) {
        return this._entry?.id;
      }
      return -1;
    });

    private redirectPath = ['employee'];
    private route: ActivatedRoute = inject(ActivatedRoute);

    public employeeStatusSelection = getEmployeeStatusSelection();

    public isLoading = true;
    public formEmployee = this._fb.group({
      id: [''],
      employeeNumber: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      dateOfBirth: [new Date(), [Validators.required]],
      entrydate: [new Date(), [Validators.required]],
      company: ['', [Validators.required]],
      status: [getEmployeeStatusKeyValue(EmployeeStatus.Active), [Validators.required]]
    });

    constructor() {
      const state = this._router.getCurrentNavigation()?.extras.state;

      if (state) {
        this.redirectPath = state['redirectPath'] ?? ['employee'];
      }
    }

    ngOnInit(): void {
      if (this.id) {
        this._isEdit = true;
        this._employeeService.employeeGetEmployeeById({ id: this.id }).subscribe((x) => {
          this._entry = x;
          this.setFormFromEntry(x);
          this.isLoading = false;
        });
      }

      this.isLoading = false;
    }

    onSubmit() {
      if (this._isEdit) {this.update();}
      else {this.create();}
    }

    update() {
      const neueEmployee: UpdateEmployeeCommand = {
        id: this.formEmployee.get('id')?.value ?? '',
        employeeNumber: this.formEmployee.get('employeeNumber')?.value ?? '',
        firstName: this.formEmployee.get('firstName')?.value ?? '',
        lastName: this.formEmployee.get('lastName')?.value ?? '',
        email: this.formEmployee.get('email')?.value ?? '',
        phone: this.formEmployee.get('phone')?.value ?? '',
        dateOfBirth: toISODateString(this.formEmployee.get('dateOfBirth')?.value ?? new Date()),
        entryDate: toISODateString(this.formEmployee.get('entrydate')?.value ?? new Date()),
        company: this.formEmployee.get('company')?.value ?? '',
        extraEmails: [],
        status: +(this.formEmployee.get('status')?.value?.key ?? EmployeeStatus.Active),
        country: 'Österreich',
        zipCode: '1234',
        city: 'Wien',
        street: 'Musterstraße 123',
        iban: 'AT123456789012345678',
        bic: 'BIC12345',
        bankName: 'Bank',
        socialSecurityNumber: '1234567890',
        jobtitle: 'Jobtitel',
        wage: 0,
        tradeUnionFee: 0,
        singleIncomeEarnerEmployeeChildren: 0,
        leavingDate: toISODateString(new Date()),
        familyStatus: FamilyStatus.Single,
        gender: Gender.Male,
        homeOfficeDays: 0,
        commuterKilometers: 0,
        weeklyWorkingHours: 0
      };
      this._employeeService.employeeUpdateEmployee({ body: neueEmployee })
        .subscribe(() => {
          this._messageService.add({
            severity: 'success',
            summary: 'Erfolg',
            detail:
                        'Employee ' +
                        neueEmployee.id +
                        ' erfolgreich bearbeitet!',
          });

          this._successService.displaySuccessionMsg('employeeEditSuccess');
          this.back();
        });
    }

    create() {
      const neueEmployee: CreateEmployeeCommand = {
        id: this.formEmployee.get('id')?.value ?? '',
        employeeNumber: this.formEmployee.get('employeeNumber')?.value ?? '',
        firstName: this.formEmployee.get('firstName')?.value ?? '',
        lastName: this.formEmployee.get('lastName')?.value ?? '',
        email: this.formEmployee.get('email')?.value ?? '',
        phone: this.formEmployee.get('phone')?.value ?? '',
        dateOfBirth: toISODateString(this.formEmployee.get('dateOfBirth')?.value ?? new Date()),
        entryDate: toISODateString(this.formEmployee.get('entrydate')?.value ?? new Date()),
        company: this.formEmployee.get('company')?.value ?? '',
        extraEmails: [],
        status: +(this.formEmployee.get('status')?.value?.key ?? EmployeeStatus.Active),
        country: 'Österreich',
        zipCode: '1234',
        city: 'Wien',
        street: 'Musterstraße 123',
        iban: 'AT123456789012345678',
        bic: 'BIC12345',
        bankName: 'Bank',
        socialSecurityNumber: '1234567890',
        jobtitle: 'Jobtitel',
        wage: 0,
        tradeUnionFee: 0,
        singleIncomeEarnerEmployeeChildren: 0,
        leavingDate: toISODateString(new Date()),
        familyStatus: FamilyStatus.Single,
        gender: Gender.Male,
        homeOfficeDays: 0,
        commuterKilometers: 0,
        weeklyWorkingHours: 0
      };
      this._employeeService.employeeCreateEmployee({ body: neueEmployee })
        .subscribe(() => {
          this._messageService.add({
            severity: 'success',
            summary: 'Erfolg',
            detail:
                        'Employee erfolgreich angelegt!',
          });
          this._successService.displaySuccessionMsg('employeeAddSuccess');
          this.back();
        });
    }

    private setFormFromEntry(dto: EmployeeDto) {
        this.formEmployee.setValue(
          {
            id: dto.id,
            employeeNumber: dto.employeeNumber,
            firstName: dto.firstName,
            lastName: dto.lastName,
            email: dto.email,
            phone: dto.phone,
            dateOfBirth: new Date(dto.dateOfBirth),
            entrydate: new Date(dto.entryDate),
            company: dto.company,
            status: getEmployeeStatusKeyValue(dto.status)
          },
          { emitEvent: false }
        );
    }

    public resetForm() {
      if (this._isEdit) {
        this.setFormFromEntry(this._entry!);
        return;
      }
      this.formEmployee.setValue({
        id: '',
        employeeNumber: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        dateOfBirth: new Date(),
        entrydate: new Date(),
        company: '',
        status: getEmployeeStatusKeyValue(EmployeeStatus.Active)
      });
    }

    public back() {
      structuredClone(this.redirectPath) !==
            structuredClone(['employee'])
        ? this._router.navigate(this.redirectPath, {
          state: { rememberValues: true },
        })
        : this._router.navigate(this.redirectPath);
    }
}
