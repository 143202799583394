import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Provider,
  computed,
  forwardRef,
  inject,
  input,
  signal,
  viewChild
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';

const DROPDOWN_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormDropdownComponent),
  multi: true,
};

@Component({
  selector: 'app-form-dropdown',
  standalone: true,
  imports: [NgClass, FormsModule, ReactiveFormsModule],
  templateUrl: './form-dropdown.component.html',
  providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDropdownComponent implements ControlValueAccessor {
  control = signal<FormControl>(new FormControl());

  inputId = input.required<string>();
  optionList = input.required<any[]>();
  multipleSelection = input<boolean>(false);
  optionLabel = input.required<string>();
  filter = input<boolean>(false);
  showClear = input<boolean>(false);
  optionValue = input<string>();
  labelName = input.required<string>();
  group = input<boolean>(false);
  selectedItemsLabel = input<string>();
  initialLabel = signal<string>('');

  selectedValues = signal<any[]>([]);
  disabled = signal<boolean>(false);
  searchQuery = signal<string>('');
  noMargin = input<boolean>(false);

  private onTouched: Function = () => { };
  private onChanged: Function = () => { };

  inputOptions = computed(() => {
    const sq = this.searchQuery();
    const label = this.optionLabel();
    const value = this.optionValue();
    let groupResult: any[] = [];
    let result: any[] = [];
    result = this.optionList()
      .filter((value, index, array) => array.indexOf(value) === index)
      .map((option) => {
        if (this.group()) {
          return option.items.forEach((listItem: any) => {
            groupResult.push({
              value: listItem[value ?? label],
              label: listItem[label],
              selected: this.selectedValues().some(
                (value) =>
                  value === listItem[this.optionValue() ?? this.optionLabel()]
              ),
              group: option.label,
            });
          });
        } else {
          return {
            value: option[value ?? label],
            label: option[label],
            selected: this.selectedValues().some(
              (value) =>
                value === option[this.optionValue() ?? this.optionLabel()]
            ),
          };
        }
      });
    if (this.group()) {
      return this.uniqueArray(groupResult);
    }
    return this.uniqueArray(result);
  });

  filteredInputOptions = computed(() => {
    const sq = this.searchQuery();
    return this.inputOptions().filter((option) =>
      option?.label?.toLocaleLowerCase().includes(sq.toLocaleLowerCase())
    );
  });

  selectedLabels = computed(() => {
    return this.inputOptions()
      .filter((option) => option?.selected)
      ?.map((option, index) =>
        index < 1 ? option?.label : ' ' + option?.label
      )
      .toString();
  });

  dropdownOptionsAreVisible = signal<boolean>(false);
  listComponent = viewChild<ElementRef>('dropdown');

  injector = inject(Injector);
  private _cdr = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    const ngControl = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control.set(ngControl.control as FormControl);
    }
  }

  showDropdownOptions() {
    this.dropdownOptionsAreVisible.update((options) => !options);
  }

  hideDropdownOptions(event: FocusEvent) {
    const clickedElement = event.relatedTarget as HTMLElement;
    const clickedOnDropdownOption = clickedElement
      ? clickedElement.classList.contains('app-dropdown-option')
      : false;
    if (!clickedOnDropdownOption) {
      this.dropdownOptionsAreVisible.set(false);
    }
  }

  selectOption(value: any) {
    if (this.initialLabel() !== '') {
      this.initialLabel.set('');
    }
    this.onTouched();
    if (this.multipleSelection()) {
      const values = this.selectedValues();
      const index = values.indexOf(value);
      if (index > -1) {
        values.splice(index, 1); // Removing the value if already selected
      } else {
        values.push(value); // Adding the value if not selected
      }
      this.setValue(values, true);
    } else {
      this.setValue(value, true);
    }

    if (!this.multipleSelection()) {
      this.dropdownOptionsAreVisible.set(false);
    }
  }

  filterOptions(event: Event) {
    this.searchQuery.set((event.target as any).value);
  }

  clearInput() {
    this.setValue([], true);
  }

  writeValue(obj: any): void {
    {
      if (obj) {
        if (Array.isArray(obj)) {
          obj = obj.map(
            (item) => item[this.optionValue() ?? this.optionLabel()] ?? item
          );
        } else {
          this.initialLabel.set(obj[this.optionLabel()]);
          obj = obj[this.optionValue() ?? this.optionLabel()] ?? obj;
        }
        this.setValue(obj, false);
        this._cdr.markForCheck();
      }
    }
  }

  protected setValue(obj: any, emitEvent: boolean) {
    let result;
    if (this.group()) {
      let groupResult: any[] = [];
      this.optionList().forEach((itemsList) =>
        groupResult.push(
          ...itemsList.items.filter((item: any) => {
            return obj.some((o: any) => {
              return o === item[this.optionValue() ?? this.optionLabel()];
            });
          })
        )
      );
      const arr = this.uniqueArray([...obj], true);
      this.selectedValues.set([...arr]);
      if (emitEvent && this.onChanged) {
        this.onChanged(
          this.uniqueArray(groupResult, false, this.optionValue())
        );
        this.onTouched();
      }
    } else {
      if (Array.isArray(obj)) {
        this.selectedValues.set([...obj]);
        result = this.optionList().filter((o) =>
          obj.some((o1) => o[this.optionValue() ?? this.optionLabel()] === o1)
        );
      } else {
        this.selectedValues.set([obj]);
        result = this.optionList().find(
          (o) => o[this.optionValue() ?? this.optionLabel()] === obj
        );
      }

      if (emitEvent && this.onChanged) {
        this.onChanged(result);
        this.onTouched();
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  private uniqueArray(
    a: any[],
    ids: boolean = false,
    valueName: string = 'value'
  ) {
    if (ids) {
      return [...new Set(a)];
    }
    const map = new Map<any, any>();
    for (const element of a) {
      map.set(element[valueName], element);
    }
    return [...map.values()];
  }
}
