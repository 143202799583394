import { EmployeeStatus } from '../apis/backend/models';

export function transformEmployeeStatus(value: number): string {
  const enumKey =
        Object.keys(EmployeeStatus).find(
          (key: string) => (EmployeeStatus as any)[key] === value
        ) || 'error';
  return enumKey;
}

export function getEmployeeStatusSelection(): Array<{
    value: string;
    key: number;
}> {
  return Object.keys(EmployeeStatus)
    .filter(StringIsNumber)
    .map((key: any) => {
      return { value: EmployeeStatus[key], key: key };
    });
}

export function getEmployeeStatusKeyValue(item: EmployeeStatus): { value: string; key: number; } {
  return {
    value: EmployeeStatus[item],
    key: item,
  };
}

const StringIsNumber = (value: any) => isNaN(Number(value)) === false;