import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor(private _msalService: MsalService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let accountInfo = this._msalService.instance.getActiveAccount();

        if (
            !accountInfo &&
            this._msalService.instance.getAllAccounts().length > 0
        ) {
            let accounts = this._msalService.instance.getAllAccounts();
            this._msalService.instance.setActiveAccount(accounts[0]);
            accountInfo = this._msalService.instance.getActiveAccount();
        }

        const userRoles = accountInfo?.idTokenClaims?.roles ?? [];
        const allowedRoles = next.data["roles"];
        const matchingRoles = userRoles.filter(x => allowedRoles.includes(x));
        return matchingRoles.length > 0;
    }
}