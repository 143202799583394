import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { employeeComputeField } from 'src/app/global/apis/backend/fn/employee/employee-compute-field';
import { employeeExportEmployee } from 'src/app/global/apis/backend/fn/employee/employee-export-employee';
import { employeeExportFilteredEmployee } from 'src/app/global/apis/backend/fn/employee/employee-export-filtered-employee';
import { employeeFilterEmployee } from 'src/app/global/apis/backend/fn/employee/employee-filter-employee';
import { EmployeeService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import {
  FunctionInjectionMap,
  GLOBAL_SEARCH_COLLUMS,
  LazyTableService,
  SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: [],
  providers: [
    DialogService,
    {
      provide: GLOBAL_SEARCH_COLLUMS,
      useValue: [
        'employeeNumber',
        'firstName',
        'lastName',
        'email',
        'phone',
        'Company',
      ],
    },
    {
      provide: SERVICE_FUNCTIONS,
      useValue: {
        getLazyData: employeeFilterEmployee,
        exportAll: employeeExportFilteredEmployee,
        exportSelected: employeeExportEmployee,
        deactivateSelected: null,
        computeField: employeeComputeField,
        archiveSelected: null,
      } as FunctionInjectionMap,
    },
    LazyTableService<EmployeeService>,
  ],
  imports: [
    CommonModule,
    ToastModule,
    TableModule,
    ProgressSpinnerModule,
    LazyTableComponent,
  ]
})
export class EmployeeComponent {
  diaRef!: DynamicDialogRef;
  public dataService: LazyTableService<EmployeeService> = inject(
    LazyTableService<EmployeeService>
  );

  public cols = [
    { field: 'employeeNumber', header: 'employeenumber', type: 'text', preselect: false },
    { field: 'firstName', header: 'firstname', type: 'text' },
    { field: 'lastName', header: 'lastName', type: 'text' },
    { field: 'email', header: 'email', type: 'text' },
    { field: 'phone', header: 'phone', type: 'text' },
    { field: 'company', header: 'company', type: 'text', preselect: false }
  ];

  constructor() { }
}
