<svg id="Ebene_2" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 307.41 80.5">
    <defs>
        <style>
            .cls-1,
            .cls-2,
            .cls-3 {
                stroke: #fff;
            }

            .cls-1,
            .cls-3 {
                stroke-linecap: round;
                stroke-linejoin: round;
            }

            .cls-1,
            .cls-3,
            .cls-4 {
                fill: #fff;
            }

            .cls-2 {
                fill: none;
                stroke-miterlimit: 5;
                stroke-width: 3px;
            }

            .cls-3 {
                stroke-width: 2px;
            }
        </style>
    </defs>
    <g id="Ebene_6" data-name="Ebene 6">
        <path class="cls-2"
            d="m6.45,52.7c1.78,3.83,5.35,10.26,11.65,15.84,1.68,1.49,5.29,4.66,10.6,7.05,15.44,6.95,30.09,1.27,31.97.51,1.43-.58,12.08-5.25,18.78-16.29M27.49,10.87c2.83-.39,5.16-3.31,4.44-6-.61-2.32-3.27-3.62-5.56-3.33-2.52.31-5.02,2.6-4.67,5.33.34,2.68,3.25,4.35,5.78,4Zm55.88,40.21s.41-1.4.69-2.83c2.86-14.51-4.53-30.8-17.33-40C52.53-1.94,34.05,2.48,30.87,3.06m17.49,57.08c2.83-.39,5.16-3.31,4.44-6-.61-2.32-3.27-3.62-5.56-3.33-2.52.31-5.02,2.6-4.67,5.33.34,2.68,3.25,4.35,5.78,4Zm26.94-31.77l-23.37,24m30.33,7.89c2.83-.39,5.16-3.31,4.44-6-.61-2.32-3.27-3.62-5.56-3.33-2.52.31-5.02,2.6-4.67,5.33.34,2.68,3.25,4.35,5.78,4Zm-11.05-27.71l-12.49-13.34-8.69,8.54m34.47,11.02l-9.21-10.39-13.04,13.33-25.44-27.89-26.89,30.26m26.89-30.26l-27.43,30.87" />
        <circle class="cls-2" cx="6.14" cy="48.08" r="4.64" />
    </g>
    <g id="Ebene_9" data-name="Ebene 9">
        <polygon class="cls-3"
            points="36.75 21.36 27.93 31.89 30.63 39.09 34.5 33.24 39.27 39.09 41.34 33.6 49.71 36.39 36.75 21.36" />
        <polygon class="cls-3"
            points="71.38 38.86 75.03 34.51 80.39 40.72 76.93 39.57 76.07 41.84 73.66 40.31 71.38 38.86" />
        <polyline class="cls-1" points="55.79 26.4 58.32 28.09 59.53 26.85 61.54 27.58 58.42 23.65 55.79 26.4" />
        <g>
            <path class="cls-4"
                d="m115.13,53.43h-5.1l9.29-26.38h5.9l9.3,26.38h-5.1l-7.04-20.97h-.21l-7.03,20.97Zm.17-10.34h13.91v3.84h-13.91v-3.84Z" />
            <path class="cls-4" d="m139.9,53.43v-26.38h4.78v22.37h11.62v4.01h-16.39Z" />
            <path class="cls-4"
                d="m162.33,53.43v-26.38h9.89c2.03,0,3.73.38,5.11,1.13,1.38.76,2.42,1.79,3.13,3.11.71,1.32,1.06,2.81,1.06,4.49s-.36,3.19-1.07,4.51c-.71,1.31-1.76,2.35-3.16,3.1s-3.1,1.13-5.14,1.13h-6.55v-3.93h5.91c1.19,0,2.16-.21,2.91-.62.76-.41,1.32-.98,1.68-1.7s.55-1.55.55-2.49-.18-1.76-.55-2.47-.93-1.27-1.69-1.67c-.76-.4-1.74-.6-2.93-.6h-4.38v22.38h-4.78Z" />
            <path class="cls-4"
                d="m200.91,34.31c-.12-1.12-.62-2-1.51-2.63-.89-.63-2.04-.94-3.46-.94-1,0-1.85.15-2.56.45-.71.3-1.26.71-1.64,1.22-.38.51-.57,1.1-.58,1.76,0,.55.13,1.03.38,1.43.25.4.6.74,1.04,1.02.44.28.92.51,1.46.7.53.19,1.07.35,1.61.48l2.47.62c1,.23,1.96.55,2.88.94s1.75.89,2.49,1.49c.73.6,1.32,1.33,1.75,2.18s.64,1.85.64,2.99c0,1.55-.4,2.9-1.19,4.08s-1.93,2.09-3.42,2.74c-1.49.66-3.29.99-5.4.99s-3.83-.32-5.34-.95c-1.51-.64-2.68-1.56-3.53-2.78-.85-1.22-1.3-2.7-1.37-4.46h4.7c.07.92.35,1.68.85,2.29.5.61,1.15,1.06,1.95,1.36.8.3,1.7.45,2.7.45s1.95-.16,2.74-.47,1.4-.75,1.85-1.31.67-1.22.68-1.98c0-.69-.21-1.26-.61-1.71-.4-.45-.95-.83-1.65-1.13-.71-.3-1.53-.58-2.48-.82l-3-.77c-2.17-.56-3.89-1.41-5.14-2.54-1.26-1.14-1.89-2.65-1.89-4.54,0-1.55.42-2.91,1.27-4.08.85-1.17,2-2.08,3.46-2.72,1.46-.65,3.11-.97,4.96-.97s3.51.32,4.93.97c1.41.65,2.52,1.55,3.33,2.69.81,1.15,1.22,2.46,1.25,3.95h-4.6Z" />
            <path class="cls-4"
                d="m234.6,35.96h-4.82c-.14-.79-.39-1.49-.76-2.11-.37-.61-.83-1.14-1.38-1.56s-1.17-.75-1.87-.97c-.7-.22-1.45-.33-2.26-.33-1.43,0-2.7.36-3.81,1.08s-1.97,1.76-2.6,3.14-.94,3.06-.94,5.04.32,3.72.95,5.09c.63,1.38,1.5,2.42,2.6,3.12,1.1.7,2.37,1.05,3.79,1.05.79,0,1.53-.11,2.22-.32s1.31-.52,1.87-.93c.55-.41,1.02-.92,1.4-1.52s.65-1.29.79-2.06l4.82.03c-.18,1.25-.57,2.43-1.17,3.53-.6,1.1-1.38,2.07-2.34,2.9-.96.84-2.09,1.49-3.37,1.96-1.29.47-2.72.7-4.29.7-2.32,0-4.39-.54-6.21-1.61s-3.25-2.62-4.3-4.65c-1.05-2.03-1.57-4.46-1.57-7.29s.53-5.27,1.58-7.3,2.49-3.57,4.31-4.64c1.82-1.07,3.88-1.61,6.18-1.61,1.47,0,2.83.21,4.1.62,1.26.41,2.39,1.02,3.37,1.81s1.8,1.76,2.44,2.91c.64,1.15,1.06,2.45,1.26,3.92Z" />
            <path class="cls-4"
                d="m243.95,53.43h-5.1l9.29-26.38h5.9l9.3,26.38h-5.1l-7.04-20.97h-.21l-7.03,20.97Zm.17-10.34h13.91v3.84h-13.91v-3.84Z" />
            <path class="cls-4" d="m268.72,53.43v-26.38h4.78v22.37h11.62v4.01h-16.39Z" />
            <path class="cls-4"
                d="m290.15,53.43v-26.38h17.15v4.01h-12.38v7.16h11.49v4.01h-11.49v7.2h12.48v4.01h-17.26Z" />
        </g>
    </g>
</svg>