import { CommonModule } from '@angular/common';
import {
  Component,
  Injector,
  Input,
  OnInit,
  computed,
  inject
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { CreateCostCenterCommand, CostCenterDto, UpdateCostCenterCommand } from 'src/app/global/apis/backend/models';
import { CostCenterService } from 'src/app/global/apis/backend/services';
import { FormInputComponent } from 'src/app/shared/ui/form-input/form-input.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { SuccessNotificationService } from 'src/app/shared/services/success-notification.service';


@Component({
  selector: 'app-costcenter.detail',
  templateUrl: './costcenter.detail.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    FloatLabelModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    InputSwitchModule,
    FormInputComponent,
    TableModule,
    TranslocoDirective
  ],
})
export default class CostCenterDetailComponent implements OnInit {
    @Input({ required: true }) readonly id?: string; // -> from route param

    public _isEdit = false;
    private readonly _costcenterService = inject(CostCenterService);
    private readonly _fb = inject(FormBuilder);
    private readonly _componentInjector = inject(Injector);
    private readonly _messageService = inject(MessageService);
    private readonly _router = inject(Router);
    private _entry: CostCenterDto | null = null;

    costcenterNumberSignal = computed(() => {
      if (this._isEdit) {
        return this._entry?.id;
      }
      return -1;
    });

    private redirectPath = ['costcenter'];
    private route: ActivatedRoute = inject(ActivatedRoute);
    private _successService = inject(SuccessNotificationService);

    public algStunden: number = 0.25;

    public isLoading = true;
    public formCostCenter = this._fb.group({
      id: [''],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      hourlyrate: [0, [Validators.required, Validators.min(0)]],
      amount: [0],
      active: [true],
      internal: [true],
      factor: 1,
      surcharge: 0
    });

    constructor() {
      const state = this._router.getCurrentNavigation()?.extras.state;

      if (state) {
        this.redirectPath = state['redirectPath'] ?? ['costcenter'];
      }
    }

    ngOnInit(): void {
      if (this.id) {
        this._isEdit = true;
        this._costcenterService.costCenterGetCostCenterById({ id: this.id }).subscribe((x) => {
          this._entry = x;
          this.setFormFromEntry(x);
          this.isLoading = false;
        });
      }

      this.isLoading = false;
    }

    onSubmit() {
      if (this._isEdit) {this.update();}
      else {this.create();}
    }

    update() {
      const neueCostCenter: UpdateCostCenterCommand = {
        id: this.formCostCenter.get('id')?.value ?? '',
        name: this.formCostCenter.get('name')?.value ?? '',
        description: this.formCostCenter.get('description')?.value ?? '',
        hourlyRate: this.formCostCenter.get('hourlyrate')?.value ?? 0,
        amount: this.formCostCenter.get('amount')?.value,
        active: this.formCostCenter.get('active')?.value ?? true,
        internal: this.formCostCenter.get('internal')?.value ?? true,
        factor: this.formCostCenter.get('factor')?.value ?? 1,
        surcharge: this.formCostCenter.get('surcharge')?.value ?? 0

      };
      this._costcenterService.costCenterUpdateCostCenter({ body: neueCostCenter })
        .subscribe(() => {
          this._messageService.add({
            severity: 'success',
            summary: 'Erfolg',
            detail:
                        'CostCenter ' +
                        neueCostCenter.id +
                        ' erfolgreich bearbeitet!',
          });
          this._successService.displaySuccessionMsg('costCentreEditedSuccess');

          this.back();
        });
    }

    create() {
      const neueCostCenter: CreateCostCenterCommand = {
        name: this.formCostCenter.get('name')?.value ?? '',
        description: this.formCostCenter.get('description')?.value ?? '',
        hourlyRate: this.formCostCenter.get('hourlyrate')?.value ?? 0,
        amount: this.formCostCenter.get('amount')?.value,
        active: this.formCostCenter.get('active')?.value ?? true,
        internal: this.formCostCenter.get('internal')?.value ?? true,
        factor: this.formCostCenter.get('factor')?.value ?? 1,
        surcharge: this.formCostCenter.get('surcharge')?.value ?? 0
      };
      this._costcenterService.costCenterCreateCostCenter({ body: neueCostCenter })
        .subscribe(() => {
          this._messageService.add({
            severity: 'success',
            summary: 'Erfolg',
            detail:
                        'CostCenter erfolgreich angelegt!',
          });
          this._successService.displaySuccessionMsg('costCentreCreateSuccess');
          this.back();
        });
    }

    private setFormFromEntry(dto: CostCenterDto) {
      try {
        this.formCostCenter.setValue(
          {
            id: dto.id,
            name: dto.name,
            description: dto.description,
            factor: dto.factor ?? 1,
            hourlyrate: dto.hourlyRate,
            amount: dto.amount ?? null,
            active: dto.active,
            internal: dto.internal,
            surcharge: dto.surcharge ?? 0
          },
          { emitEvent: false }
        );
      } catch (e) {
        console.error('Error setting form values: ', e);
        throw e;
      }
    }

    public resetForm() {
      if (this._isEdit) {

        this.setFormFromEntry(this._entry!);
        return;
      }
      this.formCostCenter.setValue({
        id: '',
        name: '',
        description: '',
        hourlyrate: 0,
        amount: null,
        active: true,
        internal: true,
        surcharge: 0,
        factor: 1
      });
    }

    public back() {
      structuredClone(this.redirectPath) !==
            structuredClone(['costcenter'])
        ? this._router.navigate(this.redirectPath, {
          state: { rememberValues: true },
        })
        : this._router.navigate(this.redirectPath);
    }
}
