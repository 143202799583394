<section class="grid grid-cols-1 gap-8 px-6 xl:grid-cols-3 2xl:grid-cols-4 md:grid-cols-2 mt-2">
    <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200 ">
        <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35" cy="35" r="35" fill="#713BDB" fill-opacity="0.05" />
                <path
                    d="M26 44C26 40.625 30.5 40.625 32.75 38.375C33.875 37.25 30.5 37.25 30.5 31.625C30.5 27.8754 31.9996 26 35 26C38.0004 26 39.5 27.8754 39.5 31.625C39.5 37.25 36.125 37.25 37.25 38.375C39.5 40.625 44 40.625 44 44"
                    stroke="#6F52ED" stroke-width="2" stroke-linecap="square" />
            </svg>

            <div class="mx-2">
                <h3 class="text-2xl font-medium text-gray-800">5</h3>
                <p class="mt-1 text-sm text-gray-500">Mitarbeiter:innen</p>
            </div>
        </div>
    </div>

    <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200 ">
        <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35" cy="35" r="35" fill="#33D69F" fill-opacity="0.07" />
                <path d="M26 39L31 34" stroke="#21B8C7" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M32 34C32.5523 34 33 33.5523 33 33C33 32.4477 32.5523 32 32 32C31.4477 32 31 32.4477 31 33C31 33.5523 31.4477 34 32 34Z"
                    stroke="#21B8C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M37 39C37.5523 39 38 38.5523 38 38C38 37.4477 37.5523 37 37 37C36.4477 37 36 37.4477 36 38C36 38.5523 36.4477 39 37 39Z"
                    stroke="#21B8C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M38 37L44 31M33 34L36 37L33 34Z" stroke="#21B8C7" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>

            <div class="mx-2">
                <h3 class="text-2xl font-medium text-gray-800">6.8</h3>
                <p class="mt-1 text-sm text-gray-500">Durchschnittliche Arbeitszeit</p>
            </div>
        </div>
    </div>

    <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200 ">
        <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35" cy="35" r="35" fill="#FF4C61" fill-opacity="0.05" />
                <path d="M26 32L32 38L36 34L43.405 41.405" stroke="#FF4C61" stroke-width="2" stroke-linecap="square" />
                <path d="M43.405 41.405L44 42" stroke="#FF4C61" stroke-width="2" stroke-linecap="round" />
                <path d="M44 39V42H41" stroke="#FF4C61" stroke-width="2" stroke-linecap="square" />
            </svg>

            <div class="mx-2">
                <h3 class="text-2xl font-medium text-gray-800">9 <span class="text-xl text-gray-600">(14%)</span></h3>
                <p class="mt-1 text-sm text-gray-500">Verrechenbarkeit</p>
            </div>
        </div>
    </div>

    <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200 ">
        <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35" cy="35" r="35" fill="#4CB8FF" fill-opacity="0.07" />
                <path
                    d="M42 26V44H31C30.2044 44 29.4413 43.6839 28.8787 43.1213C28.3161 42.5587 28 41.7956 28 41V29C28 28.2044 28.3161 27.4413 28.8787 26.8787C29.4413 26.3161 30.2044 26 31 26H42Z"
                    stroke="#4CB8FF" stroke-width="2" stroke-linecap="square" />
                <path d="M28 41C28 40.2044 28.3161 39.4413 28.8787 38.8787C29.4413 38.3161 30.2044 38 31 38H42"
                    stroke="#4CB8FF" stroke-width="2" stroke-linecap="square" />
            </svg>

            <div class="mx-2">
                <h3 class="text-2xl font-medium text-gray-800">92%</h3>
                <p class="mt-1 text-sm text-gray-500">Getätigte Zeitbuchungen</p>
            </div>
        </div>
    </div>
</section>