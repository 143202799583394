/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PagedListOfTimeBookingFilter } from '../models/paged-list-of-time-booking-filter';
import { timeBookingComputeField } from '../fn/time-booking/time-booking-compute-field';
import { TimeBookingComputeField$Params } from '../fn/time-booking/time-booking-compute-field';
import { timeBookingCreateTimeBooking } from '../fn/time-booking/time-booking-create-time-booking';
import { TimeBookingCreateTimeBooking$Params } from '../fn/time-booking/time-booking-create-time-booking';
import { timeBookingDeActivate } from '../fn/time-booking/time-booking-de-activate';
import { TimeBookingDeActivate$Params } from '../fn/time-booking/time-booking-de-activate';
import { TimeBookingDto } from '../models/time-booking-dto';
import { timeBookingExportFilteredTimeBooking } from '../fn/time-booking/time-booking-export-filtered-time-booking';
import { TimeBookingExportFilteredTimeBooking$Params } from '../fn/time-booking/time-booking-export-filtered-time-booking';
import { timeBookingExportTimeBooking } from '../fn/time-booking/time-booking-export-time-booking';
import { TimeBookingExportTimeBooking$Params } from '../fn/time-booking/time-booking-export-time-booking';
import { timeBookingFilterTimeBooking } from '../fn/time-booking/time-booking-filter-time-booking';
import { TimeBookingFilterTimeBooking$Params } from '../fn/time-booking/time-booking-filter-time-booking';
import { timeBookingGetTimeBookingById } from '../fn/time-booking/time-booking-get-time-booking-by-id';
import { TimeBookingGetTimeBookingById$Params } from '../fn/time-booking/time-booking-get-time-booking-by-id';
import { timeBookingUpdateTimeBooking } from '../fn/time-booking/time-booking-update-time-booking';
import { TimeBookingUpdateTimeBooking$Params } from '../fn/time-booking/time-booking-update-time-booking';

@Injectable({ providedIn: 'root' })
export class TimeBookingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `timeBookingGetTimeBookingById()` */
  static readonly TimeBookingGetTimeBookingByIdPath = '/api/TimeBooking/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeBookingGetTimeBookingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  timeBookingGetTimeBookingById$Response(params: TimeBookingGetTimeBookingById$Params, context?: HttpContext): Observable<StrictHttpResponse<TimeBookingDto>> {
    return timeBookingGetTimeBookingById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timeBookingGetTimeBookingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timeBookingGetTimeBookingById(params: TimeBookingGetTimeBookingById$Params, context?: HttpContext): Observable<TimeBookingDto> {
    return this.timeBookingGetTimeBookingById$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimeBookingDto>): TimeBookingDto => r.body)
    );
  }

  /** Path part for operation `timeBookingUpdateTimeBooking()` */
  static readonly TimeBookingUpdateTimeBookingPath = '/api/TimeBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeBookingUpdateTimeBooking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingUpdateTimeBooking$Response(params: TimeBookingUpdateTimeBooking$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return timeBookingUpdateTimeBooking(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timeBookingUpdateTimeBooking$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingUpdateTimeBooking(params: TimeBookingUpdateTimeBooking$Params, context?: HttpContext): Observable<string> {
    return this.timeBookingUpdateTimeBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `timeBookingCreateTimeBooking()` */
  static readonly TimeBookingCreateTimeBookingPath = '/api/TimeBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeBookingCreateTimeBooking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingCreateTimeBooking$Response(params: TimeBookingCreateTimeBooking$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return timeBookingCreateTimeBooking(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timeBookingCreateTimeBooking$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingCreateTimeBooking(params: TimeBookingCreateTimeBooking$Params, context?: HttpContext): Observable<string> {
    return this.timeBookingCreateTimeBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `timeBookingDeActivate()` */
  static readonly TimeBookingDeActivatePath = '/api/TimeBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeBookingDeActivate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingDeActivate$Response(params: TimeBookingDeActivate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return timeBookingDeActivate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timeBookingDeActivate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingDeActivate(params: TimeBookingDeActivate$Params, context?: HttpContext): Observable<Array<string>> {
    return this.timeBookingDeActivate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `timeBookingExportTimeBooking()` */
  static readonly TimeBookingExportTimeBookingPath = '/api/TimeBooking/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeBookingExportTimeBooking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingExportTimeBooking$Response(params: TimeBookingExportTimeBooking$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return timeBookingExportTimeBooking(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timeBookingExportTimeBooking$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingExportTimeBooking(params: TimeBookingExportTimeBooking$Params, context?: HttpContext): Observable<Blob> {
    return this.timeBookingExportTimeBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `timeBookingFilterTimeBooking()` */
  static readonly TimeBookingFilterTimeBookingPath = '/api/TimeBooking/filter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeBookingFilterTimeBooking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingFilterTimeBooking$Response(params: TimeBookingFilterTimeBooking$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedListOfTimeBookingFilter>> {
    return timeBookingFilterTimeBooking(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timeBookingFilterTimeBooking$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingFilterTimeBooking(params: TimeBookingFilterTimeBooking$Params, context?: HttpContext): Observable<PagedListOfTimeBookingFilter> {
    return this.timeBookingFilterTimeBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagedListOfTimeBookingFilter>): PagedListOfTimeBookingFilter => r.body)
    );
  }

  /** Path part for operation `timeBookingExportFilteredTimeBooking()` */
  static readonly TimeBookingExportFilteredTimeBookingPath = '/api/TimeBooking/filter/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeBookingExportFilteredTimeBooking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingExportFilteredTimeBooking$Response(params: TimeBookingExportFilteredTimeBooking$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return timeBookingExportFilteredTimeBooking(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timeBookingExportFilteredTimeBooking$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingExportFilteredTimeBooking(params: TimeBookingExportFilteredTimeBooking$Params, context?: HttpContext): Observable<Blob> {
    return this.timeBookingExportFilteredTimeBooking$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `timeBookingComputeField()` */
  static readonly TimeBookingComputeFieldPath = '/api/TimeBooking/filter/compute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeBookingComputeField()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingComputeField$Response(params: TimeBookingComputeField$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return timeBookingComputeField(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timeBookingComputeField$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timeBookingComputeField(params: TimeBookingComputeField$Params, context?: HttpContext): Observable<number> {
    return this.timeBookingComputeField$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
