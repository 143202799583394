import { Pipe, PipeTransform} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
    name: 'translocoNoLogging',
    pure: false
})

export class TranslocoNoLoggingPipe implements PipeTransform {
    constructor(
        private translocoService: TranslocoService
    ) {}

    transform(value: any) {
        
        const lang = this.translocoService.getActiveLang();

        return this.translocoService.getTranslation(lang)[value] ?? String(value).replace(/.*\./, '');
    }
}