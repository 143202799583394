import { CommonModule } from '@angular/common';
import {
    Component,
    Injector,
    Input,
    OnInit,
    computed,
    inject
} from '@angular/core';
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { CreateKostentraegerCommand, KostentraegerDto, UpdateKostentraegerCommand } from 'src/app/global/apis/backend/models';
import { KostentraegerService } from 'src/app/global/apis/backend/services';
import { FormDatePickerComponent } from 'src/app/shared/ui/form-date-picker/form-date-picker.component';

import { FormDropdownComponent } from 'src/app/shared/ui/form-dropdown/form-dropdown.component';
import { FormInputComponent } from 'src/app/shared/ui/form-input/form-input.component';
import { FormTimePickerComponent } from 'src/app/shared/ui/form-time-picker/form-time-picker.component';

@Component({
    selector: 'app-kostentraeger.detail',
    templateUrl: './kostentraeger.detail.component.html',
    styleUrls: [],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        FloatLabelModule,
        InputTextModule,
        DropdownModule,
        InputNumberModule,
        MultiSelectModule,
        InputSwitchModule,
        FormInputComponent,
        FormDropdownComponent,
        FormTimePickerComponent,
        FormDatePickerComponent,
        TableModule
    ],
})
export default class KostentraegerDetailComponent implements OnInit {
    @Input({ required: true }) readonly id?: string; // -> from route param

    public _isEdit = false;
    private readonly _kostentraegerService = inject(KostentraegerService);
    private readonly _fb = inject(FormBuilder);
    private readonly _componentInjector = inject(Injector);
    private readonly _messageService = inject(MessageService);
    private readonly _router = inject(Router);
    private _entry: KostentraegerDto | null = null;

    kostentraegerNummerSignal = computed(() => {
        if (this._isEdit) {
            return this._entry?.id;
        }
        return -1;
    });

    private redirectPath = ['kostentraeger'];
    private route: ActivatedRoute = inject(ActivatedRoute);
    public algStunden: number = 0.25;

    public isLoading = true;
    public formKostentraeger = this._fb.group({
        id: [''],
        name: ['', [Validators.required]],
        beschreibung: ['', [Validators.required]],
        stundensatz: [0, [Validators.required, Validators.min(0)]],
        kontingent: [0],
        aktiv: [true],
        intern: [true]
    });

    constructor() {
        const state = this._router.getCurrentNavigation()?.extras.state;

        if (state) {
            this.redirectPath = state['redirectPath'] ?? ['kostentraeger'];
        }
    }

    ngOnInit(): void {
        if (this.id) {
            this._isEdit = true;
            this._kostentraegerService.kostentraegerGetKostentraegerById({ id: this.id }).subscribe((x) => {
                this._entry = x;
                this.setFormFromEntry(x);
                this.isLoading = false;
            });
        }

        this.isLoading = false;
    }

    onSubmit() {
        if (this._isEdit) this.update();
        else this.create();
    }

    update() {
        const neueKostentraeger: UpdateKostentraegerCommand = {
            id: this.formKostentraeger.get('id')?.value ?? '',
            name: this.formKostentraeger.get('name')?.value ?? '',
            beschreibung: this.formKostentraeger.get('beschreibung')?.value ?? '',
            stundensatz: this.formKostentraeger.get('stundensatz')?.value ?? 0,
            kontingent: this.formKostentraeger.get('kontingent')?.value,
            aktiv: this.formKostentraeger.get('aktiv')?.value ?? true,
            intern: this.formKostentraeger.get('intern')?.value ?? true
        };
        this._kostentraegerService.kostentraegerUpdateKostentraeger({ body: neueKostentraeger })
            .subscribe((x) => {
                this._messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail:
                        'Kostentraeger ' +
                        neueKostentraeger.id +
                        ' erfolgreich bearbeitet!',
                });
                this.back();
            });
    }

    create() {
        const neueKostentraeger: CreateKostentraegerCommand = {
            name: this.formKostentraeger.get('name')?.value ?? '',
            beschreibung: this.formKostentraeger.get('beschreibung')?.value ?? '',
            stundensatz: this.formKostentraeger.get('stundensatz')?.value ?? 0,
            kontingent: this.formKostentraeger.get('kontingent')?.value,
            aktiv: this.formKostentraeger.get('aktiv')?.value ?? true,
            intern: this.formKostentraeger.get('intern')?.value ?? true
        };
        this._kostentraegerService.kostentraegerCreateKostentraeger({ body: neueKostentraeger })
            .subscribe((response) => {
                this._messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail:
                        'Kostentraeger erfolgreich angelegt!',
                });
                this.back();
            });
    }

    private setFormFromEntry(dto: KostentraegerDto) {
        try {
            this.formKostentraeger.setValue(
                {
                    id: dto.id,
                    name: dto.name,
                    beschreibung: dto.beschreibung,
                    stundensatz: dto.stundensatz,
                    kontingent: dto.kontingent ?? null,
                    aktiv: dto.aktiv,
                    intern: dto.intern
                },
                { emitEvent: false }
            );
        } catch (e) {
            console.error('Error setting form values: ', e);
        }
    }

    public resetForm() {
        if (this._isEdit) {

            this.setFormFromEntry(this._entry!);
            return;
        }
        this.formKostentraeger.setValue({
            id: '',
            name: '',
            beschreibung: '',
            stundensatz: 0,
            kontingent: null,
            aktiv: true,
            intern: true
        });
    }

    public back() {
        structuredClone(this.redirectPath) !==
            structuredClone(['kostentraeger'])
            ? this._router.navigate(this.redirectPath, {
                state: { rememberValues: true },
            })
            : this._router.navigate(this.redirectPath);
    }
}
