/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum FamilyStatus {
  Single = 0,
  Married = 1,
  Divorced = 2,
  Widowed = 3
}
