<div class="relative min-h-screen lg:flex">
    <app-sidebar />
    <main class="flex-1 bg-gray-200 lg:h-screen md:ml-16">
        <div class='flex flex-col h-screen justify-between'>
            <app-header />
            <ng-content></ng-content>
            <div class='mb-auto'></div>
            <!-- <app-footer /> -->
        </div>
    </main>
</div>