<div class="h-full w-full flex items-center justify-center spinner-container" *ngIf="isLoading">
    <p-progressSpinner></p-progressSpinner>
</div>
<section *ngIf="!isLoading" class="max-w-4xl p-6 sm:my-4 mx-auto bg-white sm:rounded-2xl sm:border sm:border-gray-300">
    <div *transloco="let t">
    <div *transloco="let ft; scope: 'features'; prefix: 'features'">
    <div class="grid grid-cols-3">
        <a href="/employee" class="row-start-2 md:row-start-1 w-fit px-3 transition-colors duration-300 flex items-center justify-center py-1 rounded-md"
            (click)="back()">
            <i class="pi pi-angle-left"></i><span> {{ t('back_button')}} </span>
        </a>
        <a href="" class="row-start-2 md:row-start-2 w-fit px-3 transition-colors duration-300 flex items-center justify-center py-1 rounded-md">
            <i class="pi pi-angle-left"></i><span> {{t('dashboard')}} </span>
        </a>
        <h2 class="text-lg text-center content-center font-semibold text-gray-700">
            {{t('employee')}}
        </h2>
        <div>
            <svg class="ml-auto size-8 md:size-10 stroke-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
    </div>
    <form [formGroup]="formEmployee" *ngIf="!isLoading">
        <div class="grid grid-cols-1 gap-2 my-4 sm:grid-cols-2">
            <app-form-input inputType="text" labelName="{{ ft('id')}}{{t('required') }}" inputId="id" formControlName="id" />
            <app-form-input inputType="text" labelName="{{ ft('employeenumber')}}{{t('required') }}" inputId="employeeNumber"
                formControlName="employeeNumber" />
            <div class="flex w-full gap-3">
                <app-form-input inputType="text" labelName="{{ ft('firstname')}}{{t('required') }}" inputId="firstName" formControlName="firstName" />
                <app-form-input inputType="text" labelName="{{ ft('lastName')}}{{t('required') }}" inputId="lastName" formControlName="lastName" />
            </div>
            <app-form-input inputType="text" labelName="{{ ft('email')}}{{t('required') }}" inputId="email" formControlName="email" />
            <app-form-input inputType="text" labelName="{{ ft('phone')}}{{t('required') }}" inputId="phone" formControlName="phone" />
            <app-form-date-picker labelName="{{ ft('birthdate')}}{{t('required') }}" inputId="dateOfBirth" formControlName="dateOfBirth" />
            <app-form-input inputType="text" labelName="{{ ft('company')}}{{t('required') }}" inputId="company"
                formControlName="company" />
            <app-form-date-picker labelName="{{ ft('entrydate')}}{{t('required') }}" inputId="entrydate"
                formControlName="entrydate" />
            <app-form-dropdown labelName="{{ ft('status')}}{{t('required') }}" inputId="status" inputType="dropdown" formControlName="status"
                [optionList]="employeeStatusSelection" optionLabel="value" optionValue="key" [filter]="true"
                [showClear]="true" />
        </div>
        <div class="flex justify-center mb-8 gap-2">
            <button
                class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-primary rounded-md hover:bg-primary focus:outline-none focus:bg-gray-600 disabled:bg-gray-200"
                [disabled]="formEmployee.invalid" type="submit" (click)="onSubmit()">
                {{t('save')}}
            </button>
            <button
                class="px-8 py-2.5 leading-5 text-primary transition-colors duration-300 transform border border-gray-200 rounded-md hover:bg-secondaryhover active:bg-primary active:transition-none"
                (click)="resetForm()">
                {{t('restore')}}
            </button>
        </div>
        <p class="text-danger mt-3" *ngIf="formEmployee.invalid">
            {{t('fillout_msg')}}{{t('required')}}
        </p>
    </form>
    </div>
    </div>
</section>
