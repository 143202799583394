@if(inputOptions()){
<div class="relative" [ngClass]="noMargin() ? '' : 'mb-3'">
  <label [for]="inputId()">{{ labelName() }}</label>
  <div class="relative">
    <!--  && control().touched -->
    <!-- [ngClass]="valid() ? 'border-gray-200' : 'border-danger'" -->
    <input [id]="inputId()" [disabled]="disabled()" readonly tabindex="0" [ngClass]="[
        control().invalid && control().touched
          ? 'border-danger'
          : ' border-gray-400',
        labelName() !== '' ? 'mt-2' : ''
      ]" (blur)="hideDropdownOptions($event)" (click)="showDropdownOptions()"
      class="h-11 block w-full px-4 py-2 text-gray-700 hover:border-black bg-white border rounded-md focus:border-primary focus:outline-none cursor-pointer invalid:border-danger disabled:text-gray-500 disabled:border-gray-100"
      [value]="
        selectedItemsLabel() !== undefined
          ? selectedValues().length +
            '/' +
            inputOptions().length +
            ' ' +
            selectedItemsLabel()
          : selectedLabels().length > 0
          ? selectedLabels()
          : initialLabel()
      " />
    @if(showClear()){
    <button (click)="clearInput()"
      class="w-8 h-8 absolute right-8 top-2/4 -translate-y-1/2 hover:bg-gray-200 rounded-full flex justify-center items-center">
      <i class="pi pi-times"></i>
    </button>
    }
    <button
      class="w-8 h-8 absolute right-0.5 top-2/4 -translate-y-1/2 hover:bg-gray-200 rounded-full flex justify-center items-center pointer-events-none">
      @if(dropdownOptionsAreVisible()){
      <i class="pi pi-angle-up"></i>
      }@else {
      <i class="pi pi-angle-down" [ngClass]="disabled() ? 'text-gray-300' : null"></i>
      }
    </button>
  </div>
  <div #dropdown [ngClass]="dropdownOptionsAreVisible() ? 'flex' : 'hidden'"
    class="absolute w-full flex-col shadow-2xl bg-white z-50 rounded-b-md max-h-64 overflow-auto">
    @if(filter()){
    <div class="bg-white p-2 sticky top-0 app-dropdown-option">
      <i
        class="app-dropdown-option pi pi-search text-black absolute translate-x-3 translate-y-3 pointer-events-none"></i>
      <input
        class="app-dropdown-option block pl-10 h-11 w-full px-4 py-2 text-gray-700 hover:border-black bg-white border rounded-md focus:border-primary focus:outline-none"
        type="text" placeholder="Suche" (blur)="hideDropdownOptions($event)" (input)="filterOptions($event)" />
    </div>
    } @for(option of filteredInputOptions(); track option?.value){
    @if(multipleSelection()){ @if(group() && $index === 0){
    <div>
      <b>{{ option.group }} </b>
    </div>
    }

    <button role="button" type="button" [ngClass]="
        option?.selected
          ? 'bg-primary text-white'
          : 'hover:bg-gray-100 hover:text-black'
      "
      class="app-dropdown-option flex items-center pl-10 h-11 w-full py-2 text-gray-700 px-3 text-sm border-b border-gray-300"
      (blur)="hideDropdownOptions($event)" (click)="selectOption(option?.value)">
      {{ option?.label }}
    </button>
    @if(group() && (filteredInputOptions()[$index]?.group ?? '') !==
    (filteredInputOptions()[$index + 1]?.group)){
    <div class="h-11">
      <b>{{ filteredInputOptions()[$index + 1]?.group }} </b>
    </div>
    } } @else { @if(group() && $index === 0){
    <div class="h-11">
      <b>{{ option.group }} </b>
    </div>
    }
    <button role="button" type="button" [ngClass]="
        option?.selected
          ? 'bg-primary text-white'
          : 'hover:bg-gray-100 hover:text-black'
      "
      class="app-dropdown-option flex items-center pl-10 h-11 w-full py-2 text-gray-700 px-3 text-sm border-b border-gray-300"
      (blur)="hideDropdownOptions($event)" (click)="selectOption(option?.value)">
      {{ option?.label }}
    </button>
    @if(group() && filteredInputOptions()[$index]?.group !==
    filteredInputOptions()[$index + 1]?.group){
    <div>
      <b>{{ filteredInputOptions()[$index + 1]?.group }} </b>
    </div>
    } } }
  </div>
</div>

}