import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Input, HostBinding, ChangeDetectionStrategy, ViewEncapsulation, Component, NgModule } from '@angular/core';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const _c0 = ["*"];
const theme = ({
  dt
}) => `
.p-iconfield {
    position: relative;
    display: block;
}

.p-inputicon {
    position: absolute;
    top: 50%;
    margin-top: calc(-1 * (${dt('icon.size')} / 2));
    color: ${dt('iconfield.icon.color')};
    line-height: 1;
}

.p-iconfield .p-inputicon:first-child {
    inset-inline-start: ${dt('form.field.padding.x')};
}

.p-iconfield .p-inputicon:last-child {
    inset-inline-end: ${dt('form.field.padding.x')};
}

.p-iconfield .p-inputtext:not(:first-child) {
    padding-inline-start: calc((${dt('form.field.padding.x')} * 2) + ${dt('icon.size')});
}

.p-iconfield .p-inputtext:not(:last-child) {
    padding-inline-end: calc((${dt('form.field.padding.x')} * 2) + ${dt('icon.size')});
}

.p-iconfield:has(.p-inputfield-sm) .p-inputicon {
    font-size: ${dt('form.field.sm.font.size')};
    width: ${dt('form.field.sm.font.size')};
    height: ${dt('form.field.sm.font.size')};
    margin-top: calc(-1 * (${dt('form.field.sm.font.size')} / 2));
}

.p-iconfield:has(.p-inputfield-lg) .p-inputicon {
    font-size: ${dt('form.field.lg.font.size')};
    width: ${dt('form.field.lg.font.size')};
    height: ${dt('form.field.lg.font.size')};
    margin-top: calc(-1 * (${dt('form.field.lg.font.size')} / 2));
}
`;
const classes = {
  root: 'p-iconfield'
};
class IconFieldStyle extends BaseStyle {
  name = 'iconfield';
  theme = theme;
  classes = classes;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵIconFieldStyle_BaseFactory;
    return function IconFieldStyle_Factory(__ngFactoryType__) {
      return (ɵIconFieldStyle_BaseFactory || (ɵIconFieldStyle_BaseFactory = i0.ɵɵgetInheritedFactory(IconFieldStyle)))(__ngFactoryType__ || IconFieldStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: IconFieldStyle,
    factory: IconFieldStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconFieldStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * IconField wraps an input and an icon.
 *
 * [Live Demo](https://www.primeng.org/iconfield/)
 *
 * @module iconfieldstyle
 *
 */
var IconFieldClasses;
(function (IconFieldClasses) {
  /**
   * Class name of the root element
   */
  IconFieldClasses["root"] = "p-iconfield";
})(IconFieldClasses || (IconFieldClasses = {}));

/**
 * IconField wraps an input and an icon.
 * @group Components
 */
class IconField extends BaseComponent {
  /**
   * Position of the icon.
   * @group Props
   */
  iconPosition = 'left';
  get _styleClass() {
    return this.styleClass;
  }
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  _componentStyle = inject(IconFieldStyle);
  static ɵfac = /* @__PURE__ */(() => {
    let ɵIconField_BaseFactory;
    return function IconField_Factory(__ngFactoryType__) {
      return (ɵIconField_BaseFactory || (ɵIconField_BaseFactory = i0.ɵɵgetInheritedFactory(IconField)))(__ngFactoryType__ || IconField);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: IconField,
    selectors: [["p-iconfield"], ["p-iconField"], ["p-icon-field"]],
    hostAttrs: [1, "p-iconfield"],
    hostVars: 6,
    hostBindings: function IconField_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassMap(ctx._styleClass);
        i0.ɵɵclassProp("p-iconfield-left", ctx.iconPosition === "left")("p-iconfield-right", ctx.iconPosition === "right");
      }
    },
    inputs: {
      iconPosition: "iconPosition",
      styleClass: "styleClass"
    },
    features: [i0.ɵɵProvidersFeature([IconFieldStyle]), i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function IconField_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    dependencies: [CommonModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconField, [{
    type: Component,
    args: [{
      selector: 'p-iconfield, p-iconField, p-icon-field',
      standalone: true,
      imports: [CommonModule],
      template: ` <ng-content></ng-content>`,
      providers: [IconFieldStyle],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'p-iconfield',
        '[class.p-iconfield-left]': 'iconPosition === "left"',
        '[class.p-iconfield-right]': 'iconPosition === "right"'
      }
    }]
  }], null, {
    iconPosition: [{
      type: Input
    }],
    _styleClass: [{
      type: HostBinding,
      args: ['class']
    }],
    styleClass: [{
      type: Input
    }]
  });
})();
class IconFieldModule {
  static ɵfac = function IconFieldModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || IconFieldModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: IconFieldModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [IconField]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconFieldModule, [{
    type: NgModule,
    args: [{
      imports: [IconField],
      exports: [IconField]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { IconField, IconFieldClasses, IconFieldModule, IconFieldStyle };
