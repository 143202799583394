import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { timeBookingDeActivate } from 'src/app/global/apis/backend/fn/time-booking/time-booking-de-activate';
import { timeBookingExportFilteredTimeBooking } from 'src/app/global/apis/backend/fn/time-booking/time-booking-export-filtered-time-booking';
import { timeBookingExportTimeBooking } from 'src/app/global/apis/backend/fn/time-booking/time-booking-export-time-booking';
import { timeBookingFilterTimeBooking } from 'src/app/global/apis/backend/fn/time-booking/time-booking-filter-time-booking';
import { TimeBookingService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import { EmployeeFilter } from 'src/app/lazy-table/table-filters/employee-filter';
import {
  FunctionInjectionMap,
  GLOBAL_SEARCH_COLLUMS,
  LazyTableService,
  SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';
import { timeBookingComputeField } from 'src/app/global/apis/backend/fn/time-booking/time-booking-compute-field';
import { UserRoleService } from 'src/app/shared/services/user-role.service';

@Component({
  selector: 'app-timebooking',
  templateUrl: './timebooking.component.html',
  styleUrls: [],
  providers: [
    DialogService,
    {
      provide: GLOBAL_SEARCH_COLLUMS,
      useValue: [
        'employee'
      ],
    },
    {
      provide: SERVICE_FUNCTIONS,
      useValue: {
        getLazyData: timeBookingFilterTimeBooking,
        exportAll: timeBookingExportFilteredTimeBooking,
        exportSelected: timeBookingExportTimeBooking,
        deactivateSelected: timeBookingDeActivate,
        computeField: timeBookingComputeField,
        archiveSelected: null,
      } as FunctionInjectionMap,
    },
        LazyTableService<TimeBookingService>,
  ],
  imports: [
    CommonModule,
    ToastModule,
    TableModule,
    ProgressSpinnerModule,
    LazyTableComponent,
  ]
})
export class TimeBookingComponent {
  diaRef!: DynamicDialogRef;
  // Private readonly destroy$ = new Subject();
  public dataService: LazyTableService<TimeBookingService> = inject(
        LazyTableService<TimeBookingService>
  );
  public employeeFilter: EmployeeFilter = new EmployeeFilter;
  private UserRole = inject(UserRoleService);


  public cols = [
    ...(this.UserRole.HasUserRole(UserRoleService.RoleConstants.ADMINISTRATOR) ? [{ field: 'employee', header: 'employee', type: 'text', selectionFilter: this.employeeFilter.EmployeeFilter()}] : [] ), 
    { field: 'date', header: 'date', type: 'date' },
    { field: 'start', header: 'beginning', type: 'time', noFilter: true, transform: (value: string) => new Date(`1970-01-01T${value}`), preselect: false },
    { field: 'end', header: 'ending', type: 'time', noFilter: true, transform: (value: string) => new Date(`1970-01-01T${value}`), preselect: false },
    { field: 'break', header: 'break', type: 'numeric', preselect: false },
    { field: 'workTimeWithoutBreak', header: 'hwb', type: 'numeric', preselect: false },
    { field: 'workTimeIncludingBreak', header: 'hib', type: 'numeric', preselect: false },
    { field: 'openHours', header: 'openhours', type: 'numeric' },
    { field: 'billableHours', header: 'bh', type: 'numeric' },
    { field: 'billability', header: 'billability', type: 'numeric' },
    { field: 'workLocation', header: 'worklocation', type: 'string', preselect: false },
  ];

  constructor() { }
}
