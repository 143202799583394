import { Component } from '@angular/core';
import { FooterComponent } from "../footer/footer.component";
import { HeaderComponent } from "../header/header.component";
import { SidebarComponent } from "../sidebar/sidebar.component";

@Component({
    selector: 'app-mainlayout',
    standalone: true,
    templateUrl: './mainlayout.component.html',
    imports: [FooterComponent, HeaderComponent, SidebarComponent]
})
export class MainlayoutComponent {

}
