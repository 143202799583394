export function convertToTimespan(decimalTime: number): string {
  let hours = Math.floor(decimalTime);
  let minutes = Math.round((decimalTime - hours) * 60);
  let seconds = 0;

  // If the minutes are 60 due to rounding, increment the hours and set minutes to 0
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  return `${pad(hours)}:${pad(minutes)}:00`;
}

// Function to add leading zeros
function pad(n: number): string {
  return n < 10 ? '0' + n : n.toString();
}


export function convertToDecimal(time: string): number {
  let timeParts = time.split(':').map(Number); // Split the time string into parts and convert them to numbers
  let hours = timeParts[0];
  let minutes = timeParts[1];
  let seconds = 0;
  if (timeParts.length > 2) {
    seconds = timeParts[2];
  }

  // Convert hours and minutes to their decimal form
  let decimalTime = hours + (minutes / 60) + (seconds / 3600);

  return decimalTime;
}