/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum AggregationOperation {
  Sum = 0,
  Average = 1,
  Count = 2,
  Min = 3,
  Max = 4
}
