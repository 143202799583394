import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { costCenterComputeField } from 'src/app/global/apis/backend/fn/cost-center/cost-center-compute-field';
import { costCenterDeActivateCostCenter } from 'src/app/global/apis/backend/fn/cost-center/cost-center-de-activate-cost-center';
import { costCenterExportCostCenter } from 'src/app/global/apis/backend/fn/cost-center/cost-center-export-cost-center';
import { costCenterExportFilteredCostCenter } from 'src/app/global/apis/backend/fn/cost-center/cost-center-export-filtered-cost-center';
import { costCenterFilterCostCenter } from 'src/app/global/apis/backend/fn/cost-center/cost-center-filter-cost-center';
import { CostCenterService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import {
  FunctionInjectionMap,
  GLOBAL_SEARCH_COLLUMS,
  LazyTableService,
  SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';

@Component({
  selector: 'app-costcenter',
  templateUrl: './costcenter.component.html',
  styleUrls: [],
  providers: [
    DialogService,
    {
      provide: GLOBAL_SEARCH_COLLUMS,
      useValue: [
        'name',
        'description'
      ],
    },
    {
      provide: SERVICE_FUNCTIONS,
      useValue: {
        getLazyData: costCenterFilterCostCenter,
        exportAll: costCenterExportFilteredCostCenter,
        exportSelected: costCenterExportCostCenter,
        deactivateSelected: costCenterDeActivateCostCenter,
        computeField: costCenterComputeField,
        archiveSelected: null,
      } as FunctionInjectionMap,
    },
    LazyTableService<CostCenterService>,
  ],
  imports: [
    CommonModule,
    ToastModule,
    TableModule,
    ProgressSpinnerModule,
    LazyTableComponent
  ],
})
export class CostCenterComponent {

  constructor() { }

  private _status = '';
  private _translocoService: TranslocoService = inject(TranslocoService);

  diaRef!: DynamicDialogRef;
  public dataService: LazyTableService<CostCenterService> = inject(
    LazyTableService<CostCenterService>,
  );

  public cols = [
    {
      field: 'active',
      header: 'status',
      type: 'text',
      tag: true,
      transform: (value: any) => (value ? this._translocoService.translate('features.active') : this._translocoService.translate('features.inactive')),
      filter: [{ action: 'equals', value: this._translocoService.translate('features.active') }],
      selectionFilter: [
        { value: true, name: this._translocoService.translate('features.active') },
        { value: false, name: this._translocoService.translate('features.inactive') },
      ],
    },
    { field: 'amount', header: 'amount', type: 'numeric' },
    { field: 'name', header: 'name', type: 'text' },
    { field: 'factor', header: 'factor', type: 'numeric', preselect: false },
    { field: 'surcharge', header: 'surcharge', type: 'numeric', preselect: false },
    { field: 'description', header: 'description', type: 'text' },
    { field: 'hourlyRate', header: 'pph', type: 'numeric' },
    { field: 'hours', header: 'hours', type: 'numeric' },
    // { field: 'hours', header: 'is', type: 'numeric', progressBar: true },
    // { field: 'amount', header: 'should', type: 'numeric', progressBar: true },
    {
      field: 'internal',
      header: 'type',
      type: 'text',
      tag: true,
      transform: (value: any) => (value ? this._translocoService.translate('features.internal') : this._translocoService.translate('features.external')),
      filter: [{ action: 'equals', value: this._translocoService.translate('features.internal') }],
      selectionFilter: [
        { value: true, name: this._translocoService.translate('features.internal') },
        { value: false, name: this._translocoService.translate('features.external') },
      ],
    },

  ];

}
