import { Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'languageToFlag',
})

export class LangCodeToFlagImgPipe implements PipeTransform {
    constructor (
        private sanitiser: DomSanitizer
    ) {};
    
    transform(value: string): SafeHtml {
        let svgCode: string = "";
        switch (value.toLowerCase()) {
            case 'cz':
                    svgCode = `
                        <path fill="#ec1c24" d="m32.454 32.03l-28.344 20.22c1.538 1.106 3.499 1.754 5.89 1.754h44c6.627 0 10-4.925 10-11v-11h-31.584l.038.027"/>
                        <path fill="#e6e7e8" d="m54 10h-44c-2.201 0-4.04.553-5.514 1.5l27.93 20.5h31.584v-11c0-6.075-3.373-11-10-11"/>
                        <path fill="#1b75bb" d="M32.416,32L4.486,11.5C1.513,13.407,0,16.942,0,21v11v11c0,3.883,1.385,7.289,4.11,9.246l28.344-20.219 L32.416,32z"/>
                    `;
                break;
            case 'de':
                    svgCode = `
                        <path fill="#e6e7e8" d="m0 25h64v14h-64z"/>
                        <path fill="#ec1c24" d="m54 10h-44c-6.627 0-10 4.925-10 11v4h64v-4c0-6.075-3.373-11-10-11"/>
                        <path fill="#ec1c24" d="m0 43c0 6.075 3.373 11 10 11h44c6.627 0 10-4.925 10-11v-4h-64v4"/>
                    `;
                break;
            case 'en':
                    svgCode = `
                        <path fill="#1b75bb" d="m57.1 12.85h-50.21c-3.813 0-6.897 3.695-6.897 8.249v5.599 13.85 5.599c0 4.555 3.083 8.25 6.897 8.25h50.22c3.807 0 6.896-3.695 6.896-8.25v-5.599-13.85-5.599c0-4.555-3.086-8.249-6.899-8.249"/>
                        <path fill="#176496" d="M6.892,12.854c-3.814,0-6.897,3.686-6.897,8.23v5.586l0,0v13.818l0,0v5.586c0,4.545,3.083,8.23,6.897,8.23   H19.11V40.489l0,0v-13.82l0,0V12.854H6.892z"/>
                        <path fill="#fff" d="m46.684 29.06l17.317-.002v-1.916l-14.326-.0001 13.896-8.92c-.51-1.707-1.482-3.23-2.799-4.106l1.58 2.467-16.534 10.559h-6.93l21.11-13.547c-.756-.443-1.635-.659-2.518-.741l-18.685 11.954v-11.95h-13.605v11.769l-18.391-11.773c-.932.004-1.871.208-2.691.636l21.08 13.527v.125l-6.812-.0001-16.739-10.743 1.429-2.232c-1.175.927-2.107 2.309-2.598 3.877l14.174 9.1h-14.658v2.01l17.7-.054 7.213 4.63-6.974 4.475-17.939-.005v1.922l14.947.002-14.403 9.24c.497 1.34 1.219 2.533 2.198 3.386l-1.091-1.699 17.03-10.929h6.526v.195l-21.02 13.423c.677.406 1.533.668 2.385.668l.656-.002 17.978-11.543v11.545h2.02v-12.834l4.953-3.184 4.619 2.969v13.05h2.02v-11.761l18.31 11.758h.078c1.133 0 1.797-.193 2.633-.666l-21.02-13.49v-.13h6.818l16.737 10.74-1.315 2.066c1.026-.764 2.067-2.16 2.476-3.709l-14.179-9.1 14.666.004v-1.907l-17.646-.015-6.957-4.461 7.286-4.674m-3.873 0l-5.363 3.428-3.441-2.211 2.777-1.785v.568h6.03v0m-7.23 4.617l-3.515 2.244-3.428-2.201 3.505-2.249 3.438 2.206m-8.371-20.671h9.572v13.09l-4.645 2.98-4.928-3.162.0001-12.91 0 0m-5.809 16.07l5.807-.019v-.751l3.064 1.968-3.505 2.248-5.366-3.446m5.809 9.944v-.824h-5.551l5.108-3.278 3.423 2.201-2.98 1.901m15.422-.821l-5.85-.004v.753l-2.753-1.771 3.507-2.247 5.096 3.269"/>
                        <path fill="#ec1c24" d="m64.01 28.833l-20.729.003 19.488-12.51c-.678-1.244-1.662-2.115-2.766-2.736l-23.21 14.883v-15.619h-9.574v15.431l-23.11-14.799c-1.242.631-2.094 1.539-2.8 2.722l19.725 12.649-21.04.071v9.482l21.299.004-19.883 12.757c.73 1.226 1.74 2.111 2.92 2.652l22.888-14.683v15.26h9.573v-15.436l23.02 14.769c.854-.426 1.972-1.146 2.902-2.699l-19.686-12.614 20.978.012v-9.599h-.003"/>
                        <path fill="#c32129" d="m18.859 28.86v9.55h-18.859l-.011-9.487z"/>
                    `;
                break;    
            default:
                    svgCode = `
                        <path d="M12.5 13C12.5 11 14 11.5 14 10C14 9.34375 13.5 8.5 12.5 8.5C11.5 8.5 11 9 10.5 9.5M12.5 16V14.5M5.5 5.5H19.5V19.5H5.5V5.5Z" stroke="#121923" stroke-width="1.2"/>
                    `;
                break;
        }

        return this.sanitiser.bypassSecurityTrustHtml(svgCode);
    }
}