/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { employeeComputeField } from '../fn/employee/employee-compute-field';
import { EmployeeComputeField$Params } from '../fn/employee/employee-compute-field';
import { employeeCreateEmployee } from '../fn/employee/employee-create-employee';
import { EmployeeCreateEmployee$Params } from '../fn/employee/employee-create-employee';
import { EmployeeDto } from '../models/employee-dto';
import { employeeExportEmployee } from '../fn/employee/employee-export-employee';
import { EmployeeExportEmployee$Params } from '../fn/employee/employee-export-employee';
import { employeeExportFilteredEmployee } from '../fn/employee/employee-export-filtered-employee';
import { EmployeeExportFilteredEmployee$Params } from '../fn/employee/employee-export-filtered-employee';
import { employeeFilterEmployee } from '../fn/employee/employee-filter-employee';
import { EmployeeFilterEmployee$Params } from '../fn/employee/employee-filter-employee';
import { employeeGetAllEmployee } from '../fn/employee/employee-get-all-employee';
import { EmployeeGetAllEmployee$Params } from '../fn/employee/employee-get-all-employee';
import { employeeGetEmployeeById } from '../fn/employee/employee-get-employee-by-id';
import { EmployeeGetEmployeeById$Params } from '../fn/employee/employee-get-employee-by-id';
import { employeeGetEmployeeLanguageById } from '../fn/employee/employee-get-employee-language-by-id';
import { EmployeeGetEmployeeLanguageById$Params } from '../fn/employee/employee-get-employee-language-by-id';
import { EmployeeMinimalDto } from '../models/employee-minimal-dto';
import { employeeUpdateEmployee } from '../fn/employee/employee-update-employee';
import { EmployeeUpdateEmployee$Params } from '../fn/employee/employee-update-employee';
import { employeeUpdateEmployeeLanguage } from '../fn/employee/employee-update-employee-language';
import { EmployeeUpdateEmployeeLanguage$Params } from '../fn/employee/employee-update-employee-language';
import { PagedListOfEmployeeFilter } from '../models/paged-list-of-employee-filter';

@Injectable({ providedIn: 'root' })
export class EmployeeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `employeeGetEmployeeById()` */
  static readonly EmployeeGetEmployeeByIdPath = '/api/Employee/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeGetEmployeeById()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeGetEmployeeById$Response(params: EmployeeGetEmployeeById$Params, context?: HttpContext): Observable<StrictHttpResponse<EmployeeDto>> {
    return employeeGetEmployeeById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeGetEmployeeById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeGetEmployeeById(params: EmployeeGetEmployeeById$Params, context?: HttpContext): Observable<EmployeeDto> {
    return this.employeeGetEmployeeById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmployeeDto>): EmployeeDto => r.body)
    );
  }

  /** Path part for operation `employeeGetEmployeeLanguageById()` */
  static readonly EmployeeGetEmployeeLanguageByIdPath = '/api/Employee/language';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeGetEmployeeLanguageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeGetEmployeeLanguageById$Response(params?: EmployeeGetEmployeeLanguageById$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return employeeGetEmployeeLanguageById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeGetEmployeeLanguageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeGetEmployeeLanguageById(params?: EmployeeGetEmployeeLanguageById$Params, context?: HttpContext): Observable<string> {
    return this.employeeGetEmployeeLanguageById$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `employeeGetAllEmployee()` */
  static readonly EmployeeGetAllEmployeePath = '/api/Employee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeGetAllEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeGetAllEmployee$Response(params?: EmployeeGetAllEmployee$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EmployeeMinimalDto>>> {
    return employeeGetAllEmployee(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeGetAllEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  employeeGetAllEmployee(params?: EmployeeGetAllEmployee$Params, context?: HttpContext): Observable<Array<EmployeeMinimalDto>> {
    return this.employeeGetAllEmployee$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmployeeMinimalDto>>): Array<EmployeeMinimalDto> => r.body)
    );
  }

  /** Path part for operation `employeeUpdateEmployee()` */
  static readonly EmployeeUpdateEmployeePath = '/api/Employee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeUpdateEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeUpdateEmployee$Response(params: EmployeeUpdateEmployee$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return employeeUpdateEmployee(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeUpdateEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeUpdateEmployee(params: EmployeeUpdateEmployee$Params, context?: HttpContext): Observable<string> {
    return this.employeeUpdateEmployee$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `employeeCreateEmployee()` */
  static readonly EmployeeCreateEmployeePath = '/api/Employee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeCreateEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeCreateEmployee$Response(params: EmployeeCreateEmployee$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return employeeCreateEmployee(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeCreateEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeCreateEmployee(params: EmployeeCreateEmployee$Params, context?: HttpContext): Observable<string> {
    return this.employeeCreateEmployee$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `employeeUpdateEmployeeLanguage()` */
  static readonly EmployeeUpdateEmployeeLanguagePath = '/api/Employee/updateLanguage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeUpdateEmployeeLanguage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeUpdateEmployeeLanguage$Response(params: EmployeeUpdateEmployeeLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return employeeUpdateEmployeeLanguage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeUpdateEmployeeLanguage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeUpdateEmployeeLanguage(params: EmployeeUpdateEmployeeLanguage$Params, context?: HttpContext): Observable<string> {
    return this.employeeUpdateEmployeeLanguage$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `employeeExportEmployee()` */
  static readonly EmployeeExportEmployeePath = '/api/Employee/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeExportEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeExportEmployee$Response(params: EmployeeExportEmployee$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return employeeExportEmployee(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeExportEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeExportEmployee(params: EmployeeExportEmployee$Params, context?: HttpContext): Observable<Blob> {
    return this.employeeExportEmployee$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `employeeFilterEmployee()` */
  static readonly EmployeeFilterEmployeePath = '/api/Employee/filter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeFilterEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeFilterEmployee$Response(params: EmployeeFilterEmployee$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedListOfEmployeeFilter>> {
    return employeeFilterEmployee(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeFilterEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeFilterEmployee(params: EmployeeFilterEmployee$Params, context?: HttpContext): Observable<PagedListOfEmployeeFilter> {
    return this.employeeFilterEmployee$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagedListOfEmployeeFilter>): PagedListOfEmployeeFilter => r.body)
    );
  }

  /** Path part for operation `employeeExportFilteredEmployee()` */
  static readonly EmployeeExportFilteredEmployeePath = '/api/Employee/filter/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeExportFilteredEmployee()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeExportFilteredEmployee$Response(params: EmployeeExportFilteredEmployee$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return employeeExportFilteredEmployee(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeExportFilteredEmployee$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeExportFilteredEmployee(params: EmployeeExportFilteredEmployee$Params, context?: HttpContext): Observable<Blob> {
    return this.employeeExportFilteredEmployee$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `employeeComputeField()` */
  static readonly EmployeeComputeFieldPath = '/api/Employee/filter/compute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeComputeField()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeComputeField$Response(params: EmployeeComputeField$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return employeeComputeField(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeComputeField$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  employeeComputeField(params: EmployeeComputeField$Params, context?: HttpContext): Observable<number> {
    return this.employeeComputeField$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
