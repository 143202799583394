/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PagedListOfZeitbuchungFilter } from '../models/paged-list-of-zeitbuchung-filter';
import { zeitbuchungCreateZeitbuchung } from '../fn/zeitbuchung/zeitbuchung-create-zeitbuchung';
import { ZeitbuchungCreateZeitbuchung$Params } from '../fn/zeitbuchung/zeitbuchung-create-zeitbuchung';
import { zeitbuchungDeActivate } from '../fn/zeitbuchung/zeitbuchung-de-activate';
import { ZeitbuchungDeActivate$Params } from '../fn/zeitbuchung/zeitbuchung-de-activate';
import { ZeitbuchungDto } from '../models/zeitbuchung-dto';
import { zeitbuchungExportFilteredZeitbuchung } from '../fn/zeitbuchung/zeitbuchung-export-filtered-zeitbuchung';
import { ZeitbuchungExportFilteredZeitbuchung$Params } from '../fn/zeitbuchung/zeitbuchung-export-filtered-zeitbuchung';
import { zeitbuchungExportZeitbuchung } from '../fn/zeitbuchung/zeitbuchung-export-zeitbuchung';
import { ZeitbuchungExportZeitbuchung$Params } from '../fn/zeitbuchung/zeitbuchung-export-zeitbuchung';
import { zeitbuchungFilterZeitbuchung } from '../fn/zeitbuchung/zeitbuchung-filter-zeitbuchung';
import { ZeitbuchungFilterZeitbuchung$Params } from '../fn/zeitbuchung/zeitbuchung-filter-zeitbuchung';
import { zeitbuchungGetZeitbuchungById } from '../fn/zeitbuchung/zeitbuchung-get-zeitbuchung-by-id';
import { ZeitbuchungGetZeitbuchungById$Params } from '../fn/zeitbuchung/zeitbuchung-get-zeitbuchung-by-id';
import { zeitbuchungUpdateZeitbuchung } from '../fn/zeitbuchung/zeitbuchung-update-zeitbuchung';
import { ZeitbuchungUpdateZeitbuchung$Params } from '../fn/zeitbuchung/zeitbuchung-update-zeitbuchung';

@Injectable({ providedIn: 'root' })
export class ZeitbuchungService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `zeitbuchungGetZeitbuchungById()` */
  static readonly ZeitbuchungGetZeitbuchungByIdPath = '/api/Zeitbuchung/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `zeitbuchungGetZeitbuchungById()` instead.
   *
   * This method doesn't expect any request body.
   */
  zeitbuchungGetZeitbuchungById$Response(params: ZeitbuchungGetZeitbuchungById$Params, context?: HttpContext): Observable<StrictHttpResponse<ZeitbuchungDto>> {
    return zeitbuchungGetZeitbuchungById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `zeitbuchungGetZeitbuchungById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  zeitbuchungGetZeitbuchungById(params: ZeitbuchungGetZeitbuchungById$Params, context?: HttpContext): Observable<ZeitbuchungDto> {
    return this.zeitbuchungGetZeitbuchungById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZeitbuchungDto>): ZeitbuchungDto => r.body)
    );
  }

  /** Path part for operation `zeitbuchungUpdateZeitbuchung()` */
  static readonly ZeitbuchungUpdateZeitbuchungPath = '/api/Zeitbuchung';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `zeitbuchungUpdateZeitbuchung()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungUpdateZeitbuchung$Response(params: ZeitbuchungUpdateZeitbuchung$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return zeitbuchungUpdateZeitbuchung(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `zeitbuchungUpdateZeitbuchung$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungUpdateZeitbuchung(params: ZeitbuchungUpdateZeitbuchung$Params, context?: HttpContext): Observable<string> {
    return this.zeitbuchungUpdateZeitbuchung$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `zeitbuchungCreateZeitbuchung()` */
  static readonly ZeitbuchungCreateZeitbuchungPath = '/api/Zeitbuchung';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `zeitbuchungCreateZeitbuchung()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungCreateZeitbuchung$Response(params: ZeitbuchungCreateZeitbuchung$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return zeitbuchungCreateZeitbuchung(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `zeitbuchungCreateZeitbuchung$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungCreateZeitbuchung(params: ZeitbuchungCreateZeitbuchung$Params, context?: HttpContext): Observable<string> {
    return this.zeitbuchungCreateZeitbuchung$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `zeitbuchungDeActivate()` */
  static readonly ZeitbuchungDeActivatePath = '/api/Zeitbuchung';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `zeitbuchungDeActivate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungDeActivate$Response(params: ZeitbuchungDeActivate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return zeitbuchungDeActivate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `zeitbuchungDeActivate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungDeActivate(params: ZeitbuchungDeActivate$Params, context?: HttpContext): Observable<Array<string>> {
    return this.zeitbuchungDeActivate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `zeitbuchungExportZeitbuchung()` */
  static readonly ZeitbuchungExportZeitbuchungPath = '/api/Zeitbuchung/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `zeitbuchungExportZeitbuchung()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungExportZeitbuchung$Response(params: ZeitbuchungExportZeitbuchung$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return zeitbuchungExportZeitbuchung(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `zeitbuchungExportZeitbuchung$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungExportZeitbuchung(params: ZeitbuchungExportZeitbuchung$Params, context?: HttpContext): Observable<Blob> {
    return this.zeitbuchungExportZeitbuchung$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `zeitbuchungFilterZeitbuchung()` */
  static readonly ZeitbuchungFilterZeitbuchungPath = '/api/Zeitbuchung/filter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `zeitbuchungFilterZeitbuchung()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungFilterZeitbuchung$Response(params: ZeitbuchungFilterZeitbuchung$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedListOfZeitbuchungFilter>> {
    return zeitbuchungFilterZeitbuchung(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `zeitbuchungFilterZeitbuchung$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungFilterZeitbuchung(params: ZeitbuchungFilterZeitbuchung$Params, context?: HttpContext): Observable<PagedListOfZeitbuchungFilter> {
    return this.zeitbuchungFilterZeitbuchung$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagedListOfZeitbuchungFilter>): PagedListOfZeitbuchungFilter => r.body)
    );
  }

  /** Path part for operation `zeitbuchungExportFilteredZeitbuchung()` */
  static readonly ZeitbuchungExportFilteredZeitbuchungPath = '/api/Zeitbuchung/filter/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `zeitbuchungExportFilteredZeitbuchung()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungExportFilteredZeitbuchung$Response(params: ZeitbuchungExportFilteredZeitbuchung$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return zeitbuchungExportFilteredZeitbuchung(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `zeitbuchungExportFilteredZeitbuchung$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  zeitbuchungExportFilteredZeitbuchung(params: ZeitbuchungExportFilteredZeitbuchung$Params, context?: HttpContext): Observable<Blob> {
    return this.zeitbuchungExportFilteredZeitbuchung$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
