import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FailedComponent } from './failed/failed.component';
import { KostentraegerComponent } from './features/kostentraeger/kostentraeger.component';
import KostentraegerDetailComponent from './features/kostentraeger/kostentraeger.detail/kostentraeger.detail.component';
import { MitarbeiterComponent } from './features/mitarbeiter/mitarbeiter.component';
import MitarbeiterDetailComponent from './features/mitarbeiter/mitarbeiter.detail/mitarbeiter.detail.component';
import { TaetigkeitComponent } from './features/taetigkeit/taetigkeit.component';
import { ZeitbuchungComponent } from './features/zeitbuchung/zeitbuchung.component';
import ZeitbuchungDetailComponent from './features/zeitbuchung/zeitbuchung.detail/zeitbuchung.detail.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { RoleConstants } from './shared/constants/role.constant';
import { RoleGuard } from './shared/guards/role.guard';

export const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR, RoleConstants.BASIC] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'zeitbuchung',
    component: ZeitbuchungComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR, RoleConstants.BASIC] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'zeitbuchung/detail',
    component: ZeitbuchungDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR, RoleConstants.BASIC] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'zeitbuchung/detail/:id',
    component: ZeitbuchungDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR, RoleConstants.BASIC] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'taetigkeit',
    component: TaetigkeitComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'kostentraeger',
    component: KostentraegerComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'kostentraeger/detail',
    component: KostentraegerDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'kostentraeger/detail/:id',
    component: KostentraegerDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'mitarbeiter',
    component: MitarbeiterComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'mitarbeiter/detail',
    component: MitarbeiterDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'mitarbeiter/detail/:id',
    component: MitarbeiterDetailComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: '',
    component: HomeComponent,
    data: { roles: [RoleConstants.ADMINISTRATOR, RoleConstants.BASIC] },
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
];
